
import LazyLoad from "src/lib/LazyLoad";
import PriceAlertPopup from "./PriceAlertPopup";
import PriceAlertPopupTrigger from "./PriceAlertPopupTrigger";
import PriceHistoryPlaceholder from "./PriceHistoryPlaceholder";
import React from "react";
import { TheCycleverseTheme } from "src/theme";
import dynamic from "next/dynamic";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import PriceHistoryChart, { PriceHistoryEntry } from "./PriceHistoryChart";

const PriceHistory = dynamic(() => import("./PriceHistory"), {
  loading: PriceHistoryPlaceholder,
});

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  priceAlertContainer: {
    textAlign: "center",
    maxWidth: theme.widths.contentWidth,
    margin: "auto",
  },
  popupTriggerProductPageMiddle: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "50px",
  },
  moveButtonToRight: {
    marginLeft: "175px",
    marginRight: "5px",
  },
  openButton: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.lightOrangeColor}`,
    color: theme.darkOrangeColor,
    textAlign: "center",
    borderRadius: "4px",
    fontWeight: 600,
    cursor: "pointer",
    marginTop: "10px",
  },
  bottomPriceAlertTriggerButtonHeight: {
    padding: "6px 0",
    width: "130px",
    fontSize: "14px",
  },
}));

type Price = {
  amount: number;
  currency: string;
  amountWithoutDiscount: number;
  decimalOffset: number;
};

type ProductDescriptor = {
  id: string;
  brand: string;
  title: string;
  price: Price;
  category: string;
};

type PriceAlertProps = {
  imageUrl: string;
  priceHistory?: Array<PriceHistoryEntry>;
  product: ProductDescriptor;
};

const PriceAlert = ({ imageUrl, priceHistory, product }: PriceAlertProps) => {
  const classes = useStyles();
  const { t } = useTranslation("productDetails");
  return (
    <div className={classes.priceAlertContainer}>
      <h2>{t("productDetailPriceHistoryTitle")}</h2>

      <p>{t("productDetailPriceHistoryIntro")}</p>

      <LazyLoad placeholder={<PriceHistoryPlaceholder />} once>
        {
          !!priceHistory
          ?
          <PriceHistoryChart priceHistory={priceHistory} />
          :
          <PriceHistory product={product} />
        }
      </LazyLoad>

      <PriceAlertPopupTrigger />

      <div className={classes.popupTriggerProductPageMiddle}>
        <div className={classes.moveButtonToRight}>
          <PriceAlertPopup
            toggleButtonClass={`${classes.openButton} ${classes.bottomPriceAlertTriggerButtonHeight}`}
            imageUrl={imageUrl}
            product={product}
            page="priceAlert"
          />
        </div>
      </div>
    </div>
  );
};

export default PriceAlert;
