import { FC } from "react";
import { groupByFn } from "src/components/common/ArrayFunctions";
import LocaleLink from "src/components/Navigation/LocaleLink";
import { BikeColor, NormalizedColor } from "./NormalizedBikeTypes";
import { makeStyles } from "@material-ui/core/styles";
import { TheCycleverseTheme } from "src/theme";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
    colorVariantALink: {
        display: "flex"
    },
    bikeVariantsContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    colorVariantsHeader: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginTop: "10px",
        marginBottom: "16px"
    },
    bikeVariantsHeaderText: {
        fontSize: "1.1em",
        fontWeight: "bold"
    },
    bikeVariantsHeaderTeaser: {
        color: '#00AEEF',
        cursor: 'pointer',
        textDecorationLine: 'underline'
    },
    colorVariantContainer: {
        display: "inline-block",
        maxWidth: "70px",
        overflow: "hidden",
        backgroundColor: "white",
        flexDirection: "column",
        marginRight: "8px",
    },
    colorVariantContainerAvailable: {
        opacity: "100%",
        cursor: "pointer"
    },
    colorVariantContainerUnavailable: {
        opacity: "25%",
        cursor: "auto"
    },
    colorVariantPreview: {
        flex: "1",
        alignSelf: "center",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        position: "relative"
    },
    colorVariantPrimary: {
        position: "absolute",
        left: "0px",
        top: "0px",
    },
    colorVariantPrimaryClip: {
        clipPath: "polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)",
    },
    colorVariantSecondary: {
        position: "absolute",
        left: "0px",
        top: "0px",
        clipPath: "polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%)",
    },
    colorVariantPreviewLight: {
        "&:before": {
            position: "absolute",
            display: "block",
            content: '""',
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            borderWidth: "2px",
            borderColor: "black",
            borderStyle: "solid",
            borderRadius: "50%",
        }
    },
    colorVariantPreviewDark: {
        "&:before": {
            position: "absolute",
            display: "block",
            content: '""',
            top: "2px",
            bottom: "2px",
            left: "2px",
            right: "2px",
            borderWidth: "2px",
            borderColor: "white",
            borderStyle: "solid",
            borderRadius: "50%",
        }
    },
    colorVariantPreviewActive: {
        width: "45px",
        height: "45px",
    },
    colorVariantPreviewName: {
        width: "100%",
        textAlign: "center"
    }
}));

const isBrightColor = (hexValue: string)=>{
    return !!(hexValue.match(/^#([CDEF][0-9A-F]){3}$/gi));
}

export const ColorVariantsSelection: FC<{
    variantArray: BikeColor[],
    availableArray: BikeColor[],
    selectedColorId: string,
    getRouteWithColor: (id: string) => string
}> = ({ variantArray, availableArray, selectedColorId, getRouteWithColor }) => {
    const classes = useStyles();
    const { t, lang } = useTranslation("productDetails");

    if (!variantArray || variantArray.length === 0)
        return null;

    const bikeColorsById = groupByFn(variantArray, c => c.id);
    const colorVariants = Object.values(bikeColorsById).map(x => { return { ...x[0], "amt": x.length } });
    const variantName = t("bikeDetails:productBikeVariantsColorHeaderText");
    const isAvailable = (c: BikeColor) => availableArray.map(a => a.id).includes(c.id);
    return <div>
        <div className={classes.colorVariantsHeader}>
            <div className={classes.bikeVariantsHeaderText}>{variantName}:</div>
        </div>
        <div className={classes.bikeVariantsContainer}>
            {
                colorVariants.map((bikeColor: BikeColor) => {
                    const isLightPrimaryColor = isBrightColor(bikeColor.colors[0].hexValue);
                    const targetRoute = getRouteWithColor(bikeColor.id);
                    const hasSecondColor = bikeColor.colors.length>1;
                    const isActive = selectedColorId === bikeColor.id;
                    const primaryColor = bikeColor.colors[0].hexValue;
                    const secondaryColor = hasSecondColor && bikeColor.colors[1].hexValue;
                    const isLightSecondaryColor = hasSecondColor && bikeColor.colors[1].hexValue.match(/^[#EF]+$/g)

                    const colorPreview = classes.colorVariantPreview;

                    const lightPrimaryColorClass = isLightPrimaryColor ? classes.colorVariantPreviewLight : classes.colorVariantPreviewDark;
                    const lightSecondaryColorClass = isLightSecondaryColor ? classes.colorVariantPreviewLight : classes.colorVariantPreviewDark;
                    const activeClass = isActive && classes.colorVariantPreviewActive;

                    const showColorNames = false;

                    const linkContent = <div
                        key={bikeColor.id}
                        className={`${classes.colorVariantContainer} ${isAvailable(bikeColor) ? classes.colorVariantContainerAvailable : classes.colorVariantContainerUnavailable}`}>

                        <div style={{position: 'relative'}}>
                            <div className={`${colorPreview} ${activeClass}`} />
                            { primaryColor && 
                            <div style={{ backgroundColor: primaryColor }}
                                className={`${colorPreview} ${classes.colorVariantPrimary} ${hasSecondColor && classes.colorVariantPrimaryClip} ${lightPrimaryColorClass} ${activeClass}`} />
                            }
                            { secondaryColor && 
                            <div style={{ backgroundColor: secondaryColor }}
                                className={`${colorPreview} ${classes.colorVariantSecondary} ${lightSecondaryColorClass} ${activeClass}`} />
                            }
                        </div>

                        {showColorNames && <div className={classes.colorVariantPreviewName}>{bikeColor.id}</div>}

                    </div>

                    return isAvailable(bikeColor) ?
                        <LocaleLink key={'lc-' + (bikeColor.id)} as={targetRoute} href={targetRoute} aClass={classes.colorVariantALink}>{linkContent}</LocaleLink>
                        : linkContent;
                })
            }
        </div>
    </div>
}
