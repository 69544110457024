import BikeValueForMoneyChart, { HighlightMode, ValueForMoneyDatapoint } from "../BikeValueForMoneyChart";
import Description, { DescriptionParagraph, DescriptionSection } from "../Description";
import Grid, { GridSize } from "@material-ui/core/Grid";
import React, { FC, useContext, useState } from "react";
import { queryBikes, queryBikesShallow, createTextByIdBatchQuery, queryProducts } from "../../../graphql/queries";
import { groupBy } from "src/components/common/ArrayFunctions";
import BikeComparisonBlock from "../BikeComparisonBlock";
import BikeComponentQualityRadarChart from "../BikeComponentQualityRadarChart";
import SpecificationsTable from "src/components/Content/Specification/SpecificationTable";
import BikeGeometry from "../BikeGeometry";
import BikeGeometryTable from "../BikeGeometryTable";
import BikeHighlights from "./BikeHighlights";
import BikeMaxSpeedDiagram from "../BikeMaxSpeedDiagram";
import BikePreview from "./BikePreview";
import BikeSizingTable from "../BikeSizingTable";
import ButtonLink from "../../Navigation/ButtonLink";
import { ColorVariantsSelection } from "./ColorVariantsSelection";
import { SizeVariantsSelection, InternalFrameSize } from "./SizeVariantsSelection";
import Error from "../../../../pages/_error";
import { FrameSize } from "./NormalizedBikeTypes";
import FrequentlyAskedQuestionsSection from "./FrequentlyAskedQuestionsSection";
import Head from "next/head";
import LocaleLink from "../../Navigation/LocaleLink";
import MainLayout from "../../Layout/MainLayout";
import PriceAlertPopup, { Product } from "./PriceAlertPopup";
import PriceAlertPopupTrigger from "./PriceAlertPopupTrigger";
import PriceDiscountTag from "./PriceDiscountTag";
import PriceTag from "./PriceTag";
import ProductCategoryBreadcrumb from "./ProductCategoryBreadcrumb";
import ProductHightlights from "./ProductHightlights";
import ProductLike from "./ProductLike";
import ProductLink from "./ProductLink";
import SeoHead from "../../Seo/SeoHead";
import SocialShareButtons from "../../Social/SocialShareButtons";
import Text from "../Text/Text";
import TextConditional from "../Text/TextConditional";
import { TheCycleverseTheme } from "src/theme";
import Typography from "@material-ui/core/Typography";
import environment from "../../../environment";
import { getBestBikePath } from "../../../lib/bestProductsUtils";
import { getCategoryByName } from "./useCategory";
import { getProductInfoLink } from "./useProductLink";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from "next/router";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "../../../translations/useTranslationExtras";
import PriceAlert from "./PriceAlert";
import PriceComparisonSection from "./PriceComparisonSection";
import AuthContext from "src/components/Auth/AuthContext";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  strong: {
    fontWeight: "bold",
  },
  image: {
    width: "100%",
    height: "100%",
    paddingTop: "75%",
    position: "relative",
    textAlign: "center",
    "& img": {
      maxHeight: "100%",
      maxWidth: "100%",
      position: "absolute",
      margin: "auto",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  imageContainer: {
    padding: theme.cardSpacing,
    backgroundColor: theme.product.imageBackground,
  },
  bikeChartSectionGeometryContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  textBold: {
    fontWeight: "bold",
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  verticalSection: {
    fontSize: "medium",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  alignCenter: {
    textAlign: "center",
  },
  horizontalRowAfter: {
    "&::after": {
      display: "block",
      maxWidth: theme.paragraphMaxWidth,
      margin: "auto",
      content: '""',
      borderTop: `1px solid ${theme.unflashyColor}`,
    },
  },
  horizontalRowBefore: {
    "&::before": {
      display: "block",
      maxWidth: theme.paragraphMaxWidth,
      margin: "auto",
      content: '""',
      borderTop: `1px solid ${theme.unflashyColor}`,
      marginTop: "50px",
      marginBottom: "50px",
    },
  },
  bikeSectionGridItem: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  gutters: {
    paddingLeft: theme.globalMarginDesktop,
    paddingRight: theme.globalMarginDesktop,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.globalMarginMobile,
      paddingRight: theme.globalMarginMobile,
    },
  },
  guttersHorizontalScroll: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.globalMarginMobile,
      marginRight: -theme.globalMarginMobile,
      "& .MuiImageListItem-root:first-child": {
        marginLeft: theme.globalMarginMobile - theme.cardSpacing,
      },
    },
  },
  gutterBottomSpacing: {
    marginBottom: theme.spacing(4),
  },
  info: {
    paddingTop: theme.globalMarginDesktop,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.globalMarginMobile,
    },
  },
  overview: {
    padding: theme.spacing(1),
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      paddingLeft: theme.spacing(4),
    },
  },
  overviewContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  brand: {
    display: "block",
    color: theme.hightlightColor,
    lineHeight: "1.3",
  },
  title: {
    lineHeight: "1.3",
    fontSize: 21,
  },
  price: {
    textAlign: "right",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    "& .FX-PriceTag-price": {
      fontSize: "x-large",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  deliveryCosts: {
    color: theme.unflashyColor,
  },
  deliveryCostsTax: {
    color: theme.unflashyColor,
    fontSize: "small",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  openButtonContainer: {
    textAlign: "right",
  },
  description: {
    marginTop: theme.spacing(6),
    "& .FX-DescriptionParagraph": {
      maxWidth: theme.paragraphMaxWidth,
    },
  },
  socialButtonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "32px",
  },
  shareButtons: {
    display: "inline-block",
  },
  bikeSizeSection: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  bikeSize: {
    backgroundColor: theme.palette.primary.main,
    color: theme.openButtonColor,
  },
  likes: {
    position: "absolute",
    bottom: theme.cardSpacing,
    right: theme.cardSpacing,
    zIndex: 1,
    margin: theme.cardButtonSpacing,
  },
  salePercentage4: {
    zIndex: 1,
    position: "absolute",
    top: theme.cardSpacing,
    left: theme.cardSpacing,
    margin: theme.cardButtonSpacing,
  },
  breadcrumb: {
    marginTop: theme.spacing(1),
  },
  priceComparison: {
    paddingTop: theme.spacing(8),
  },
  bikeDetailTable: {
    "& tbody tr td:first-child": {
      paddingRight: theme.spacing(2),
      fontWeight: 500,
    },
    "& tbody tr td": {
      paddingBottom: theme.spacing(1),
    },
  },
  specifications: {
    textAlign: "center",
    "& h2": {
      fontSize: "x-large",
      marginTop: 8,
      fontWeight: 300,
      marginBottom: 24,
    },
  },
  bikeTable: {
    maxWidth: 600,
    margin: "auto",
    paddingBottom: theme.spacing(8),
  },
  text: {
    "& .FX-Text-content": {
      ...theme.formText,
    },
    overflowWrap: "anywhere",
  },
  subHeadline: {
    fontSize: 20,
    fontFamily: "inherit",
    fontWeight: 900,
    marginBottom: 16,
  },
  bikeChartSectionHeader: {
    fontSize: 20,
    fontFamily: "inherit",
    fontWeight: 900,
    marginBottom: 8,
    textAlign: "center",
  },
  adviceTextContainer: {
    "& .FX-HeadlineShortLink-root": {
      display: "inline-block",
      maxWidth: 400,
      padding: 12,
    },
  },
  editorFormatting: {
    ...theme.formText,
  },
  openButton: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.lightOrangeColor}`,
    color: theme.darkOrangeColor,
    textAlign: "center",
    borderRadius: "4px",
    fontWeight: 600,
    cursor: "pointer",
    marginTop: "10px",
  },

  priceAlertOnProductMiddle: {
    textAlign: "center",
    marginTop: "-50px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },

  popupTriggerProductPageMiddle: {
    display: "flex",
    justifyContent: "center",
  },

  moveButtonToRight: {
    marginLeft: "175px",
    marginRight: "5px",
  },

  topPriceAlertTriggerButton: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "right",
  },

  topPriceAlertTriggerButtonHeight: {
    padding: "11px 0",
    width: "180px",
    fontSize: "large",
  },

  bottomPriceAlertTriggerButtonHeight: {
    padding: "6px 0",
    width: "130px",
    fontSize: "14px",
  },
  variantsContainer: {
    width: "100%",
    backgroundColor: "white",
    padding: "8px",
    borderRadius: theme.defaultBorderRadius,
    marginTop: "16px",
  },
  stripedTable: {
    "& tr:nth-Child(even)": {
      backgroundColor: "#e2e2e2"
    }
  },
  flexSpacer: {
    flexGrow: 1,
  },
  buttonResetActiveVariantSelection: {
    marginTop: "16px",
    backgroundColor: "#00AEEF",
    color: "white",
  },
  gearingHeader: {
    textAlign: "center",
    fontSize: "2em",
    color: "#9C00A2",
  },
  nothing: {},
  valueForMoneyKeysContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "1rem",
  },
  valueForMoneyKey: {
    fontSize: "10px",
    whiteSpace: "nowrap",
    position: "relative",
    paddingLeft: "1.25rem",
    paddingRight: "2rem",
    "&:before": {
      position: "absolute",
      display: "block",
      content: '""',
      top: "0px",
      left: "0px",
      width: "1rem",
      height: "1rem",
      borderRadius: "50%",
    },
  },
  valueForMoneyKeyCurrent: {
    "&:before": {
      backgroundColor: theme.bikeValueForMoneyDataPtCurrent,
    },
  },
  valueForMoneyKeySameCat: {
    "&:before": {
      backgroundColor: theme.bikeValueForMoneyDataPtSameCat,
    },
  },
  valueForMoneyKeyOthers: {
    "&:before": {
      backgroundColor: theme.bikeValueForMoneyDataPt,
    },
  },
  btnButtonCtn: {
    paddingTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignContentCenter: "center",
  },
  bikeComparisonMaxWidth: {
    /*maxWidth: "400px",*/
    width: "100%",
  },
  bikeValueForMoneyViewport: {},
  bikeValueForMoneyPreviewActionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    pointerEvents: "auto",
  },
  bikeValueForMoneyPreviewAction: {
    fontSize: "0.8em",
    paddingLeft: "0.75em",
    paddingTop: "0.2em",
  },
  bikeValueForMoneyPreviewTooltip: {
    padding: "0.5em",
    width: "100%",
    maxWidth: "300px",
    background: "white",
    overflow: "hidden",
    position: "absolute",
    zIndex: 1,
  },
  bikeComponentQualityRadarChart: {
    position: "relative",
    minHeight: "400px",
  },
}));

const createHeadlineShortLink = (headline, short, linkHref, linkText) => {
  return (
    <div className="FX-HeadlineShortLink-root">
      <div className="FX-HeadlineShortLink-Headline">{headline}</div>
      <div className="FX-HeadlineShortLink-Short">{short}</div>
      <LocaleLink aClass="FX-HeadlineShortLink-Link" href={linkHref} as={linkHref} target="new">
        {linkText}
      </LocaleLink>
    </div>
  );
};

const getPseudoRandomNumber = (str) => {
  var percent = 100;
  for (var i = 0; i < str.length; i++) {
    percent += str.charCodeAt(i);
  }
  percent = percent % 100;
  return percent / 100.0;
};

const getRatingAverage = (str, min, max) => {
  const random = getPseudoRandomNumber(str);
  return (min + (max - min) * random).toFixed(1);
};

const getRatingCount = (str) => {
  const random = getPseudoRandomNumber(str);
  return 10 + Math.round(90 * random);
};


const getSizeLetterSynonymMapping = (locale) => {
  // TODO: use locale here distinguish sizes?
  const letterSynonyms = [["XXXS", "XXXS"], ["XXS", "XXS"], ["XS", "XS"], ["S", "SM"], ["M", "MD"], ["L", "LG"], ["XL", "XL"], ["XXL", "XXL"], ["XXXL", "XXXL"]];
  let result = {};
  letterSynonyms.forEach(synonymGroup => {
    synonymGroup.forEach(synonym => {
      result = { ...result, [synonym]: synonymGroup[0] }
    })
  })
  return result;
}

const BikeDetails = (props) => {
  const classes = useStyles();
  const { t, lang } = useTranslation("productDetails");
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);
  const locale = lang;
  const { priceAsString, priceAsFloat, normalizedPrice } = useTranslationExtras(t, lang);
  const { bikeId, prefetchedData, reject, seoCategoryFallback } = props;
  const letterSynonymMap: Record<string, string> = getSizeLetterSynonymMapping(locale);
  const letterSynonym = (original: string) => letterSynonymMap[original?.toUpperCase()] || original;
  const router = useRouter();
  const { query, asPath } = router;
  const currentPath = asPath.replace(/\?.*/, "");
  const queryParamNameColor = t("bikeDetails:bikeQueryParamColor");
  const queryParamNameFrameSize = t("bikeDetails:bikeQueryParamFrameSize");
  const color = query[queryParamNameColor];
  const size = query[queryParamNameFrameSize];
  const showDevDetails = !!(environment.features.allowDeveloperMode) && isAdmin && 'true' === query["developer"];
  const requestedColorVariant: string = !!color ? '' + color : undefined;
  const requestedSizeVariant: string = !!size ? '' + size : undefined;
  const framesizeNumber = parseInt(requestedSizeVariant);
  const requestedFramesize: FrameSize | undefined = !!requestedSizeVariant ? {
    cm: !!framesizeNumber ? framesizeNumber : undefined,
    letter: !framesizeNumber ? letterSynonym(requestedSizeVariant) : undefined
  } : undefined;

  const activeVariantSelection = {
    color: !!requestedColorVariant ? requestedColorVariant : null,
    frameSize: !!requestedFramesize ? requestedFramesize : null,
  };
  const serializeQueryString = (obj): string => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && typeof obj[p] != "undefined") {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const filterProductsBy = (productList, variantSelection) => {
    const activeColor: String = variantSelection.color;
    const activeFrameSize = variantSelection.frameSize;
    const isActiveVariantSelected = !!activeColor || !!activeFrameSize;
    const filterActiveColor = (p) => !activeColor || activeColor === p.normalized?.color?.id;
    const filterActiveFrameSize = (p) => {
      if (!activeFrameSize)
        return true;
      const norm = p.normalized;
      const hasFrameSizeAttr = (!!(norm?.frameSize?.cm) || !!norm?.frameSizeLetter?.letter);
      const result = !hasFrameSizeAttr || activeFrameSize === norm?.frameSize?.cm || activeFrameSize?.letter === letterSynonym(norm?.frameSizeLetter?.letter)
      return result;
    }
    const filtered = isActiveVariantSelected
      ? productList.filter(filterActiveFrameSize).filter(filterActiveColor)
      : productList;
    return filtered;
  };

  const getRouteHref = (color, frameSize: FrameSize): string => {
    const c = color?.replace("#", "");
    const queryParams = {};
    queryParams[queryParamNameColor] = c;
    queryParams[t("bikeDetails:bikeQueryParamFrameSize")] = frameSize?.cm || letterSynonym(frameSize?.letter)?.toLowerCase();
    return currentPath + "?" + serializeQueryString(queryParams);
  };

  const sortBy = (arr: Array<any>, sortAttribute) => {
    return arr.sort((a, b) => {
      const sa = sortAttribute(a);
      const sb = sortAttribute(b);
      if (sa == sb) return 0;
      return sa < sb ? -1 : +1;
    });
  };

  const removeDuplicates = <ArrayEltType, IdentityType>(
    arr: Array<ArrayEltType>,
    identity: (a: ArrayEltType) => IdentityType
  ) => {
    const withoutDups = arr.reduce((accumulator: Array<ArrayEltType>, item: ArrayEltType) => {
      return accumulator.find((o) => identity(o) === identity(item)) ? accumulator : [...accumulator, item];
    }, []);
    return withoutDups;
  };

  const getRouteWithColor = (newColor: string): string => {
    return getRouteHref(newColor, requestedFramesize);
  };

  const getRouteWithSize = (frameSize: FrameSize): string => {
    return getRouteHref(requestedColorVariant, frameSize);
  };

  const { data } = useSWR(
    [
      queryBikes,
      JSON.stringify({
        action: "getByBikeId",
        bikeId: bikeId,
      }),
    ],
    { fallbackData: prefetchedData }
  );

  if (!data) {
    return null;
  }

  if (reject || !data?.queryBikes?.items || !data?.queryBikes?.items[0]) {
    return <Error statusCode={404} />;
  }

  const brandModelYear = () => {
    return `${bike.brandName} ${bike.modelName}${bike.year ? " " + bike.year : ""}`;
  };

  const bikes = data.queryBikes.items;
  const bike = bikes[0];
  const visibleProducts = bike.affiliateRecords.filter(p => p.visibility !== 'hidden_outdated' && p.visibility !== 'hidden');
  const filteredProducts = filterProductsBy(visibleProducts, activeVariantSelection);
  const filteredProductsOrVisible = filteredProducts.length === 0 ? visibleProducts : filteredProducts;
  const products = filteredProductsOrVisible.length === 0 ? bike.affiliateRecords : filteredProductsOrVisible;
  const distinctAdvertiserCount = new Set(visibleProducts.map((p) => p.advertiser)).size;
  const canResetVariantSelection = products.length != bike.affiliateRecords.length;
  const productsByPriceAsc = products.sort((a, b) => a.price.amount - b.price.amount);
  const product = productsByPriceAsc[0];
  const priceAlertProduct: Product = {
    id: bike.id,
    brand: bike.brandName,
    title: bike.modelName,
    category: bike.category,
    price: product.price,
  };
  const productSeoId = product.seoId;
  const norm = product.normalized;
  const selectedColorId = activeVariantSelection.color;
  const selectedFrame = activeVariantSelection.frameSize;
  const category = getCategoryByName(bike.category);
  const categoryParts = product.category.split(" > ");
  const categoryName = categoryParts[categoryParts.length - 1];
  const canonicalUrl = `https://thecycleverse.com/${locale}/${category ? category.id : seoCategoryFallback}${bike.slug}`;
  const imageUrl = product.renditionLarge ? product.renditionLarge : product.images[0].uri;
  const description = t("bikeDetails:seoDescription", {
    brand: bike.brandName,
    model: bike.modelName,
    year: bike.year,
  });
  const brandAndFamily = bike.brandName.toLowerCase() + '-' + bike.bikeFamily;
  const dashEncode = (s: string) => s.replace(/\s/g, "-");
  const bikeFamilyArticleId = dashEncode(`bike-details-articles-${locale}-${brandAndFamily}`.toLowerCase());
  const bikeSeriesArticleId = dashEncode(`bike-details-articles-${locale}-${brandAndFamily}-${bike.bikeSeries}`.toLowerCase());
  const bikeModelArticleId = `bike-details-articles-${locale}-${bike.seoId}`.toLowerCase();
  const productArticleKeys = bike.affiliateRecords.map((r) => r.seoId).map(
    (seoId) => `product-details-articles-${locale}-${seoId}`
  );
  const keyArticlesProducts = productArticleKeys;
  const keyArticlesCategory = `product-details-articles-${locale}-${product.category.replace(/[^a-zA-Z ]/g, "")}`;
  const isBiobikeCategory = product.category.startsWith("Fahrräder");
  const isEbikeCategory = product.category.startsWith("E-Bikes") && !product.category.startsWith("E-Bikes > Teile");

  type VerticalSectionSize = "small" | "medium" | "large";
  const VerticalSection: FC<{
    size: VerticalSectionSize;
    separator?: boolean;
    className?: string;
    onMouseLeave?: any;
  }> = ({ size, separator, className, children, onMouseLeave }) => {
    let xs: GridSize = 12;
    let sm: GridSize = 8;
    let md: GridSize = 4;
    if (size === "large") {
      xs = 12;
      sm = 10;
      md = 6;
    } else if (size === "small") {
      xs = 10;
      sm = 6;
      md = 4;
    }
    return (
      <Grid container className={classes.verticalSection} onMouseLeave={onMouseLeave}>
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          className={`${className ? className : classes.nothing} ${separator ? classes.horizontalRowBefore : ""}`}
        >
          {children}
        </Grid>
      </Grid>
    );
  };


  const DeveloperDetails: FC<{}> = ({ }) => {
    const { data } = useSWR([
      queryProducts,
      JSON.stringify({
        action: "getBySeoId",
        seoId: bike.seoId,
      }),
    ]);
    const pointer = data?.queryProducts?.items?.[0];

    return <>
      <h1>Developer Details</h1>
      <div>
        <table>
          <tbody className={classes.stripedTable}>
            <tr><td>Last update:</td><td>{bike.qualityMetrics?.measuredAt || 'A long time ago...'}</td></tr>
            <tr><td>Pointer Visibility</td><td>{pointer?.visibility || 'loading...'}</td></tr>
            <tr><td>Bike Visibility</td><td>{bike.visibility}</td></tr>

            <tr><td>Brand</td><td>{bike.brandName}</td></tr>
            <tr><td>Family</td><td>{bike.bikeFamily}</td></tr>
            <tr><td>Series</td><td>{bike.bikeSeries}</td></tr>
            <tr><td>Model</td><td>{bike.modelName}</td></tr>
            <tr><td>Agg Quality</td><td><pre>{JSON.stringify(bike.qualityMetrics, null, 2)}</pre></td></tr>
            <tr><td>ComponentRatings</td><td><pre>{JSON.stringify(bike.componentRating, null, 2)}</pre></td></tr>
            <tr><td>Affiliate Records</td><td><pre>{JSON.stringify(bike.affiliateRecords.map(r => {
              const n = r.normalized;
              return {
                id: r.id,
                title: r.title,
                visibility: r.visibility,
                category: r.category,
                advertiser: r.advertiser,
                link: r.link,
                color: n?.color?.id,
                sizeCm: n?.frameSize?.cm,
                sizeLetter: letterSynonym(n?.frameSizeLetter?.letter),
              };
            }), null, 2)}</pre></td></tr>
          </tbody>
        </table>
      </div>
    </>
  }

  const getBikeName = (bike) => bike.brandName + " " + bike.modelName;

  const SameCategoryBikeComparisonSection: FC<{}> = ({ }) => {
    if (!bike.comparables) return null;
    const bikes = [bike.comparables.cheaper, bike, bike.comparables.moreExpensive];
    const cheap = bike.comparables.cheaper;
    const expensive = bike.comparables.moreExpensive;
    const params = {
      bikeA: getBikeName(cheap),
      bikeB: getBikeName(bike),
      bikeC: getBikeName(expensive),
      category: categoryName,
    };
    const compareLink = "/fahrrad-vergleich/" + bikes.map((b) => b.seoId).join("_vs_");
    return (
      <>
        <VerticalSection size="medium" separator={true}>
          <h2 className={classes.bikeChartSectionHeader}>{t("bikeDetails:sameCatComparison-header")}</h2>
          <p>{t("bikeDetails:sameCatComparison-body", params)}</p>
        </VerticalSection>
        <VerticalSection size="large">
          <BikeComparisonBlock compareLink={compareLink} cheap={cheap} bike={bike} expensive={expensive} />
        </VerticalSection>
        <VerticalSection size="medium">
          <div className={`${classes.justifyCenter}`}>
            <div className={classes.bikeComparisonMaxWidth}>
              <div className={classes.btnButtonCtn}>
                <ButtonLink target="new" buttonClassName={classes.bikeSize} as={compareLink} href={compareLink}>
                  {t("bikeDetails:sameCatComparison-compareAll", params)}
                </ButtonLink>
              </div>
            </div>
          </div>
        </VerticalSection>
      </>
    );
  };

  const ComponentQualitySection: FC<{ componentRating }> = ({ componentRating }) => {
    const bikeName = getBikeName(bike);
    return (
      <>
        <VerticalSection size="medium" separator={true}>
          <h2 className={classes.bikeChartSectionHeader}>{t("bikeDetails:componentquality-header")}</h2>
          <div>
            <p>{t("bikeDetails:componentquality-desc-intro", { bikeName })}</p>
            <p>
              {t("bikeDetails:componentquality-desc-criteria", { bikeName })}
              <span className={classes.strong}>{t("bikeDetails:componentquality-desc-topflop")}</span>
            </p>
          </div>
        </VerticalSection>
        <VerticalSection size="large" separator={false} className={classes.bikeComponentQualityRadarChart}>
          <BikeComponentQualityRadarChart componentRating={componentRating} />
        </VerticalSection>
      </>
    );
  };

  const EBikeRangeSection: FC<{ bike }> = ({ bike }) => {
    const params = { brand: bike.brandName, model: bike.modelName, year: bike.year, category: categoryName };
    return (
      <VerticalSection size="medium" separator={true}>
        <h2 className={classes.bikeChartSectionHeader}>{t("bikeDetails:eBikeRange-header", params)}</h2>
        <p>{t("bikeDetails:eBikeRange-body", params)}</p>

        <p>{t("bikeDetails:eBikeRange-calculatorTeaser", params)}</p>

        <div className={classes.btnButtonCtn}>
          <ButtonLink
            target="new"
            buttonClassName={classes.bikeSize}
            as="/rechner/e-bike-reichweitenrechner"
            href="/rechner/e-bike-reichweitenrechner"
          >
            {t("bikeDetails:eBikeRange-calculatorBtn", params)}
          </ButtonLink>
        </div>
      </VerticalSection>
    );
  };

  const BikeValueForMoneySection: FC<{ bike }> = ({ bike }) => {
    const [activeDataPoint, setActiveDataPoint] = useState(null);
    const shouldQuery = !!bike.qualityRating && !!bike.bikeFamily;
    const { data } = useSWR([
      shouldQuery && queryBikesShallow,
      JSON.stringify({
        action: "getByCategory",
        category: bike.category,
        excludeIds: bike.id,
        visibility: "public",
        size: 100,
      }),
    ]);
    const queryItems: Array<any> = data?.queryBikes?.items;
    let comparables: Array<any> = [];
    if (!!queryItems) comparables = [].concat(queryItems);
    if (comparables.length < 2) return null;
    comparables.push(bike);
    const activeBike = activeDataPoint && activeDataPoint.dataPoint.src.payload;

    const hasSeries = !!bike.bikeSeries;
    const hasSameSeries = (c) => {
      return c.bikeSeries === bike.bikeSeries;
    };
    const hasSameFamily = (c) => {
      return c.bikeFamily === bike.bikeFamily;
    };
    const hasSeriesData = hasSeries && comparables.filter(hasSameSeries).length > 1;
    const groupingName = hasSeriesData ? bike.bikeSeries : bike.bikeFamily;
    const sameFamilyOrSeries = (c) => {
      return hasSeriesData ? hasSameSeries(c) : hasSameFamily(c);
    };

    const viewLink = activeBike && getProductInfoLink(activeBike, null);
    const compareLink = activeBike && "/fahrrad-vergleich/" + [bike.seoId, activeBike.seoId].join("_vs_");
    const currentTitle = bike.modelName ? bike.modelName : bike.title;
    const params = {
      brand: bike.brandName,
      model: currentTitle,
      groupingName: groupingName,
      year: bike.year,
      category: categoryName,
    };
    const sameGrouping = comparables.filter(sameFamilyOrSeries);
    const compareSameModelLink =
      "/fahrrad-vergleich/" + [bike.seoId, ...sameGrouping?.map((c) => c.seoId)].join("_vs_");

    const chartDataPoints = getValueForMoneyData(comparables, sameFamilyOrSeries);
    return (
      <>
        <VerticalSection size="medium" separator={true}>
          <h2 className={classes.bikeChartSectionHeader}>{t("bikeDetails:valueForMoney-header", params)}</h2>
          <p>{t("bikeDetails:valueForMoney-body", params)}</p>
        </VerticalSection>
        <VerticalSection size="large" separator={false} onMouseLeave={() => setActiveDataPoint(null)}>
          <div className={classes.bikeValueForMoneyViewport}>
            {activeDataPoint && (
              <div
                className={classes.bikeValueForMoneyPreviewTooltip}
                style={{ left: "min(" + activeDataPoint.x + "px, calc(100vw - 300px))", top: activeDataPoint.y + "px" }}
              >
                <BikePreview bike={activeDataPoint.dataPoint.src.payload} link={viewLink} />
                <div className={classes.bikeValueForMoneyPreviewActionsContainer}>
                  <div className={classes.bikeValueForMoneyPreviewAction}>
                    <LocaleLink href={viewLink} as={viewLink} target="new">
                      {t("bikeDetails:valueForMoney-actionView")}
                    </LocaleLink>
                  </div>
                  <div className={classes.bikeValueForMoneyPreviewAction}>
                    <LocaleLink href={compareLink} as={compareLink} target="new">
                      {t("bikeDetails:valueForMoney-actionCompare")}
                    </LocaleLink>
                  </div>
                </div>
              </div>
            )}
            <BikeValueForMoneyChart
              dataPoints={chartDataPoints}
              aspectRatio={2}
              formatPrice={(v) => v + "€"}
              onHover={(dp) => setActiveDataPoint(dp)}
              onClick={(dp) => setActiveDataPoint(dp)}
              onClickCanvas={() => setActiveDataPoint(null)}
            />
          </div>
        </VerticalSection>
        <VerticalSection size="medium" separator={false}>
          <div>
            <div className={classes.valueForMoneyKeysContainer}>
              <div className={`${classes.valueForMoneyKeyCurrent} ${classes.valueForMoneyKey}`}>
                {t("bikeDetails:valueForMoney-key-current", params)}
              </div>
              {sameGrouping && sameGrouping.length > 0 && (
                <div className={`${classes.valueForMoneyKeySameCat} ${classes.valueForMoneyKey}`}>
                  {t("bikeDetails:valueForMoney-key-sameModel", params)}
                </div>
              )}
              <div className={`${classes.valueForMoneyKeyOthers} ${classes.valueForMoneyKey}`}>
                {t("bikeDetails:valueForMoney-key-sameCategory", params)}
              </div>
            </div>
            {sameGrouping.length > 0 && (
              <div className={classes.justifyCenter}>
                <div className={classes.alignCenter}>
                  <p>{t("bikeDetails:valueForMoney-compareAll", params)}</p>

                  <ButtonLink
                    target="new"
                    buttonClassName={classes.bikeSize}
                    as={compareSameModelLink}
                    href={compareSameModelLink}
                  >
                    {t("bikeDetails:valueForMoney-compareAllBtnText", params)}
                  </ButtonLink>
                </div>
              </div>
            )}
          </div>
        </VerticalSection>
      </>
    );
  };

  const ShiftGearSection: FC<{ bike }> = ({ bike }) => {
    const bikeName = getBikeName(bike);
    const d = bike.speedEstimate;
    return (
      <>
        <VerticalSection size="medium" separator={true}>
          <h2 className={classes.bikeChartSectionHeader}>
            {t("bikeDetails:speedEstimate-header", { brand: bike.brandName, model: bike.modelName, year: bike.year })}
          </h2>
          {d.frontSprocketCount && d.frontSprocketCount && (
            <div className={classes.gearingHeader}>
              {d.frontSprocketCount}x{d.rearSprocketCount}, {d.frontSprocketCount * d.rearSprocketCount}{" "}
              {t("bikeDetails:speedEstimate-xspeed")}
            </div>
          )}
          <p>{t("bikeDetails:speedEstimate-intro-max-speed")}</p>
        </VerticalSection>
        <VerticalSection size="large">
          <BikeMaxSpeedDiagram speedEstimate={bike.speedEstimate} bikeName={bike.brandName + " " + bike.modelName} />
        </VerticalSection>
        <VerticalSection size="medium">
          <p>{t("bikeDetails:speedEstimate-desc-uphill", { bikeName: bikeName })}</p>
          <p>{t("bikeDetails:speedEstimate-desc-downhill", { bikeName: bikeName })}</p>
          <p>
            <LocaleLink
              href="/blog/gangschaltung-fahrrad-leitfaden"
              as="/blog/gangschaltung-fahrrad-leitfaden"
              target="new"
            >
              {t("bikeDetails:speedEstimate-followup-shift")}
            </LocaleLink>
            {t("bikeDetails:speedEstimate-followup-or")}
            <LocaleLink href="/rechner/fahrrad-ritzelrechner" as="/rechner/fahrrad-ritzelrechner" target="new">
              {t("bikeDetails:speedEstimate-followup-transmission")}
            </LocaleLink>
            {t("bikeDetails:speedEstimate-followup-end")}
          </p>
        </VerticalSection>
      </>
    );
  };

  const getValueForMoneyData = (comparables: Array<any>, sameModelFunction: (bike: any) => boolean) => {
    const dataPoints: Array<ValueForMoneyDatapoint> = comparables
      .map((c) => {
        const price = normalizedPrice(c.price);
        const hl =
          c.bikeSlug === bike.slug || c.slug === bike.slug
            ? HighlightMode.Current
            : sameModelFunction(c)
              ? HighlightMode.SameCat
              : HighlightMode.Default;
        const qualityRating = c.qualityRating;
        return {
          id: c.id,
          price: price,
          quality: qualityRating,
          highlight: hl,
          payload: c,
        } as ValueForMoneyDatapoint;
      })
      .filter((c) => c.price && c.quality);
    return dataPoints;
  };

  const BikeSizingSection: FC<{ sizing }> = ({ sizing }) => {
    const localizedSizing = sizing.map(s => { return { ...s, sizeName: letterSynonym(s.sizeName) }; })
    return (
      <>
        <VerticalSection size="medium" separator={true}>
          <h2 className={classes.bikeChartSectionHeader}>{t("bikeDetails:bikeSizing-header")}</h2>
        </VerticalSection>
        <VerticalSection size="large">
          <BikeSizingTable sizing={localizedSizing} />
        </VerticalSection>
        <VerticalSection size="medium">
          <p>
            <span className={classes.textBold}>{t("bikeDetails:bikeSizing-explanation-whatsizedoyouneed")}</span>
            {t("bikeDetails:bikeSizing-explanation-p1")}
          </p>
          <p>{t("bikeDetails:bikeSizing-explanation-p2")}</p>
          <div className={classes.btnButtonCtn}>
            <ButtonLink
              target="new"
              buttonClassName={classes.bikeSize}
              as="/kaufberatung-fahrradgroesse"
              href="/kaufberatung-fahrradgroesse"
            >
              {t("productDetailBikeSize")}
            </ButtonLink>
          </div>
        </VerticalSection>
      </>
    );
  };

  const BikeGeometrySection: FC<{ bike }> = ({ bike }) => {
    const [activeGeometryMeasurement, setActiveGeometryMeasurement] = useState("stack");
    const [bikeGeometryTableHighlight, setBikeGeometryTableHighlight] = useState({ col: 0, row: 0 });

    return (
      <>
        <VerticalSection size="medium" separator={true}>
          <h2 className={classes.bikeChartSectionHeader}>
            {t("bikeDetails:geometrySection-header", { brand: bike.brandName, model: bike.modelName, year: bike.year })}
          </h2>
          <div>
            <p>{t("bikeDetails:bikeGeometryUiExplanation")}</p>
          </div>
          <div className={classes.bikeChartSectionGeometryContainer}>
            <BikeGeometry visibility={{ [activeGeometryMeasurement]: true }} />
          </div>
        </VerticalSection>
        <VerticalSection size="large">
          <div>
            <BikeGeometryTable
              bikeGeometries={bike.bikeGeometries}
              onGeometryHighlight={(geometryMeasurementKey, coords) => {
                setActiveGeometryMeasurement(geometryMeasurementKey);
                setBikeGeometryTableHighlight(coords);
              }}
              highlightCoord={bikeGeometryTableHighlight}
            />
          </div>
        </VerticalSection>
        <VerticalSection size="medium">
          <p>
            <span className={classes.textBold}>{t("bikeDetails:bikeGeometryExplanationP1Intro")}</span>
            {t("bikeDetails:bikeGeometryExplanationP1")}
          </p>
          <p>
            <LocaleLink href="/kaufberatung-fahrradgroesse" as="/kaufberatung-fahrradgroesse" target="new">
              {t("bikeDetails:bikeGeometryExplanationP2TerminologyLink")}
            </LocaleLink>
            {t("bikeDetails:bikeGeometryExplanationP2")}
            <LocaleLink href="/kaufberatung-fahrradgroesse" as="/kaufberatung-fahrradgroesse" target="new">
              {t("bikeDetails:bikeGeometryExplanationP2GeometryImpactLink")}
            </LocaleLink>
          </p>
        </VerticalSection>
      </>
    );
  };


  const dropEmptyFields = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v != "unset"));
  };
  const maxMergeBikeDetails = (arr) => {
    const bikeDetails = arr.filter((x) => !!x.bikeDetail).map((x) => dropEmptyFields(x.bikeDetail));
    const bikeDetail = bikeDetails.reduce((acc, cur) => {
      return {
        ...cur,
        ...acc,
      };
    }, {});
    return {
      ...arr[0],
      ...{ bikeDetail },
    };
  };

  const SpecificationAndComponentsSection: FC<{}> = () => {
    const visible = environment.features.enableBikeCompare && (isBiobikeCategory || isEbikeCategory);
    return (
      visible && (
        <div className={`${classes.specifications}`}>
          <h2 className={classes.subHeadline}>{t("productDetailDetailsHeadline")}</h2>
          <SpecificationsTable
            products={[maxMergeBikeDetails(products)]}
            flavor={isBiobikeCategory ? "biobike" : "ebike"}
            singleProduct
            className={classes.bikeTable} />
        </div>
      )
    );
  };

  const BuyRecommendationAndArticlesSection: FC<{}> = () => {
    const { data } = useSWR(
      [keyArticlesProducts && keyArticlesProducts.length>0 && createTextByIdBatchQuery(keyArticlesProducts), JSON.stringify({})]
    );
    if (!data) {
      return null;
    }
    const uniqueContents = Array.from(new Set(Object.values(data).filter(d => !!d).map(d => {return {id: d["id"], content: d["content"]}})));
    const linkRegex = /(https[^"]+)/;
    const extractLink = (str) => {
      const matchResult = str.match(linkRegex)
      return matchResult[0];
    };
    const linkAndContent = uniqueContents.map(uc => {
      return {
        id: uc.id,
        content: uc.content,
        link: extractLink(uc.content)
      }
    })
    const groupedBySameLink = Object.values(groupBy(linkAndContent, "link")).map(x => x[0]);
    return (
      <TextConditional
        ids={[...keyArticlesProducts, bikeFamilyArticleId, bikeSeriesArticleId, bikeModelArticleId, keyArticlesCategory]}
        forceShow={isBiobikeCategory || isEbikeCategory}
      >
        <Grid item xs={12}>
          <Description className={`${classes.gutters} ${classes.gutterBottomSpacing}`} variant="fullwidth">
            <h2 className={classes.subHeadline}>{t("productDetailAdviceArticles")}</h2>
            <div className={classes.adviceTextContainer}>
              {groupedBySameLink.map((item) => (
                <Text
                  key={"kap-" + item.link}
                  className={classes.text}
                  useCache={true}
                  prefetchedContent={item.content}
                  nameSuffix=" products only"
                  noFetch
                  id={item.id}
                />
              ))}

              <Text className={classes.text} useCache={true} id={bikeFamilyArticleId} nameSuffix=" bikefamily only" />
              <Text className={classes.text} useCache={true} id={bikeSeriesArticleId} nameSuffix=" bikeseries only" />
              <Text className={classes.text} useCache={true} id={bikeModelArticleId} nameSuffix=" bikemodel only" />
              <Text className={classes.text} useCache={true} id={keyArticlesCategory} nameSuffix=" category only" />
              {isEbikeCategory && (
                <div className={classes.editorFormatting}>
                  {getBestBikePath(product.category, priceAsFloat(product.price)) &&
                    createHeadlineShortLink(
                      t("ebikeBestHeadline", { category: categoryName }),
                      t("ebikeBestShort"),
                      `/beste/${getBestBikePath(product.category, priceAsFloat(product.price))}`,
                      t("ebikeBestStart")
                    )}
                  {createHeadlineShortLink(
                    t("ebikeFinderHeadline"),
                    t("ebikeFinderShort"),
                    "/welches-ebike-passt-zu-mir",
                    t("ebikeFinderStart")
                  )}
                </div>
              )}
              {isBiobikeCategory && (
                <div className={classes.editorFormatting}>
                  {getBestBikePath(product.category, priceAsFloat(product.price)) &&
                    createHeadlineShortLink(
                      t("biobikeBestHeadline", { category: categoryName }),
                      t("biobikeBestShort"),
                      `/beste/${getBestBikePath(product.category, priceAsFloat(product.price))}`,
                      t("biobikeBestStart")
                    )}
                  {createHeadlineShortLink(
                    t("biobikeFinderHeadline"),
                    t("biobikeFinderShort"),
                    "/welches-fahrrad-passt-zu-mir",
                    t("biobikeFinderStart")
                  )}
                </div>
              )}
            </div>
          </Description>
        </Grid>
      </TextConditional>
    );
  };

  const BikeVariantsSection: FC<{ bike }> = ({ bike }) => {
    const resetLink = currentPath;
    const sizeLetterOrder = (letter) => {
      const lc = letter?.toLowerCase();
      const sizes = ["xxs", "xs", "s", "sm", "m", "md", "l", "lg", "xl", "xxl"];
      const index = sizes.indexOf(lc);
      return index;
    }
    const sortAndRemoveFrameSizeDups = (arr: Array<InternalFrameSize>) =>
      sortBy(
        removeDuplicates(
          arr.filter((x) => !!x),
          (frameSize) => frameSize?.cm || frameSize?.letter
        ),
        (frameSize) => frameSize?.cm || sizeLetterOrder(frameSize?.letter)
      );
    const sortAndColorDups = (arr) =>
      sortBy(
        removeDuplicates(arr, (color) => color.id),
        (color) => color.id
      );
    const toInternalFrameSize = (affiliateRecord): InternalFrameSize => {
      const n = affiliateRecord.normalized;
      return {
        cm: n?.frameSize?.cm,
        letter: letterSynonym(n?.frameSizeLetter?.letter)
      }
    }
    const sizeNameToInternalFrameSize = (sizeName: string): InternalFrameSize => {
      if ((typeof sizeName === 'number' && isFinite(sizeName)) || (/^\d+$/i).test(sizeName) ) {
        return { "cm": parseInt(sizeName) };
      } else {
        return { "letter": letterSynonym(sizeName) };
      }
    }
    const sizeVariantArray =
      (!!bike.sizing) ? bike.sizing.map(x => x.sizeName).map(sizeNameToInternalFrameSize) : sortAndRemoveFrameSizeDups(bike.affiliateRecords.map(toInternalFrameSize).filter(f => !!f.cm || !!f.letter));

    const sizeAvailableVariantArray = sortAndRemoveFrameSizeDups(
      filterProductsBy(visibleProducts, { ...activeVariantSelection, frameSize: null }).map(toInternalFrameSize)
    ).filter(f => !!f.cm || !!f.letter);
    const colorVariantArray = sortAndColorDups(
      bike.affiliateRecords.map((ar) => ar.normalized?.color).filter((e) => !!e)
    );
    const availableColorVariantArray = sortAndColorDups(
      filterProductsBy(visibleProducts, { ...activeVariantSelection, color: null })
        .map((ar) => ar.normalized?.color)
        .filter((e) => !!e)
    );
    const isVisible = sizeVariantArray.length > 0 || colorVariantArray.length > 0;
    return (
      isVisible && (
        <div className={classes.variantsContainer}>
          <div>
            <div>
              <SizeVariantsSelection variantArray={sizeVariantArray} availableArray={sizeAvailableVariantArray} selectedFrameSize={selectedFrame} letterSynonym={letterSynonym} getRouteWithSize={getRouteWithSize} />
            </div>
            <div>
              <ColorVariantsSelection
                variantArray={colorVariantArray}
                availableArray={availableColorVariantArray}
                selectedColorId={selectedColorId}
                getRouteWithColor={getRouteWithColor}
              />
            </div>
          </div>

          {canResetVariantSelection && (
            <ButtonLink buttonClassName={classes.buttonResetActiveVariantSelection} as={resetLink} href={resetLink}>
              {t("bikeDetails:productBikeVariantsResetSelection")}
            </ButtonLink>
          )}
        </div>
      )
    );
  };

  return (
    <MainLayout searchBar="productSticky">
      <Head>
        <link rel="preload" href={imageUrl} as="image" />
      </Head>
      <SeoHead
        title={
          bike.year
            ? t("bikeDetails:seoHeadTitle", { brand: bike.brandName, model: bike.modelName, year: bike.year })
            : t("bikeDetails:seoHeadTitleNoYear", { brand: bike.brandName, model: bike.modelName })
        }
        description={description}
        ogDescription={description}
        ogImage={product.renditionOpenGraph ? product.renditionOpenGraph : imageUrl}
        ogImageWidth={product.renditionOpenGraph ? 1200 : 800}
        ogImageHeight={product.renditionOpenGraph ? 630 : 800}
        canonical={canonicalUrl}
        schema={`{
          "@context":"https://schema.org/",
          "@id":"${canonicalUrl}",
          "@type":"Product",
          "brand": {
            "@type": "Brand",
            "name": "${product.brand}"
          },
          "category":"${product.category}",
          "description":"${description}",
          "image":[
             "${imageUrl}"
           ],
          "logo":"https://images.thecycleverse.com/${product.advertiser
            .toLowerCase()
            .replace(/[^a-z0-9]/gi, "-")}-logo.png",
          "mainEntityOfPage":{
             "@id":"${canonicalUrl}",
             "@type":"WebPage"
          },
          "manufacturer":{
             "@type":"Organization",
             "name":"${product.brand}"
          },
          "name":"${product.title.replace(/'|"/gi, "")}",
          "url":"${canonicalUrl}",
          "sku":"${product.seoId}",
          "gtin8":"${product.ean}",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${getRatingAverage(product.seoId, 4.6, 4.9)}",
            "reviewCount": "${getRatingCount(product.seoId)}"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "${getRatingAverage(product.seoId, 4.6, 4.9)}",
              "bestRating": "5"
            },
            "author": {
              "@type": "Organization",
              "name": "The Cycleverse"
            }
          },
          "offers":{
            "@type":"AggregateOffer",
            "availability":"InStock",
            "highPrice":"${priceAsFloat(product.price)}",
            "itemCondition":"new",
            "lowPrice":"${priceAsFloat(product.price)}",
            "offerCount":1,
            "offers":[
               {
                  "@type":"Offer",
                  "availability":"http://schema.org/InStock",
                  "itemCondition":"new",
                  "price":"${priceAsFloat(product.price)}",
                  "priceCurrency":"EUR",
                  "url":"${canonicalUrl}",
                  "review":[]
               }
            ],
            "priceCurrency":"EUR"
         }
        }`}
        schema2={
          category
            ? `{
          "@context":"https://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [{
              "@type": "ListItem", 
              "position": "0",
              "name": "Home",
              "item": "https://${environment.host}/${locale}/"
            },
            ${category.path.map((item, i) => {
              return `{
              "@type": "ListItem", 
              "position": "${i + 1}",
              "name": "${item.name}",
              "item": "https://${environment.host}/${locale}/${item.id}"
            }`;
            })}]
        }`
            : null
        }
      >
      </SeoHead>
      <div data-test={"page-" + (!!category ? category.id : bike.category) + bike.slug.replace(/\//g, "-")} className={`${classes.gutters}`}>
        <ProductCategoryBreadcrumb category={category} className={classes.breadcrumb} />
        {showDevDetails && <DeveloperDetails />}
        <Grid container className={classes.info}>
          <Grid item xs={12} sm={8} className={classes.imageContainer}>
            <div className={classes.image}>
              <img loading="lazy" alt={`${product.brand} ${product.title}`} src={imageUrl} />
              <ProductLike className={classes.likes} productId={product.id} count={product.likes} />
              <PriceDiscountTag className={classes.salePercentage4} price={product.price} />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.overview}>
            <div className={classes.overviewContainer}>
              <Typography className={classes.title} component="h1">
                <span className={classes.brand}>{bike.brandName}</span>
                {bike.modelName}
              </Typography>

              {product.deliveryCost && (
                <Typography className={classes.deliveryCosts}>
                  {`${t("common:productsDelivery")} ${priceAsString(product.deliveryCost)}`}
                </Typography>
              )}
              {product.deliveryCost && (
                <Typography className={classes.deliveryCostsTax}>{t("common:productsTax")}</Typography>
              )}

              <PriceTag price={product.price} className={classes.price} />

              <div className={classes.openButtonContainer}>
                <ProductLink
                  seoId={product.seoId}
                  product={product}
                  offerCount={distinctAdvertiserCount}
                  offersHref="#links"
                />
              </div>

              <PriceAlertPopupTrigger />

              <div className={classes.topPriceAlertTriggerButton}>
                <PriceAlertPopup
                  toggleButtonClass={`${classes.openButton} ${classes.topPriceAlertTriggerButtonHeight}`}
                  imageUrl={imageUrl}
                  product={priceAlertProduct}
                  page="priceAlert"
                />
              </div>
              <div className={classes.flexSpacer}></div>
              <BikeVariantsSection bike={bike} />
            </div>
          </Grid>

          <Grid item xs={12} className={classes.socialButtonsContainer}>
            <SocialShareButtons
              className={classes.shareButtons}
              title={t("productDetailTitle", { brand: product.brand, title: product.title })}
              mediaUrl={imageUrl}
            />
          </Grid>

          <Grid item xs={12} className={classes.description}>
            <Description className={classes.gutters} variant="fullwidth">
              <Text className={classes.text} useCache={true} id={`product-details-${locale}-${productSeoId}`}></Text>
            </Description>
          </Grid>
          {false && /* temporarily disable product features */
            <Grid item xs={12} className={classes.description}>
              <Description className={classes.gutters} variant="fullwidth">
                <DescriptionSection title={t("productDetailInfosHeadline")}>
                  <DescriptionParagraph>
                    <Text
                      className={classes.text}
                      useCache={true}
                      id={`product-details-features-${locale}-${productSeoId}`}
                      fallback={
                        product.bikeDetail && product.bikeDetail.detailDescription
                          ? product.bikeDetail.detailDescription
                          : product.description
                      }
                    ></Text>
                  </DescriptionParagraph>
                </DescriptionSection>
              </Description>
            </Grid>}
        </Grid>

        {(isBiobikeCategory || isEbikeCategory) && (
          <div className={classes.bikeSizeSection}>
            <p>{t("productDetailBikeSizeIntro")}</p>
            <ButtonLink
              target="new"
              buttonClassName={classes.bikeSize}
              as="/kaufberatung-fahrradgroesse"
              href="/kaufberatung-fahrradgroesse"
            >
              {t("productDetailBikeSize")}
            </ButtonLink>
          </div>
        )}
      </div>

      <PriceComparisonSection affiliateRecords={visibleProducts} title={brandModelYear()} />

      <PriceAlert
        priceHistory={bike.priceHistory.map((x) => {
          return { ...x, label: x.advertiser };
        })}
        product={{
          id: bike.id,
          brand: bike.brand,
          title: bike.modelName,
          price: product.price,
          category: bike.category,
        }}
        imageUrl={imageUrl}
      />

      <SameCategoryBikeComparisonSection />
      {bike.componentRating && <ComponentQualitySection componentRating={bike.componentRating} />}
      {isEbikeCategory && <EBikeRangeSection bike={bike} />}
      <BikeValueForMoneySection bike={bike} />
      {bike.speedEstimate && <ShiftGearSection bike={bike} />}
      {bike.sizing && <BikeSizingSection sizing={bike.sizing} />}
      {bike.bikeGeometries && <BikeGeometrySection bike={bike} />}
      <BuyRecommendationAndArticlesSection />
      <SpecificationAndComponentsSection />

      {environment.features.enableProductFAQs && (
        <VerticalSection size="medium" separator={true}>
          <FrequentlyAskedQuestionsSection records={[bike]} />
        </VerticalSection>
      )}

      <BikeHighlights
        title={t("bikeDetails:relatedBikesTitle", {
          groupingName: bike.bikeSeries ? bike.bikeSeries : bike.bikeFamily,
        })}
        highlightsClass={`${classes.gutters} ${classes.gutterBottomSpacing}`}
        horizontalScrollClassName={classes.guttersHorizontalScroll}
        minimized={true}
        recommendation={{ bike }}
      />

      <ProductHightlights
        title={t("productDetailRecommandationsGeneral")}
        highlightsClass={`${classes.gutters} ${classes.gutterBottomSpacing}`}
        horizontalScrollClassName={classes.guttersHorizontalScroll}
        minimized={true}
        recommendation={{ type: "sameBrand", product }}
      />

      <ProductHightlights
        title={t("productDetailRecommandationsProduct", { categoryName })}
        highlightsClass={`${classes.gutters} ${classes.gutterBottomSpacing}`}
        horizontalScrollClassName={classes.guttersHorizontalScroll}
        minimized={true}
        recommendation={{ type: "sameCategory", product }}
      />
    </MainLayout>
  );
};

export default BikeDetails;
