import React, { useEffect, useState } from "react";

import ExternalLinkIcon from "../../Navigation/Icons/ExternalLinkIcon";
import OpenIcon from "../../Navigation/Icons/OpenIcon";
import ProductLinkComponent from "./ProductLinkComponent";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import { queryProducts } from "../../../graphql/queries";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  openButton: {
    backgroundColor: theme.hightlightColorTertiary,
    color: theme.palette.primary.main,
    padding: "10px 50px",
    fontSize: "large",
    "& .MuiButton-endIcon": {
      marginLeft: 12,
    },
  },
  disabledButton: {
    backgroundColor: theme.unflashyColor,
  },
  openIcon: {
    filter: "invert(1)",
    fontSize: 12,
  },
}));

type ProductLinkProps = {
  product: any;
  seoId: string; // id from the url
  offersHref: string;
  offerCount?: number;
};

const ProductLink = (props: ProductLinkProps) => {
  const { product, seoId, offersHref } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useSWR([
    !!props.offerCount ? null : queryProducts,
    JSON.stringify({
      action: "productOffers",
      ean: product.ean,
      seoId: seoId,
    }),
  ]);

  const itemCount = props.offerCount || data?.queryProducts?.items?.length;

  if (offersHref && (!itemCount || itemCount <= 1)) {
    return <ProductLinkComponent product={product} text={t("productInfoLink")} endIcon={<ExternalLinkIcon />} />;
  } else {
    return (
      <ProductLinkComponent
        product={product}
        overrideLink={offersHref}
        target="_self"
        text={t("productOffersLink", { itemCount })}
        endIcon={<OpenIcon className={classes.openIcon} />}
      />
    );
  }
};

export default ProductLink;
