import React, { FC } from "react";

import LocaleLink from "src/components/Navigation/LocaleLink";
import PriceAlertPopup from "./PriceAlertPopup";
import { TheCycleverseTheme } from "src/theme";
import { default as bikeSizing } from "src/bikeSizing.json";
import { makeStyles } from "@material-ui/core/styles";
import { queryProducts } from "../../../graphql/queries";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "../../../translations/useTranslationExtras";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  subHeadline: {
    fontSize: 20,
    fontFamily: "inherit",
    fontWeight: 900,
    marginBottom: 8,
    textAlign: "center",
  },
  faqQuestion: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: "left",
    background: theme.faqBackgroundWhite,
  },
  faqQuestionTitle: {
    fontSize: "medium",
    margin: 0,
    marginBlockStart: 0,
  },
  faqAnswer: {
    fontSize: "medium",
  },
  faqLink: {
    backgroundColor: theme.palette.primary.main,
    color: theme.openButtonColor,
  },
  proTipp: {
    fontWeight: "bold",
  },
  priceAlertLink: {
    color: theme.linkColor,
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const getCategoryDisplayName = (category) => {
  if (!category) return category;
  const parts = category.split(">");
  const lastPart = parts[parts.length - 1];
  return lastPart.trim();
};

const addSuffixIfMissing = (text: string, suffix: string) => {
  let result = text.trim();
  if (result.endsWith(suffix)) return result;
  else return result + suffix;
};

const resolveFirst = (resolvers: Array<() => any>): any => {
  let result = undefined;
  for (let index = 0; !result && index < resolvers.length; index++) {
    result = resolvers[index]();
  }
  return result;
};

const FrequentlyAskedQuestionsSection = (props) => {
  const { records } = props;
  const classes = useStyles();
  const { t, lang } = useTranslation("frequentlyAskedQuestions");
  const {
    priceAsString,
    priceWithoutDiscountAsString,
    priceAsFloat,
    priceWithoutDiscountAsFloat,
  } = useTranslationExtras(t, lang);
  const sepAnd = " " + t("common:utilSeparatorAnd") + " ";
  const sepComma = t("common:utilSeparatorComma");
  const commaCommaAnd = (arr: string[]) => {
    const elts = arr.length;
    return arr.flatMap((e, ix) => (ix == 0 ? [e] : [ix === elts - 1 ? sepAnd : sepComma, e])).join("");
  };

  const QuestionAndAnswer: FC<{ question: JSX.Element | string; answer: JSX.Element | string }> = ({
    question,
    answer,
  }) => {
    return (
      <div className={classes.faqQuestion}>
        <h3 className={classes.faqQuestionTitle}>{question}</h3>
        <div className={classes.faqAnswer}>{answer}</div>
      </div>
    );
  };

  const recordData = records[0];
  const isBike = !!recordData.recordType && recordData.recordType === "bike";
  const cat = isBike ? recordData.category : recordData.category;
  const displayedCategory = getCategoryDisplayName(cat);
  const isBioBike = cat.startsWith("Fahrräder");
  const brand = isBike ? recordData.brandName : recordData.brand;
  const model = isBike
    ? recordData.modelName
    : recordData.bikeDetail?.model
    ? recordData.bikeDetail.model
    : recordData.title;
  const year = recordData.year ? recordData.year : "";
  const defaultParams: any = {
    brand: brand,
    model: model,
    year: year,
    modelAndYear: (model + " " + year).trim(),
  };
  const productRecords = isBike ? recordData.affiliateRecords : records;
  const publicProductRecords = productRecords.filter((r) => r.visibility?.startsWith("public"));
  const product = productRecords[0];
  const imageUrl = product.renditionLarge ? product.renditionLarge : product.images[0].uri;
  const { data } = useSWR([
    queryProducts,
    JSON.stringify({
      action: "productOffers",
      ean: product.ean,
      seoId: product.seoId,
    }),
  ]);
  const productOfferCount = data?.queryProducts?.items?.length;

  const getFromBikeDetails = (key) => {
    const values = getAllFromBikeDetails(key);
    return values ? values[0] : undefined;
  };

  const getAllFromRoot = (key, listOfProductRecords) => {
    return listOfProductRecords
      .filter((d) => !!d)
      .map((d) => d[key])
      .filter((d) => !!d);
  };

  const getAllFromBikeDetails = (key) => {
    const values = productRecords
      .map((p) => p.bikeDetail)
      .filter((d) => !!d)
      .map((d) => d[key])
      .filter((d) => !!d);
    return values;
  };

  const question = (faqKey: string) => "faqQ" + faqKey;
  const answer = (faqKey: string) => "faqA" + faqKey;
  const basic = (faqKey: string, param: any = defaultParams) => {
    return (
      <QuestionAndAnswer key={faqKey} question={t(question(faqKey), defaultParams)} answer={t(answer(faqKey), param)} />
    );
  };
  const recommendedRetailPrice = () => {
    // Not used for now as we prefer consistent prices (with other component) over the best cheapest price
    const allPrices = publicProductRecords.map((ar) => ar.price);
    const bikePrice = allPrices.reduce((r, e) => {
      const priceReduced = (r.amountWithoutDiscount && priceWithoutDiscountAsFloat(r)) || priceAsFloat(r);
      const priceExisting = (e.amountWithoutDiscount && priceWithoutDiscountAsFloat(e)) || priceAsFloat(e.amount);
      return priceReduced < priceExisting ? r : e;
    });
    return priceWithoutDiscountAsString(bikePrice);
  };
  const renderRecommendedRetailPrice = () =>
    basic("RecommendedPrice", {
      ...defaultParams,
      price: priceWithoutDiscountAsString(product.price),
    });
  const renderCheaperOffers = () => {
    const offerCount = productOfferCount;
    const faqKey = "CheaperOffers";
    const params = {
      ...defaultParams,
      offercount: offerCount,
    };
    const answerKeyPrefix = answer(faqKey);
    const answerBlock = (
      <>
        <div>{t(answer(faqKey) + (offerCount > 1 ? "_multiple" : "_single"), params)}</div>
        <br />
        <span className={classes.proTipp}>{t(answerKeyPrefix + "_proTippHeader", params)} </span>
        {t(answerKeyPrefix + "_priceAlarm1", params)}&nbsp;
        <PriceAlertPopup
          toggleButtonClass={classes.priceAlertLink}
          imageUrl={imageUrl}
          product={product}
          page="priceAlert"
          inline={true}
        />
        &nbsp;
        {t(answerKeyPrefix + "_priceAlarm2", params)}
      </>
    );
    return <QuestionAndAnswer key={faqKey} question={t(question(faqKey), defaultParams)} answer={answerBlock} />;
  };
  const renderDeliveryCost = () => {
    const faqKey = "DeliveryCost";
    const answerPrefix = t(answer(faqKey));
    const deliveryCost = getAllFromRoot("deliveryCost", publicProductRecords);
    if (!!deliveryCost && deliveryCost.length > 0) {
      const minCost = deliveryCost.reduce((r, e) => (priceAsFloat(r) < priceAsFloat(e) ? r : e));
      const min = priceAsString(minCost, minCost.decimalOffset, false, true);
      const max = priceAsString(deliveryCost.reduce((r, e) => (priceAsFloat(r) < priceAsFloat(e) ? e : r)));
      const params = {
        ...defaultParams,
        feesCurrent: min,
        feesFrom: min,
        feesUntil: max,
      };
      let answerText = "";
      if (deliveryCost.filter((dc) => !!dc.amount).length === 0) answerText = t(answerPrefix + "_free", params);
      else if (deliveryCost.length === 1 || min === max) answerText = t(answerPrefix + "_single", params);
      else answerText = t(answerPrefix + "_range", params);

      return <QuestionAndAnswer key={faqKey} question={t(question(faqKey), params)} answer={answerText} />;
    }
  };

  const getCategoryUsageType = () => {
    return t("categoryUseCases:" + getCategoryDisplayName(cat), {}, { fallback: "" });
  };

  const renderUseCase = () => {
    const useCase = getCategoryUsageType();
    if (!!useCase) {
      return basic("UseCase", {
        ...defaultParams,
        useCase: useCase,
      });
    }
  };

  const renderFrameSizeGuess = () => {
    type Size = {
      n: string; // name
      b: Array<number>; // bodyheight mm from and to
      l?: Array<number>; // inner leg length mm from and to
      f?: Array<number>; // framesize mm from and to
    };
    type SizeDef = {
      brand: string;
      model: Array<string>;
      category: Array<string>;
      sizes: Array<Size>;
      sizeType?: "frame" | "default";
    };
    type ScoredSize = {
      score: number;
      size: SizeDef;
    };
    const score = (s: SizeDef): number => {
      let result = 0;
      const currentCategory = displayedCategory;
      const currentBrand = defaultParams.brand.toLowerCase();
      const currentModel = defaultParams.model.toLowerCase();
      const brand = s.brand?.toLowerCase();
      const models = s.model?.map((m) => m.toLowerCase());
      const categories = s.category?.map((c) => c.toLowerCase());
      if (brand === currentBrand) result += 1;
      if (!!categories && categories.find((c) => c === currentCategory)) result += 1;
      if (!!models && models.find((m) => currentModel.includes(m))) result += 3;
      return result;
    };
    const scores: Array<ScoredSize> = (bikeSizing as Array<SizeDef>)
      .map((s) => {
        return {
          size: s,
          score: score(s as SizeDef),
        };
      })
      .filter((x) => x.score > 0)
      .sort((a, b) => b.score - a.score);

    if (scores.length === 0) return null;

    const bestScore = scores.slice(0, 1);
    const sizes = bestScore.flatMap((x) => x.size.sizes);

    if (sizes.length === 0) return null;
    const sizeType = bestScore[0].size.sizeType === "frame" ? "sizeTypeFrame" : "sizeTypeDefault";
    return basic("Framesizes", {
      ...defaultParams,
      sizeNames: commaCommaAnd(sizes.map((x) => x["n"])),
      sizeType: t("faqAFramesizes_" + sizeType),
    });
  };

  const renderFrameSizes = () => {
    if (!isBike) return renderFrameSizeGuess();
    if (!recordData.sizing) return null;
    const sizeType = recordData.sizing[0].measurement === "leg" ? "sizeTypeInnerLegLength" : "sizeTypeDefault";
    return basic("Framesizes", {
      ...defaultParams,
      sizeNames: commaCommaAnd(recordData.sizing.map((g) => g.sizeName)),
      sizeType: t("faqAFramesizes_" + sizeType),
    });
  };

  const renderRightsize = () => {
    const faqKey = "Rightsize";
    const answerKeyPrefix = answer(faqKey);
    const params = {
      ...defaultParams,
    };
    const link = (
      <LocaleLink href="/kaufberatung-fahrradgroesse" as="/kaufberatung-fahrradgroesse" target="new">
        {t(answerKeyPrefix + "_2")}
      </LocaleLink>
    );
    const answerBlock = (
      <>
        {t(answerKeyPrefix + "_1", params)}
        {link}
        {t(answerKeyPrefix + "_3", params)}
      </>
    );
    return <QuestionAndAnswer key={faqKey} question={t(question(faqKey), defaultParams)} answer={answerBlock} />;
  };

  const resolveMaterialFrom = (frameNameText: string) => {
    if (!frameNameText) return null;
    const p = "faqMaterial_";
    const containsAWordLike = (...words: string[]) => words.filter((w) => frameNameText.includes(w)).length > 0;
    if (containsAWordLike("aluminum", "aluminium", "alloy")) return p + "aluminum";
    else if (containsAWordLike("steel", "stahl")) return p + "steel";
    else if (containsAWordLike("carbon", "kohlenstoff")) return p + "carbon";
    else if (containsAWordLike("titanium", "titan")) return p + "titanium";
    else if (containsAWordLike("bamboo", "bambus")) return p + "bamboo";
    else if (containsAWordLike("wood", "holz")) return p + "wood";
    else return null;
  };

  const renderFrameMaterial = () => {
    const frameNameText: string = ["frameMaterial", "frame"]
      .map((d) => getFromBikeDetails(d))
      .join(" ")
      .toLowerCase();
    const material = resolveMaterialFrom(frameNameText);
    if (!material) return null;
    return basic("FrameMaterial", {
      ...defaultParams,
      material: t(material),
    });
  };

  const renderForkMaterial = () => {
    const frameNameText: string = ["forkMaterial", "fork"]
      .map((d) => getFromBikeDetails(d))
      .join(" ")
      .toLowerCase();
    const material = resolveMaterialFrom(frameNameText);
    if (!material) return null;
    return basic("ForkMaterial", {
      ...defaultParams,
      material: t(material),
    });
  };

  const extractSuspensionLengthMillisFromText = (rawText) => {
    // example: Grade Carbon Fork, Tapered Carbon Steerer, 12x100 Thru Axle, Flat Mount Disc (160mm), Adjustable Flip Chip ==> not extractable
    // example: FOX FLOAT 34 Performance Elite, GRIP2 damper, 15x110mm, 44mm offset, 140mm // should be 140mm
    if (!rawText) return null;
    const suspensionText = rawText
      .toLowerCase()
      .replace(/\d+x\d+\s*mm/gi, "")
      .replace(/\d+mm\s*reifenfreiheit/gi, "")
      .replace(/\d+\s*(mm)?\s*offset/gi, "")
      .replace(/flat\s*mount\s*disc\s*\(\s*\d+\s*c?m?m?\s*\)/gi, "");

    const travelDist = /(\d+)\s*(mm|cm)/g;
    const matches = Array.from(suspensionText.matchAll(travelDist));
    if (!matches || matches.length < 1) return null;

    const m = matches[0];
    const travelDistAmt = parseInt(m[1]);
    const travelDistUnit = m[2];
    const travelMillis = travelDistAmt * (travelDistUnit === "cm" ? 10 : 1);
    if (travelMillis < 10 || travelMillis > 200)
      // anything outside of these bounds is unrealistic
      return null;
    return travelMillis;
  };

  const renderHasForkSuspension = () => {
    if (displayedCategory.includes("Rennrad")) return null;
    const forkName = getFromBikeDetails("fork");
    if (!forkName) return null;
    const suspensionLengthMM = resolveFirst(
      [
        () => getFromBikeDetails("suspensionTravelFront"), // example value: "63 mm"
        () => extractSuspensionLengthMillisFromText(getFromBikeDetails("fork")),
      ] // example value: "rock shox judy tk r 100 mm"
    );
    const faqKey = "ForkSuspension";
    if (!!suspensionLengthMM)
      return basic(faqKey, {
        ...defaultParams,
        forkName: forkName,
        suspensionLength: addSuffixIfMissing("" + suspensionLengthMM, "mm"),
      });
    if (forkName.toLowerCase().includes("starrgabel"))
      return (
        <QuestionAndAnswer
          key={faqKey}
          question={t(question(faqKey), defaultParams)}
          answer={t(answer(faqKey + "_no"), defaultParams)}
        />
      );
    return null;
  };

  const renderHasBackSuspension = () => {
    const suspensionLengthMM = resolveFirst([
      () => getFromBikeDetails("suspensionTravelRear"),
      () => extractSuspensionLengthMillisFromText(getFromBikeDetails("frame")),
    ]);
    if (!suspensionLengthMM) return null;
    const faqKey = "Suspension";
    if (!!suspensionLengthMM)
      return basic(faqKey, {
        ...defaultParams,
        suspensionLength: addSuffixIfMissing("" + suspensionLengthMM, "mm"),
      });
  };

  const containsNumber = (txt) => {
    if (!txt) return false;
    if (!/\d/.test(txt))
      // workaround - some weight values are simply set to "ca." or "--"
      return false;
    return true;
  };

  const renderBikeWeight = () => {
    const weightDescription = getFromBikeDetails("weight");
    if (!containsNumber(weightDescription)) return null;
    // TODO: https://thecycleverse.com/de/road/trek-domane-sl-7-etap-matte-charcoal-black: "56 - 8,90 kg"
    return basic("BikeWeight", {
      ...defaultParams,
      weight: addSuffixIfMissing(weightDescription.trim(), "kg"),
    });
  };

  const renderSystemWeight = () => {
    const weightDescription: string = getFromBikeDetails("maxWeight");
    if (!containsNumber(weightDescription))
      return (
        <QuestionAndAnswer
          key={"SystemWeight"}
          question={t(question("SystemWeight"), defaultParams)}
          answer={t(answer("SystemWeight_none"), defaultParams)}
        />
      );
    return basic("SystemWeight", {
      ...defaultParams,
      maxWeight: addSuffixIfMissing(weightDescription, " kg"),
    });
  };

  const renderSeatPosition = () => {
    const angle = getFromBikeDetails("seatTubeAngle");
    if (!angle) return null;
    const answerKeyPrefix = answer("SeatPosition");
    const normalizedAngle = angle > 1000 ? angle / 100 : angle > 100 ? angle / 10 : angle; // sometimes the angle is given at a 10 or 100x multiple (e.g. 7500 instead of 75). TODO: should really be fixed in the scraper
    const capped = Math.max(70, Math.min(77, normalizedAngle)).toFixed(0);
    const suffix = "_angle" + capped;
    const params = {
      ...defaultParams,
      seatPosition: t(answerKeyPrefix + suffix),
    };
    const answerBlock = (
      <>
        <div>{t(answerKeyPrefix, params)}</div>
        <br />
        <span className={classes.proTipp}>{t(answerKeyPrefix + "_proTippHeader", params)} </span>
        {t(answerKeyPrefix + "_proTippBody", params)}
      </>
    );
    return (
      <QuestionAndAnswer
        key={"SeatPosition"}
        question={t(question("SeatPosition"), defaultParams)}
        answer={answerBlock}
      />
    );
  };

  const renderReach = () => {
    const reachText = getFromBikeDetails("reachMM");
    if (!reachText) return null;
    const reachCm = (parseFloat(reachText + 30) * 0.1).toFixed(0);
    return basic("Reach", {
      ...defaultParams,
      reach: reachCm + "cm",
    });
  };

  const getNormalizedGearCount = () => {
    let gearCount = getFromBikeDetails("gearCount");
    if (!gearCount) return null;
    if (typeof gearCount === "string") gearCount = parseInt(gearCount);
    if (!gearCount) return null;
    return gearCount;
  };

  const gearCountText: string = ["shiftType", "shifters", "gearShift", "engineKind", "crankset"]
    .map((detail) => getFromBikeDetails(detail))
    .join(" ")
    .toLowerCase();
  const resolveGearCountNxM = () => {
    const xByYSpeed = /(\d+)\s*[x]\s*(\d+)[\s-]*(speed|gänge|gang)/g;
    const matches = Array.from(gearCountText.matchAll(xByYSpeed));
    if (!matches || matches.length < 1) return null;
    else {
      const m = matches[0];
      const gearCount = parseInt(m[1]) * parseInt(m[2]);
      return gearCount;
    }
  };
  const resolveGearCountSingle = () => {
    const nGears = /(\d+)[\s-]*(gang|gänge)/g;
    const matches = Array.from(gearCountText.matchAll(nGears));
    if (!matches || matches.length < 1) return null;
    else {
      const m = matches[0];
      const gearCount = parseInt(m[1]);
      return gearCount;
    }
  };

  const resolveGearKind = () => {
    const doesMatch = (regex) => {
      const matches = Array.from(gearCountText.matchAll(regex));
      return !!matches && matches.length >= 1;
    };
    if (doesMatch(/\b(kette|chain)/g)) return "faqAGearCount_chain";
    if (doesMatch(/\b(nabenschaltung|nabe|narbe)/g)) return "faqAGearCount_hub";
    if (doesMatch(/\b(riemen|belt)/g)) return "faqAGearCount_belt";
    if (doesMatch(/\b(combination|kombinations)/g)) return "faqAGearCount_combination";
    if (doesMatch(/\b(tretlager|bottom|pinion)/g)) return "faqAGearCount_bottomBracket";
    if (doesMatch(/\b(elektrisch|electric)/g)) return "faqAGearCount_electric";
    return "faqAGearCount_chain"; // dangerous default?
  };

  const renderGearCount = () => {
    const gearCount = resolveFirst([
      () => getNormalizedGearCount(),
      () => resolveGearCountNxM(),
      () => resolveGearCountSingle(),
    ]);
    if (!gearCount) return null;
    const faqKey = "GearCount";
    const answerKey = gearCount == 1 ? faqKey + "_single" : faqKey;
    const params = {
      ...defaultParams,
      gearCount: gearCount,
      gearKind: t(resolveGearKind()),
    };
    const answerText = t(answer(answerKey), params);
    return <QuestionAndAnswer key={faqKey} question={t(question(faqKey), params)} answer={answerText} />;
  };

  const renderBrakeKind = () => {
    const brakeSystem = getFromBikeDetails("brakeSystem");
    const brakesFront = getFromBikeDetails("brakesFront");
    const brakesRear = getFromBikeDetails("brakesRear");
    const desc = [brakeSystem, brakesFront, brakesRear].join(" ").toLocaleLowerCase();
    let brakeKind = null;

    const descContainsAny = (...words: string[]): boolean => !!words.find((word) => desc.includes(word));
    const isDisc: boolean = descContainsAny("scheibe", "piston") || !!getFromBikeDetails("brakesDiscDiameter");
    const isDrum: boolean = descContainsAny("trommelbremse", "drumbrake");
    const isRim: boolean = descContainsAny(
      "felgenbremse",
      "rim",
      "v-bremse",
      "v-brake",
      "cantilever",
      "u-bremse",
      "u-brake",
      "seitenzug"
    );
    const isHydraulic: boolean = descContainsAny("hydraulisch", "hydraulic");
    const isMechanical: boolean = descContainsAny("mechanische", "mechanic");
    const isBackPedal: boolean = descContainsAny("rücktritt", "backpedal") || !!getFromBikeDetails("brakeBackPedal");

    if (desc.includes("magura")) brakeKind = "_magura";
    else if (isMechanical && isDisc) brakeKind = "_mechanicalDisc";
    else if (isHydraulic && isDisc) brakeKind = "_hydraulicDisc";
    else if (isDrum && isBackPedal) brakeKind = "_drumWithBackPedal";
    else if (isBackPedal) brakeKind = "_backPedal";
    else if (descContainsAny("rollenbremse")) brakeKind = "_roll";
    else if (isDisc) brakeKind = "_disc";
    else if (isDrum) brakeKind = "_drum";
    else if (isRim) brakeKind = "_rim";

    if (!brakeKind) return null;

    return basic("BrakeKind", {
      ...defaultParams,
      brakeKind: t(answer("BrakeKind" + brakeKind)),
    });
  };

  const renderEbikeDisplay = () => {
    if (isBioBike) return null;
    const display = getFromBikeDetails("displayType");
    if (!!display)
      return basic("EbikeDisplay", {
        ...defaultParams,
        ebikeDisplay: display,
      });
  };

  const renderEbikeMotor = () => {
    if (isBioBike) return null;
    const motor = getFromBikeDetails("engine");
    if (!!motor)
      return basic("EbikeMotor", {
        ...defaultParams,
        motor: motor,
      });
  };

  const renderEbikeTorque = () => {
    if (isBioBike) return null;
    const motorTorque = getFromBikeDetails("motorTorque");
    if (!!motorTorque)
      return basic("EbikeTorque", {
        ...defaultParams,
        torque: motorTorque,
      });
  };

  const renderEbikeBattery = () => {
    if (isBioBike) return null;
    const batteryPack = getFromBikeDetails("batteryPack");
    // Potential values for batteryPack
    // - 13.4Ah
    // - Bosch PowerTube Li-Ion 36 V
    // the latter is the only format which is to be shown
    if (!!batteryPack && batteryPack.length > 8)
      return basic("EbikeBattery", {
        ...defaultParams,
        battery: batteryPack,
      });
  };

  const faq = [
    renderRecommendedRetailPrice(),
    renderCheaperOffers(),
    renderDeliveryCost(),
    renderUseCase(),
    renderEbikeDisplay(),
    renderEbikeMotor(),
    renderEbikeTorque(),
    renderEbikeBattery(),
    renderFrameSizes(),
    renderRightsize(),
    renderFrameMaterial(),
    renderForkMaterial(),
    renderHasForkSuspension(),
    renderHasBackSuspension(),
    renderBikeWeight(),
    renderSystemWeight(),
    renderSeatPosition(),
    renderReach(),
    // TODO: basic("Warranty"), // currently no warranty data available to display
    renderGearCount(),
    renderBrakeKind(),
  ].filter((x) => x != null);
  return (
    <>
      <h2 className={classes.subHeadline}>{t("faqHeadline", defaultParams)}</h2>
      <div>{faq}</div>
    </>
  );
};

export default FrequentlyAskedQuestionsSection;
