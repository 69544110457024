import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TheCycleverseTheme } from "src/theme";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
	chartSvgContainer: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: '0px',
		top: '0px',
	},
	ratingIndicatorText: {
		stroke: theme.bikeQualityChartRatingIndicatorFontColor,
		fontSize: "x-small",
		fontStyle: "italic"
	},
	chartLabel: {
		fontFamily: theme.chartFontFamily,
		fontWeight: "lighter",
		fontSize: "x-small",
		color: theme.bikeQualityChartFontColor,
	}
}));

export type ComponentRating = {
	gearshift: number,
	undercarriage: number,
	frame: number,
	tires: number,
	brakes: number,
	ebike: number,
}

export type BikeComponentQualityRadarChartProps = {
	componentRating: ComponentRating
};

const scaleUpBikeComponentRating = (rating) => Math.round(rating * 10.0);

const BikeComponentQualityRadarChart: FC<BikeComponentQualityRadarChartProps> = (props) => {
	const classes = useStyles();
	const { t } = useTranslation("bikeDetails");

	const ratingData = props.componentRating;

	let ratings = [];

	if (!!ratingData.ebike) {
		ratings = [
			{ label: t('bikeDetails:bikeComponentQuality-gearshift'), rating: scaleUpBikeComponentRating(ratingData.gearshift) },
			{ label: t('bikeDetails:bikeComponentQuality-ebikecomponents'), rating: scaleUpBikeComponentRating(ratingData.ebike) },
			{ label: t('bikeDetails:bikeComponentQuality-frame'), rating: scaleUpBikeComponentRating(ratingData.frame) },
			{ label: t('bikeDetails:bikeComponentQuality-tires'), rating: scaleUpBikeComponentRating(ratingData.tires) },
			{ label: t('bikeDetails:bikeComponentQuality-brakes'), rating: scaleUpBikeComponentRating(ratingData.brakes) }
		];
	}
	else {
		ratings = [
			{ label: t('bikeDetails:bikeComponentQuality-gearshift'), rating: scaleUpBikeComponentRating(ratingData.gearshift) },
			{ label: t('bikeDetails:bikeComponentQuality-undercarriage'), rating: scaleUpBikeComponentRating(ratingData.undercarriage) },
			{ label: t('bikeDetails:bikeComponentQuality-frame'), rating: scaleUpBikeComponentRating(ratingData.frame) },
			{ label: t('bikeDetails:bikeComponentQuality-tires'), rating: scaleUpBikeComponentRating(ratingData.tires) },
			{ label: t('bikeDetails:bikeComponentQuality-brakes'), rating: scaleUpBikeComponentRating(ratingData.brakes) }
		];
	}

	// outer circle: 246.526,22.142 46.816,174.711 123.099,421.578 369.953,421.578 446.236,174.711 
	// center x = 46.816+(446.236-46.816)/2 = 246.526
	// center y = 22.142+(421.578-22.142)/2 = 221.68
	// radius = 221.68 - 22.142 = 199.538
	const center = [246.526, 226.68];
	const radius = 199.538;
	const maxRating = 10.0;
	const ratingRange = Array.from(Array(10).keys());
	const sectionAngle = Math.PI * 2 / ratings.length;
	const angleCoords = Array.from(Array(ratings.length).keys()).map(index => {
		const angle = Math.PI * 1.5 - (index * sectionAngle);
		return {
			angle: angle,
			x: Math.cos(angle),
			y: Math.sin(angle)
		}
	})

	const calculateCoordinatesFor = (rating, index) => {
		const length = radius * rating / maxRating
		const x = angleCoords[index].x * length;
		const y = angleCoords[index].y * length;
		return [center[0] + x, center[1] + y];
	};

	const data = ratings.map((r, ix) => {
		return {
			label: r.label,
			coords: calculateCoordinatesFor(r.rating, ix)
		}
	})

	const chartBgCoords = ratingRange.reverse().map(r => {
		return data.map((d, ix) => calculateCoordinatesFor(r + 1, ix).join(",")).join(" ")
	})
	const chartRatingIndicatorCoords = ratingRange.map((r) => calculateCoordinatesFor(10 - r, 0))
	const chartLabels = ratings.map(r => r.label).map((label, ix) => {
		return {
			label: label,
			coords: calculateCoordinatesFor(11, ix)
		}
	})

	const radarChartCoords = data.map(x => x.coords).map(c => c.join(",")).join(" ");

	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			width="492.591px" height="451.824px" viewBox="0 0 492.591 451.824" enableBackground="new 0 0 492.591 451.824"
			xmlSpace="preserve" className={classes.chartSvgContainer}>
			<g>
				{chartBgCoords.map((coords, ix) => <polygon key={"cbg-" + ix} stroke={ix == 0 ? "none" : "lightGray"} fill="white" points={coords} />)}
				{chartRatingIndicatorCoords.map((coords, ix) => <g key={"cric-" + ix}>
					<circle cx={coords[0]} cy={coords[1]} r="9" fill="#9C00A2" />
					<text textAnchor="middle" dominantBaseline="middle" transform='translate(-1 0)' x={coords[0]} y={coords[1]} className={classes.ratingIndicatorText}>{1 + ix}</text>
				</g>)}
				{chartLabels.map(cl =>
					<text key={"cl-" + cl.label} textAnchor="middle" dominantBaseline="middle" x={cl.coords[0]} y={cl.coords[1]} className={classes.chartLabel}>{cl.label}</text>
				)}
				<g opacity="0.5" clipPath="url(#BCQRID_6_)">
					<g>
						<defs>
							<rect id="BCQRID_7_" x="78.526" y="33.109" width="376.855" height="357.718" />
						</defs>
						<polygon fill="#00A4EB" points={radarChartCoords} />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default BikeComponentQualityRadarChart;
