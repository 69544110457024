import PriceAlertConfirmationPopup, { OpenState } from "./PriceAlertConfirmationPopup";
import React, { FC } from "react";

import { CallMissedSharp } from "@material-ui/icons";
import PriceAlertButton from "./PriceAlertButton";
import PriceAlertPopupModal from "./PriceAlertPopupModal";
import { TheCycleverseTheme } from "src/theme";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  inlineTrigger: {
    display: "inline-block",
  },
}));

type Price = {
  amount: number;
  currency: string;
  amountWithoutDiscount: number;
  decimalOffset: number;
};

export type Product = {
  id: string;
  brand: string;
  title: string;
  price: Price;
  category: string;
};

export type PriceAlertPopupProps = {
  page: string;
  imageUrl: string;
  product: Product;
  toggleButtonClass: string;
  inline?: boolean;
};

const PriceAlertPopup: FC<PriceAlertPopupProps> = (props) => {
  const { page, imageUrl, product, toggleButtonClass, inline } = props;
  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState<OpenState>("closed");
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={clsx(inline && classes.inlineTrigger)}>
      <div onClick={handleOpen} className={`${inline ? classes.inlineTrigger : null}`}>
        <PriceAlertButton toggleButtonClass={toggleButtonClass} />
      </div>
      <PriceAlertPopupModal
        page={page}
        open={open}
        handleClose={handleClose}
        afterSubscribe={() => {}}
        imageUrl={imageUrl}
        product={product}
        openConfirmationModal={(emailConfirmed) =>
          setOpenConfirmation(emailConfirmed ? "openConfirmedEmail" : "openUnconfirmedEmail")
        }
        closeConfrimationModal={() => setOpenConfirmation("closed")}
      />
      <PriceAlertConfirmationPopup
        imageUrl={imageUrl}
        product={product}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation("closed")}
      />
    </div>
  );
};

export default PriceAlertPopup;
