import React, { FC } from "react";

import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import LocaleLink from "src/components/Navigation/LocaleLink";
import Image from "next/image";
import { AspectRatio } from "@material-ui/icons";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  bikePreviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: "1%",
    height: "100%"
  },
  verticalBikePreviewContainer: {
    flexDirection: 'column',
    gap: "0%",
  },
  bikePreviewImageContainer: {
    cursor: "pointer",
    flexBasis: "30%",
    alignItems: "stretch",
    backgroundColor: theme.bikePreviewDetailBackgroundColor,
    padding: "5px"
  },
  verticalBikePreviewImageContainer: {
    flexBasis: "70%",
    minHeight: "100px"
  },
  bikePreviewImage: {
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  },
  bikePreviewDetailsContainer: {
    flexBasis: "70%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: "stretch",
    backgroundColor: theme.bikePreviewDetailBackgroundColor,
    padding: '1em'
  },
  verticalBikePreviewDetailsContainer: {
    flexBasis: "30%",
  },
  bikePreviewBrandContainer: {
    flexBasis: "50%",
    paddingLeft: "0.5rem",
    display: "flex",
    alignItems: "flex-end",
    alignContent: "flex-end",
    verticalAlign: "bottom",
    color: theme.bikePreviewBrandNameFontColor
  },
  bikePreviewTitle: {
    flexBasis: "50%",
    paddingLeft: "0.5rem"
  }
}));

const getMediumImageUri = (bike) => {
  if (bike.recordType === 'bike') {
    
    const renditionMedium = bike.renditionMedium;
    if (!!renditionMedium)
      return renditionMedium;

    const renditionSmall = bike.renditionSmall;
    if (!!renditionSmall)
      return renditionSmall[0];

    if (!!bike.imagesSmall) {
      const smallImage = bike.imagesSmall.flatMap(img => img.uri);
      if (smallImage.length > 0)
        return smallImage[0];
    }

    if (!!bike.images) {
      const normalImage = bike.images.flatMap(img => img.uri);
      if (normalImage.length > 0)
        return normalImage[0];
    }
    console.log('failed to find image for', bike);
    return null;
  }
  else if (bike.recordType === 'bikepointer') {
    return bike.renditionMedium;
  } else if (bike.img) {
    return bike.img
  }
};

interface BikePreviewProps {
  bike: any,
  link?: string,
  vertical?: boolean,
  onClick?: () => any
}

const BikePreview: FC<BikePreviewProps> = (props) => {
  const classes = useStyles();
  const { bike, link, onClick } = props;

  const brandName = !!(bike.brandName) ? bike.brandName : bike.brand;
  const modelName = !!(bike.modelName) ? bike.modelName + (bike.year?" "+bike.year:"") : bike.title;

  const renderImage = () => <div className={classes.bikePreviewImage} style={{ backgroundImage: "url(" + getMediumImageUri(bike) + ")" }} />
  const renderImageWithLink = () => <LocaleLink href={link} as={link} target="new">{renderImage()}</LocaleLink>
  return (
    <div className={`${classes.bikePreviewContainer} ${props.vertical ? classes.verticalBikePreviewContainer : ''}`}>
      <div className={`${classes.bikePreviewImageContainer}  ${props.vertical ? classes.verticalBikePreviewImageContainer : ''}`}>
        {link ? renderImageWithLink() : renderImage()}
      </div>
      <div className={`${classes.bikePreviewDetailsContainer} ${props.vertical ? classes.verticalBikePreviewDetailsContainer : ''}`}>
        <div className={classes.bikePreviewBrandContainer}>
          {brandName}
        </div>
        <div className={classes.bikePreviewTitle}>{modelName}</div>
      </div>
    </div>
  );
};

export default BikePreview;
