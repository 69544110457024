import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TheCycleverseTheme } from "src/theme";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
	chartSvgContainer: {
		backgroundColor: "transparent",
		maxWidth: "100vw"
	},
	xAxisLabel: {
		fontFamily: theme.chartFontFamily,
		fontWeight: "lighter",
		fontSize: "large",
		fill: theme.bikeValueForMoneyXAxisLabelFontColor,
	},
	gridLinesVertical: {
		stroke: theme.bikeValueForMoneyGridLineColor,
	},
	sizeUpOnHover: {
		transform: "scale(0.4)",
		transition: "0.1s ease",
		"& :hover": {
			transform: "scale(1.3)",
			transition: "0.1s ease"
		},
	},
	sizeUpHighlightedOnHover: {
		transform: "scale(0.8)",
		transition: "0.1s ease",
		"& :hover": {
			transform: "scale(1.2)",
			transition: "0.1s ease"
		}
	},
	defaultDataPoint: {
		fill: theme.bikeValueForMoneyDataPt,
		opacity: 0.5
	},
	red: {
		fill: "red"
	}, green: {
		fill: "green"
	},
}));


export enum HighlightMode {
	Default = 0,
	SameCat = 1,
	Current = 2,
};
export type ValueForMoneyDatapoint = {
	id: string,
	quality: number,
	price: number,
	highlight: HighlightMode
	payload: any
}

export type DatapointSelectionEvent = {
	x: number,
	y: number,
	dataPoint: ValueForMoneyDatapoint
}

export type CanvasClickEvent = {
	x: number,
	y: number
}

export type BikeValueForMoneyChartProps = {
	aspectRatio: number,
	dataPoints: Array<ValueForMoneyDatapoint>,
	formatPrice: (number) => string,
	onHover: (DatapointSelectionEvent) => any,
	onClick: (DatapointSelectionEvent) => any,
	onClickCanvas: (CanvasClickEvent) => any
};

const BikeValueForMoneyChart: FC<BikeValueForMoneyChartProps> = (props) => {
	const classes = useStyles();
	const { t } = useTranslation("bikeDetails");

	const priceStepping = 1000; // euros
	const priceBuffer = 300; // euros

	const rawEntries = props.dataPoints;

	const minQuality = Math.min.apply(Math, rawEntries.map(x => x.quality));
	const maxQuality = Math.max.apply(Math, rawEntries.map(x => x.quality));
	const qualityDelta = maxQuality - minQuality;

	const minPrice = Math.min.apply(Math, rawEntries.map(x => x.price));
	const maxPrice = Math.max.apply(Math, rawEntries.map(x => x.price));
	const minPriceStepped = minPrice - (minPrice % priceStepping) - priceBuffer;
	const maxPriceStepped = maxPrice - (maxPrice % priceStepping) + priceStepping + priceBuffer;
	const priceDelta = maxPriceStepped - minPriceStepped;

	const entries = rawEntries.map(e => {
		const relativeQuality = (e.quality - minQuality) / qualityDelta;
		const relativePrice = (e.price - minPriceStepped) / priceDelta
		return {
			relativeQuality: relativeQuality,
			relativePrice: relativePrice,
			relativeSize: 0.4,
			src: e
		}
	}).sort((a, b) => a.src.highlight - b.src.highlight)

	const verticalLines = [];
	const firstVerticalLine = minPrice - minPrice % priceStepping;
	const lastVerticalLine = maxPrice - maxPrice % priceStepping + priceStepping;

	for (let price = firstVerticalLine; price <= lastVerticalLine; price += priceStepping) {
		verticalLines.push({
			price: price,
			relativePrice: (price - minPriceStepped) / priceDelta,
		});
	}

	const totalWidth = 1000;
	const totalHeight = totalWidth / props.aspectRatio;
	const padding = totalWidth * 0.0025;

	const paddedTotalHeight = (totalHeight - 2 * padding);
	const graphHeight = paddedTotalHeight * 0.8;
	const legendHeight = paddedTotalHeight * 0.2;

	const graphWidth = totalWidth - 2 * padding;
	const legendWidth = graphWidth;

	const dataPointSize = 50;
	const dataPointsHalfSize = dataPointSize / 2;

	return <svg version="1.1" id="bikeValueForMoneyChart" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox={"0 0 " + totalWidth + " " + totalHeight}
			xmlSpace="preserve" className={classes.chartSvgContainer}
			>
				<rect x={0} y={0} width={totalWidth} height={totalHeight} fill="#fff0" onClick={(evt) => {
								props.onClickCanvas({
									x: evt.pageX,
									y: evt.pageY
								})
							}}
							>
				</rect>
				<svg x={padding} y={padding} viewBox={"0 0 " + graphWidth + " " + graphHeight} overflow="visible">
					{verticalLines.map((vl, ix) => {
						const x = vl.relativePrice * graphWidth;
						const h = graphHeight;
						return <line key={"vl_" + ix} x1={x} x2={x} y1="0" y2={h} className={classes.gridLinesVertical} />
					})}
					{entries.map(e => {
						const cx = e.relativePrice * graphWidth;
						const cy = graphHeight - e.relativeQuality * graphHeight;//canvasHeight - e.quality * canvasHeight;

						const isCurrentSelection = e.src.highlight === HighlightMode.Current
						const isSameCategory = e.src.highlight === HighlightMode.SameCat
						const isHighlighted = isCurrentSelection || isSameCategory;
						return <g key={"dtpt_blob_" + e.src.id} transform={
							"translate(" + cx + " " + cy + ")"
						}
							onMouseEnter={(evt) => {
								props.onHover({
									x: evt.pageX,
									y: evt.pageY,
									dataPoint: e
								})
							}}
							onClick={(evt) => {
								props.onClick({
									x: evt.pageX,
									y: evt.pageY,
									dataPoint: e
								})
							}}
						>
							<g className={`${isHighlighted?classes.sizeUpHighlightedOnHover:classes.sizeUpOnHover}`}>
									{
										!isHighlighted && <circle
											cx={0}
											cy={0}
											r={dataPointsHalfSize}
											className={`${classes.defaultDataPoint}`}
										/>
									}
									{isCurrentSelection && <image stroke="red" x={-dataPointsHalfSize} y={- dataPointsHalfSize} width={dataPointSize} height={dataPointSize} xlinkHref="/images/valueformoney/Check-orange.svg" />}
									{isSameCategory && <image stroke="red" x={- dataPointsHalfSize} y={- dataPointsHalfSize} width={dataPointSize} height={dataPointSize} xlinkHref="/images/valueformoney/Check-lila.svg" />}
							</g>
						</g>
					})}
				</svg>
				<svg x={padding} y={padding + graphHeight} width={legendWidth} height={legendHeight} viewBox={"0 0 " + legendWidth + " " + legendHeight} overflow="visible">
					{verticalLines.map((vl, ix) => {
						const x = vl.relativePrice * legendWidth;
						const h = legendHeight*0.8;
						return <text key={"vlt_" + ix} textAnchor="middle" x={x} y={h} className={classes.xAxisLabel}>{props.formatPrice(vl.price)}</text>
					})}
				</svg>
		</svg>
};

export default BikeValueForMoneyChart;
