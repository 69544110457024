import "chartjs-adapter-date-fns";

import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";
import React from "react";
import { TheCycleverseTheme } from "src/theme";
import { de } from "date-fns/locale";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { queryProducts } from "../../../graphql/queries";
import useSWR from "swr";
import { useTheme } from "@material-ui/styles";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "src/translations/useTranslationExtras";

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Filler, Title, Tooltip, Legend);

const useStyles = makeStyles((theme) => ({}));

export type PriceHistoryEntry = {
  amount: number,
  date: string,
  label: string
}

export type PriceHistoryChartProps = {
  priceHistory: Array<PriceHistoryEntry>;
  priceFormatter?: (number) => string;
};

const subtractMonths = (date, months) => {
  const newDate = new Date(date.getTime());
  newDate.setMonth(date.getMonth() - months);
  return newDate;
};

const PriceHistoryChart = (props: PriceHistoryChartProps) => {
  const classes = useStyles();
  const theme = useTheme<TheCycleverseTheme>();
  const { t, lang } = useTranslation();
  const { priceAsString } = useTranslationExtras(t, lang);
  
  const priceFormatter = props.priceFormatter?props.priceFormatter:(amt)=>{
    return priceAsString({ "currency":"EUR",
    "decimalOffset": 2, amount: amt }, 0);
  };
  const historySortedByDate = props.priceHistory.map(
    priceEntry => {
      return {
        ...priceEntry,
        x: new Date(priceEntry.date.split(" ")[0]),
        y: priceEntry.amount,
        timeMillis: new Date(priceEntry.date).getTime()
      };
    }
  ).sort((a, b) => {
    return a.timeMillis - b.timeMillis;
  });

  const priceHistory = historySortedByDate;

  const firstDataPoint = priceHistory[0];
  const lastDataPoint = priceHistory[priceHistory.length - 1];
  const dataPoints = [
    {
      x: subtractMonths(firstDataPoint.x, 1),
      y: firstDataPoint.y,
      label: firstDataPoint.label,
    },
    ...priceHistory,
    {
      x: new Date(),
      y: lastDataPoint.y,
      label: lastDataPoint.label,
    },
  ];

  const options = {
    responsive: true,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = context[0].raw.x.toLocaleDateString(lang === "de" ? "de-DE" : "en-US");
            const label = context[0].raw.label;
            return `${date} - ${label}`;
          },
          label: function(context) {
            return priceFormatter(context.raw.y);
          },
        },
      },
    },
    scales: {
      x: {
        type: "time" as const,
        time: {
          unit: "month" as const,
        },
        adapters: {
          date: {
            locale: de,
          },
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 7,
          //   autoSkipPadding: 50,
          callback: function(label, index, labels) {
            return priceFormatter(label);
          },
        },
      },
    },
  };

  const graphData = {
    datasets: [
      {
        fill: true,
        data: dataPoints,
        borderColor: theme.chartColorFG,
        backgroundColor: theme.chartColorBG,
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Line options={options} data={graphData} />
    </>
  );
};

export default PriceHistoryChart;
