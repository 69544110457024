import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles(() => ({
  triggerNotificationContainer: {
    fontSize: "20px",
    fontWeight: 300,
    marginTop: "40px",
    lineHeight: "32px",
    fontFamily: "Roboto",
  },
  notificationDescription: {
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px",
    fontFamily: "Roboto",
  },
}));

const PriceAlertPopupTrigger = () => {
  const { t } = useTranslation("priceAlert");
  const classes = useStyles();
  return (
    <>
      <div className={classes.triggerNotificationContainer}>{t("notifyMe")}</div>
      <div className={classes.notificationDescription}>{t("notifymeDescription")}</div>
    </>
  );
};

export default PriceAlertPopupTrigger;
