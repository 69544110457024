import React, { useContext } from "react";

import AuthContext from "../../Auth/AuthContext";
import { createTextByIdBatchQuery } from "../../../graphql/queries";
import useSWR from "swr";

const isNotNullNorUndefined = function(o) {
  return typeof o !== "undefined" && o !== null;
};

const Text = (props) => {
  const { forceShow, ids } = props;
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);

  const textIds = ids || [];
  const { data } = useSWR([createTextByIdBatchQuery(textIds), JSON.stringify({})]);
  const hasContent =
    data &&
    Object.keys(data)
      .map((k) => data[k])
      .find(isNotNullNorUndefined);

  const showContent = isAdmin || forceShow || hasContent;

  return showContent ? <>{props.children}</> : null;
};

export default Text;
