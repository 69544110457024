import {
  AdSlotProductHead,
  AdSlotProductInline2,
  AdSlotProductInline3,
  AdSlotProductSkyscraper1,
  AdSlotProductSkyscraper2,
} from "src/components/Ads/AdSlots";
import Description, { DescriptionParagraph, DescriptionSection } from "../Description";

import AdBanner from "src/components/Ads/AdBanner";
import Analytics from "src/lib/analytics/analytics";
import ButtonLink from "../../Navigation/ButtonLink";
import Error from "../../../../pages/_error";
import ExternalLinkIcon from "src/components/Navigation/Icons/ExternalLinkIcon";
import FrequentlyAskedQuestionsSection from "./FrequentlyAskedQuestionsSection";
import Grid from "@material-ui/core/Grid";
import Head from "next/head";
import LazyLoad from "src/lib/LazyLoad";
import LocaleLink from "../../Navigation/LocaleLink";
import MainLayout from "../../Layout/MainLayout";
import PriceAlert from "./PriceAlert";
import PriceAlertPopup from "./PriceAlertPopup";
import PriceAlertPopupTrigger from "./PriceAlertPopupTrigger";
import PriceComparisonSection from "./PriceComparisonSection";
import PriceDiscountTag from "./PriceDiscountTag";
import PriceTag from "./PriceTag";
import ProductCategoryBreadcrumb from "./ProductCategoryBreadcrumb";
import ProductHightlights from "./ProductHightlights";
import ProductLike from "./ProductLike";
import ProductLink from "./ProductLink";
import React from "react";
import SeoHead from "../../Seo/SeoHead";
import SocialShareButtons from "../../Social/SocialShareButtons";
import SpecificationTable from "src/components/Content/Specification/SpecificationTable";
import Text from "../Text/Text";
import TextConditional from "../Text/TextConditional";
import { TheCycleverseTheme } from "src/theme";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import environment from "../../../environment";
import { getBestBikePath } from "../../../lib/bestProductsUtils";
import { getCategoryByName } from "./useCategory";
import { makeStyles } from "@material-ui/core/styles";
import { queryProducts } from "../../../graphql/queries";
import { useMount } from "react-use";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "../../../translations/useTranslationExtras";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  image: {
    width: "100%",
    height: "100%",
    paddingTop: "75%",
    position: "relative",
    textAlign: "center",
    "& img": {
      maxHeight: "100%",
      maxWidth: "100%",
      position: "absolute",
      margin: "auto",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  imageContainer: {
    padding: theme.cardSpacing,
    backgroundColor: theme.product.imageBackground,
  },
  gutters: {
    paddingLeft: theme.globalMarginDesktop,
    paddingRight: theme.globalMarginDesktop,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.globalMarginMobile,
      paddingRight: theme.globalMarginMobile,
    },
  },
  guttersHorizontalScroll: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.globalMarginMobile,
      marginRight: -theme.globalMarginMobile,
      "& .MuiImageListItem-root:first-child": {
        marginLeft: theme.globalMarginMobile - theme.cardSpacing,
      },
    },
  },
  gutterBottomSpacing: {
    marginBottom: theme.spacing(4),
  },
  gutterBottomSpacingLarge: {
    marginBottom: theme.spacing(12),
  },
  info: {
    paddingTop: theme.globalMarginDesktop,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.globalMarginMobile,
    },
  },
  overview: {
    padding: theme.spacing(1),
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      paddingLeft: theme.spacing(4),
    },
  },
  overviewContainer: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.up("sm")]: {
      display: "inline-block",
    },
  },
  brand: {
    display: "block",
    color: theme.hightlightColor,
    lineHeight: "1.3",
  },
  title: {
    lineHeight: "1.3",
    fontSize: 21,
  },
  price: {
    textAlign: "right",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    "& .FX-PriceTag-price": {
      fontSize: "x-large",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  deliveryCosts: {
    color: theme.unflashyColor,
  },
  deliveryCostsTax: {
    color: theme.unflashyColor,
    fontSize: "small",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  openButtonContainer: {
    textAlign: "right",
  },
  description: {
    marginTop: theme.spacing(6),
    "& .FX-DescriptionParagraph": {
      maxWidth: theme.paragraphMaxWidth,
    },
  },
  shareButtons: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: 0,
    },
  },
  bikeSizeSection: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  bikeSize: {
    backgroundColor: theme.palette.primary.main,
    color: theme.openButtonColor,
  },
  likes: {
    position: "absolute",
    bottom: theme.cardSpacing,
    right: theme.cardSpacing,
    zIndex: 1,
    margin: theme.cardButtonSpacing,
  },
  salePercentage4: {
    zIndex: 1,
    position: "absolute",
    top: theme.cardSpacing,
    left: theme.cardSpacing,
    margin: theme.cardButtonSpacing,
  },
  breadcrumb: {
    marginTop: theme.spacing(1),
  },
  priceComparison: {
    paddingTop: theme.spacing(8),
  },
  bikeDetailTable: {
    "& tbody tr td:first-child": {
      paddingRight: theme.spacing(2),
      fontWeight: 500,
    },
    "& tbody tr td": {
      paddingBottom: theme.spacing(1),
    },
  },
  specifications: {
    textAlign: "center",
    "& h2": {
      fontSize: "x-large",
      marginTop: 8,
      fontWeight: 300,
      marginBottom: 24,
    },
  },
  bikeTable: {
    maxWidth: 600,
    margin: "auto",
    paddingBottom: theme.spacing(8),
  },
  text: {
    "& .FX-Text-content": {
      ...theme.formText,
    },
    overflowWrap: "anywhere",
  },
  subHeadline: {
    fontSize: 20,
    fontFamily: "inherit",
    fontWeight: 900,
    marginBottom: 8,
  },
  adviceTextContainer: {
    "& .FX-HeadlineShortLink-root": {
      display: "inline-block",
      maxWidth: 400,
      padding: 12,
    },
  },
  editorFormatting: {
    ...theme.formText,
  },
  openButton: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.lightOrangeColor}`,
    color: theme.darkOrangeColor,
    textAlign: "center",
    borderRadius: "4px",
    fontWeight: 600,
    cursor: "pointer",
    marginTop: "10px",
  },

  priceAlertOnProductMiddle: {
    textAlign: "center",
    marginTop: "-50px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },

  topPriceAlertTriggerButton: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "right",
  },

  topPriceAlertTriggerButtonHeight: {
    padding: "11px 0",
    width: "180px",
    fontSize: "large",
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  adHead: {
    margin: "auto",
    marginBottom: theme.spacing(4),
    marginTop: 32,
  },
  containerBox: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(4),
    },
  },
  adSkyscraperLane: {
    [theme.breakpoints.up("md")]: {
      flex: "0 0 300px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  adSkyscraper1: {
    display: "inline-block",
    top: 100,
    position: "sticky",
    marginTop: 48,
  },
  adInline: {
    margin: "auto",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  affiliateLinkNotice: {
    maxWidth: 450,
    margin: "auto",
    marginBottom: theme.spacing(6),
  },
  openIcon: {
    filter: "invert(1)",
    fontSize: 12,
    display: "inline-block",
  },
}));

const createHeadlineShortLink = (headline, short, linkHref, linkText) => {
  return (
    <div className="FX-HeadlineShortLink-root">
      <div className="FX-HeadlineShortLink-Headline">{headline}</div>
      <div className="FX-HeadlineShortLink-Short">{short}</div>
      <LocaleLink aClass="FX-HeadlineShortLink-Link" href={linkHref} as={linkHref} target="new">
        {linkText}
      </LocaleLink>
    </div>
  );
};

const getPseudoRandomNumber = (str) => {
  var percent = 100;
  for (var i = 0; i < str.length; i++) {
    percent += str.charCodeAt(i);
  }
  percent = percent % 100;
  return percent / 100.0;
};

const getRatingAverage = (str, min, max) => {
  const random = getPseudoRandomNumber(str);
  return (min + (max - min) * random).toFixed(1);
};

const getRatingCount = (str) => {
  const random = getPseudoRandomNumber(str);
  return 10 + Math.round(90 * random);
};

const dropEmptyFields = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v != "unset"));
};
const maxMergeBikeDetails = (arr) => {
  const bikeDetails = arr.filter((x) => !!x.bikeDetail).map((x) => dropEmptyFields(x.bikeDetail));
  const bikeDetail = bikeDetails.reduce((acc, cur) => {
    return {
      ...cur,
      ...acc,
    };
  }, {});
  return {
    ...arr[0],
    ...{ bikeDetail },
  };
};

const maxMergeSpecs = (products) => {
  const mergedGroups = products[0].specificationGroups || [];
  products.slice(1).forEach((product) => {
    const groupList = product.specificationGroups || [];
    groupList.forEach((group) => {
      const matchingGroup = mergedGroups.find((g) => g.key === group.key);
      if (matchingGroup) {
        group.value.forEach((value) => {
          const matchingValue = matchingGroup.value.find((v) => v.key === value.key);
          if (matchingValue) {
            if (matchingValue.value.indexOf(value.value) < 0) {
              matchingValue.value += `, ${value.value}`;
            }
          } else {
            matchingGroup.value.push(value);
          }
        });
      } else {
        mergedGroups.push(group);
      }
    });
  });
  return {
    ...products[0],
    specificationGroups: mergedGroups,
  };
};

const getProducts = (data) => {
  return data.queryProducts.items.sort((a, b) => (a.visibility == "public" && a.id.localeCompare(b.id) <= 0 ? -1 : 1));
};

const getProduct = (data) => {
  const products = getProducts(data);
  return products[0];
};

const ProductDetails = (props) => {
  const { seoId, prefetchedData, reject, seoCategory } = props;
  const classes = useStyles();
  const { t, lang } = useTranslation("productDetails");
  const locale = lang;
  const { priceAsString, priceAsFloat } = useTranslationExtras(t, lang);

  const { data } = useSWR(
    [
      queryProducts,
      JSON.stringify({
        action: "getBySeoId",
        seoId,
      }),
    ],
    { fallbackData: prefetchedData }
  );

  useMount(() => {
    if (typeof window !== "undefined" && window.location?.pathname) {
      const product = getProduct(data);
      if (product) {
        Analytics.productDetailsImpression(
          product.id,
          product.seoId,
          product.price.currency,
          priceAsFloat(product.price),
          product.title,
          product.brand,
          product.category
        );
      }
    }
  });

  if (!data) {
    return null;
  }

  if (reject || !data?.queryProducts?.items || !data?.queryProducts?.items[0]) {
    return <Error statusCode={404} />;
  }

  const products = getProducts(data);
  const product = getProduct(data);
  const category = getCategoryByName(product.category);
  const categoryParts = product.category.split(" > ");
  const categoryName = categoryParts[categoryParts.length - 1];
  const canonicalUrl = `https://thecycleverse.com/${locale}/${category ? category.id : seoCategory}/${seoId}`;
  const imageUrl = product.renditionLarge ? product.renditionLarge : product.images[0].uri;
  const description = `${product.brand} ${product.title} | ${categoryParts.join(" | ")}`.replace(/'|"/gi, "");
  const keyArticlesProduct = `product-details-articles-${locale}-${seoId}`;
  const keyArticlesCategory = `product-details-articles-${locale}-${product.category.replace(/[^a-zA-Z ]/g, "")}`;
  const isBiobikeCategory = product.category.startsWith("Fahrräder");
  const isEbikeCategory = product.category.startsWith("E-Bikes") && !product.category.startsWith("E-Bikes > Teile");

  return (
    <MainLayout searchBar="productSticky">
      <Head>
        <link rel="preload" href={imageUrl} as="image" />
      </Head>
      <SeoHead
        title={t("productDetailTitle", { brand: product.brand, title: product.title })}
        description={description}
        ogDescription={description}
        ogImage={product.renditionOpenGraph ? product.renditionOpenGraph : imageUrl}
        ogImageWidth={product.renditionOpenGraph ? 1200 : 800}
        ogImageHeight={product.renditionOpenGraph ? 630 : 800}
        canonical={canonicalUrl}
        schema={`{
          "@context":"https://schema.org/",
          "@id":"${canonicalUrl}",
          "@type":"Product",
          "brand": {
            "@type": "Brand",
            "name": "${product.brand}"
          },
          "category":"${product.category}",
          "description":"${description}",
          "image":[
             "${imageUrl}"
           ],
          "logo":"https://images.thecycleverse.com/${product.advertiser
            .toLowerCase()
            .replace(/[^a-z0-9]/gi, "-")}-logo.png",
          "mainEntityOfPage":{
             "@id":"${canonicalUrl}",
             "@type":"WebPage"
          },
          "manufacturer":{
             "@type":"Organization",
             "name":"${product.brand}"
          },
          "name":"${product.title.replace(/'|"/gi, "")}",
          "url":"${canonicalUrl}",
          "sku":"${product.seoId}",
          "gtin8":"${product.ean}",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${getRatingAverage(product.seoId, 4.6, 4.9)}",
            "reviewCount": "${getRatingCount(product.seoId)}"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "${getRatingAverage(product.seoId, 4.6, 4.9)}",
              "bestRating": "5"
            },
            "author": {
              "@type": "Organization",
              "name": "The Cycleverse"
            }
          },
          "offers":{
            "@type":"AggregateOffer",
            "availability":"InStock",
            "highPrice":"${priceAsFloat(product.price)}",
            "itemCondition":"new",
            "lowPrice":"${priceAsFloat(product.price)}",
            "offerCount":1,
            "offers":[
               {
                  "@type":"Offer",
                  "availability":"http://schema.org/InStock",
                  "itemCondition":"new",
                  "price":"${priceAsFloat(product.price)}",
                  "priceCurrency":"EUR",
                  "url":"${canonicalUrl}",
                  "review":[]
               }
            ],
            "priceCurrency":"EUR"
         }
        }`}
        schema2={
          category
            ? `{
          "@context":"https://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [${category.path.map((item, i) => {
            return `{
              "@type": "ListItem", 
              "position": "${i + 1}",
              "name": "${item.name}",
              "item": "https://${environment.host}/${locale}/${item.id}"
            }`;
          })}]
        }`
            : null
        }
      />
      <div className={`${classes.gutters}`}>
        <AdBanner slot={AdSlotProductHead} className={classes.adHead} />

        <ProductCategoryBreadcrumb category={category} className={classes.breadcrumb} />

        <Grid container className={classes.info}>
          <Grid item xs={12} sm={8} className={classes.imageContainer}>
            <div className={classes.image}>
              <img loading="lazy" alt={`${product.brand} ${product.title}`} src={imageUrl} />
              <ProductLike className={classes.likes} productId={product.id} count={product.likes} />
              <PriceDiscountTag className={classes.salePercentage4} price={product.price} />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.overview}>
            <div className={classes.overviewContainer}>
              <Typography className={classes.title} component="h1">
                <span className={classes.brand}>{product.brand}</span>
                {product.title}
              </Typography>

              {product.deliveryCost && (
                <Typography className={classes.deliveryCosts}>
                  {`${t("common:productsDelivery")} ${priceAsString(product.deliveryCost)}`}
                </Typography>
              )}
              {product.deliveryCost && (
                <Typography className={classes.deliveryCostsTax}>{t("common:productsTax")}</Typography>
              )}

              <PriceTag price={product.price} fetchOffersForProduct={product} className={classes.price}></PriceTag>

              <div className={classes.openButtonContainer}>
                <ProductLink seoId={seoId} product={product} offersHref="#links" />
              </div>

              <PriceAlertPopupTrigger />

              <div className={classes.topPriceAlertTriggerButton}>
                <PriceAlertPopup
                  toggleButtonClass={`${classes.openButton} ${classes.topPriceAlertTriggerButtonHeight}`}
                  imageUrl={imageUrl}
                  product={product}
                  page="priceAlert"
                />
              </div>
            </div>

            <SocialShareButtons
              className={classes.shareButtons}
              title={t("productDetailTitle", { brand: product.brand, title: product.title })}
              mediaUrl={imageUrl}
            />
          </Grid>

          <Grid item xs={12} className={classes.containerBox}>
            <div className={classes.adSkyscraperLane}>
              <AdBanner slot={AdSlotProductSkyscraper1} hideOnMobile className={classes.adSkyscraper1} />
            </div>

            <Grid container>
              <Grid item xs={12} className={classes.description}>
                <PriceComparisonSection product={product} />

                <div className={classes.affiliateLinkNotice}>
                  <span>{t("affiliateNote1")}</span>
                  <ExternalLinkIcon className={classes.openIcon} />
                  <span>{t("affiliateNote2")}</span>
                </div>

                <AdBanner slot={AdSlotProductInline2} className={classes.adInline} />
                <PriceAlert product={product} imageUrl={imageUrl} />
              </Grid>

              <Grid item xs={12} className={classes.description}>
                <Description className={classes.gutters} variant="fullwidth">
                  <Text className={classes.text} useCache={true} id={`product-details-${locale}-${seoId}`}></Text>
                </Description>
              </Grid>

              <Grid item xs={12} className={classes.description}>
                <Description className={classes.gutters} variant="fullwidth">
                  <DescriptionSection title={t("productDetailInfosHeadline")}>
                    <DescriptionParagraph>
                      <Text
                        className={classes.text}
                        useCache={true}
                        id={`product-details-features-${locale}-${seoId}`}
                        fallback={
                          environment.features.showDescriptionNormalized && product.descriptionNormalized
                            ? product.descriptionNormalized
                            : product.bikeDetail && product.bikeDetail.detailDescription
                            ? product.bikeDetail.detailDescription
                            : product.description
                        }
                      ></Text>
                    </DescriptionParagraph>
                  </DescriptionSection>
                </Description>
              </Grid>
            </Grid>
            <div className={classes.adSkyscraperLane}></div>
          </Grid>
        </Grid>

        {(isBiobikeCategory || isEbikeCategory) && (
          <div className={classes.bikeSizeSection}>
            <p>{t("productDetailBikeSizeIntro")}</p>
            <ButtonLink
              target="new"
              buttonClassName={classes.bikeSize}
              as="/kaufberatung-fahrradgroesse"
              href="/kaufberatung-fahrradgroesse"
            >
              {t("productDetailBikeSize")}
            </ButtonLink>
          </div>
        )}
      </div>

      <TextConditional ids={[keyArticlesProduct, keyArticlesCategory]} forceShow={isBiobikeCategory || isEbikeCategory}>
        <Grid item xs={12}>
          <Description className={`${classes.gutters} ${classes.gutterBottomSpacingLarge}`} variant="fullwidth">
            <h6 className={classes.subHeadline}>{t("productDetailAdviceArticles")}</h6>
            <div className={classes.adviceTextContainer}>
              <Text className={classes.text} useCache={true} id={keyArticlesProduct} nameSuffix=" products only" />
              <Text className={classes.text} useCache={true} id={keyArticlesCategory} nameSuffix=" category only" />
              {isEbikeCategory && (
                <div className={classes.editorFormatting}>
                  {getBestBikePath(product.category, priceAsFloat(product.price)) &&
                    createHeadlineShortLink(
                      t("ebikeBestHeadline", { category: categoryName }),
                      t("ebikeBestShort"),
                      `/beste/${getBestBikePath(product.category, priceAsFloat(product.price))}`,
                      t("ebikeBestStart")
                    )}
                  {createHeadlineShortLink(
                    t("ebikeFinderHeadline"),
                    t("ebikeFinderShort"),
                    "/welches-ebike-passt-zu-mir",
                    t("ebikeFinderStart")
                  )}
                </div>
              )}
              {isBiobikeCategory && (
                <div className={classes.editorFormatting}>
                  {getBestBikePath(product.category, priceAsFloat(product.price)) &&
                    createHeadlineShortLink(
                      t("biobikeBestHeadline", { category: categoryName }),
                      t("biobikeBestShort"),
                      `/beste/${getBestBikePath(product.category, priceAsFloat(product.price))}`,
                      t("biobikeBestStart")
                    )}
                  {createHeadlineShortLink(
                    t("biobikeFinderHeadline"),
                    t("biobikeFinderShort"),
                    "/welches-fahrrad-passt-zu-mir",
                    t("biobikeFinderStart")
                  )}
                </div>
              )}
            </div>
          </Description>
        </Grid>
      </TextConditional>

      <div className={clsx(classes.containerBox, classes.gutters)}>
        <div className={classes.adSkyscraperLane}>
          {(environment.features.enableBikeCompare && (isBiobikeCategory || isEbikeCategory)) ||
            (environment.features.showSpecificationGroups &&
              product.specificationGroups &&
              !isBiobikeCategory &&
              !isEbikeCategory) ||
            (environment.features.enableProductFAQs && (isBiobikeCategory || isEbikeCategory) && (
              <AdBanner slot={AdSlotProductSkyscraper2} hideOnMobile className={classes.adSkyscraper1} />
            ))}
        </div>
        <div>
          {environment.features.enableBikeCompare && (isBiobikeCategory || isEbikeCategory) && (
            <div className={`${classes.specifications}`}>
              <h2>{t("productDetailDetailsHeadline")}</h2>
              <SpecificationTable
                products={[maxMergeBikeDetails(products)]}
                flavor={isBiobikeCategory ? "biobike" : "ebike"}
                singleProduct
                className={classes.bikeTable}
              />
            </div>
          )}

          {environment.features.showSpecificationGroups &&
            product.specificationGroups &&
            !isBiobikeCategory &&
            !isEbikeCategory && (
              <>
                <SpecificationTable
                  products={[maxMergeSpecs(products)]}
                  flavor="product"
                  singleProduct
                  className={classes.bikeTable}
                />
              </>
            )}

          {environment.features.enableProductFAQs && (isBiobikeCategory || isEbikeCategory) && (
            <Grid container className={classes.justifyCenter}>
              <Grid item xs={12} sm={8} md={8}>
                <FrequentlyAskedQuestionsSection records={products} />
              </Grid>
            </Grid>
          )}
        </div>
        <div className={classes.adSkyscraperLane}></div>
      </div>

      <LazyLoad offset={500} once>
        <ProductHightlights
          title={t("productDetailRecommandationsGeneral")}
          highlightsClass={`${classes.gutters} ${classes.gutterBottomSpacing}`}
          horizontalScrollClassName={classes.guttersHorizontalScroll}
          minimized={true}
          recommendation={{ type: "sameBrand", product }}
        />
        <ProductHightlights
          title={t("productDetailRecommandationsProduct", { categoryName })}
          highlightsClass={`${classes.gutters} ${classes.gutterBottomSpacing}`}
          horizontalScrollClassName={classes.guttersHorizontalScroll}
          minimized={true}
          recommendation={{ type: "sameCategory", product }}
        />
      </LazyLoad>
    </MainLayout>
  );
};

export default ProductDetails;
