
import { groupByFn } from "src/components/common/ArrayFunctions";

export type ProductWithAdvertiser = {
    advertiser: string
}

export const singleAdvertiser = (products: Array<ProductWithAdvertiser>) => {
    const productsByAdvertiser = groupByFn(products, p => p.advertiser)
    const firstProductPerAdvertiser = Object.values(productsByAdvertiser).map(x => x[0])
    return firstProductPerAdvertiser;
};
