import React, { FC } from "react";

import Button from "@material-ui/core/Button";
import CommonModal from "src/components/common/CommonModal";
import PriceAlertPopupFirstPart from "./PriceAlertPopupFirstPart";
import { PriceAlertPopupModalProps } from "./PriceAlertPopupModal";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  paper: {
    overflow: "scroll",
    position: "absolute",
    width: "100%",
    maxWidth: 450,
    maxHeight: "95%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    display: "block",
  },

  secondePartContainer: {
    backgroundColor: "#F5EFFC",
    padding: "20px 5px",
  },

  headline: {
    fontSize: "50px",
    fontWeight: 900,
    textAlign: "center",
    color: theme.darkOrangeColor,
    fontFamily: "Roboto",
    marginTop: "5px",
    "@media (max-width: 768px)": {
      fontSize: "40px",
    },
  },

  subHeadline: {
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Roboto",
    lineHeight: "28px",
    "@media (max-width: 768px)": {
      fontSize: "22px",
      lineHeight: "26px",
    },
  },

  gotAnEmail: {
    textAlign: "center",
    fontSize: "18px",
    marginTop: "30px",
    lineHeight: "24px",
    "@media (max-width: 768px)": {
      marginTop: "20px",
    },
  },

  bold: {
    fontWeight: 500,
  },

  finalButton: {
    textAlign: "center",
    marginTop: "40px",
    marginBottom: "20px",
    paddingBottom: theme.spacing(1),
    "& button": {
      backgroundColor: theme.darkOrangeColor,
      fontSize: "large",
      color: theme.tableHeaderFgColor,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: "5px 30px",
    },

    "@media (max-width: 768px)": {
      marginTop: "25px",
    },
  },
}));

export type OpenState = "openUnconfirmedEmail" | "openConfirmedEmail" | "closed";

export type PriceAlertConfirmationPopupProps = {
  handleClose?: () => void;
  open: OpenState;
  product: any;
  imageUrl: string;
};

const PriceAlertConfirmationPopup: FC<PriceAlertConfirmationPopupProps> = ({
  imageUrl,
  product,
  open,
  handleClose,
}) => {
  const classes = useStyles();

  const { t } = useTranslation("priceAlert");
  return (
    <CommonModal
      open={open != "closed"}
      handleClose={handleClose}
      containerClass={classes.paper}
      dataTest="price-alert"
    >
      <PriceAlertPopupFirstPart handleClose={handleClose} imageUrl={imageUrl} product={product} />
      {open == "openUnconfirmedEmail" && (
        <div className={classes.secondePartContainer}>
          <div>
            <div className={classes.headline}>{t("confirmationHeadline")}</div>
            <div className={classes.subHeadline}>
              <div>{t("subHeadline1")}</div>
              <div>{t("subHeadline2")}</div>
            </div>
          </div>

          <div className={classes.gotAnEmail}>
            <div>
              <span className={classes.bold}>{t("noMail")}</span> {t("seeAlso")}
            </div>
            <div> {t("spamFolder")}</div>
          </div>

          <div className={classes.finalButton}>
            <Button onClick={handleClose}>{t("gotIt")}</Button>
          </div>
        </div>
      )}

      {open == "openConfirmedEmail" && (
        <div className={classes.secondePartContainer}>
          <div>
            <div className={classes.headline}>{t("confirmationHeadlineAlt")}</div>
            <div className={classes.subHeadline}>
              <div>{t("subHeadlineAlt1")}</div>
              <div>{t("subHeadlineAlt2")}</div>
            </div>
          </div>

          <div className={classes.gotAnEmail}>
            <div>
              <span className={classes.bold}>{t("priceNotice1")}</span>
            </div>
            <div> {t("priceNotice2")}</div>
          </div>

          <div className={classes.finalButton}>
            <Button onClick={handleClose}>{t("gotIt")}</Button>
          </div>
        </div>
      )}
    </CommonModal>
  );
};

export default PriceAlertConfirmationPopup;
