import React, { FC } from "react";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import PriceTag from "./PriceTag";
import { Product } from "./PriceAlertPopup";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  closeButton: {
    textAlign: "right",
    position: "absolute",
    width: "100%",
    paddingTop: theme.globalMarginMobile,
    paddingRight: theme.globalMarginMobile,
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },

  price: {
    marginBottom: theme.spacing(2),
    "& .FX-PriceTag-price": {
      fontSize: "x-large",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },

  productImageContainer: {
    textAlign: "center",
  },

  productImage: {
    width: "350px",
    height: "200px",
    marginTop: "10px",
    objectFit: "scale-down",
    "@media (max-width: 414px)": {
      width: "200px",
      height: "100px",
    },
  },

  productInfo: {
    textAlign: "center",
    fontWeight: 300,
  },

  productBrand: {
    color: theme.bikeCompareColor,
    fontWeight: 300,
    fontSize: "22px",
    "@media (max-width: 375px)": {
      fontSize: "20px",
    },
  },

  productTitle: {
    fontSize: "large",
    fontWeight: 300,
    padding: "0 20px",
    "@media (max-width: 768px)": {
      fontSize: "16px",
    },
  },
}));

export type PriceAlertPopupFirstPartProps = {
  handleClose?: () => void;
  imageUrl: string;
  product: Product;
};

const PriceAlertPopupFirstPart: FC<PriceAlertPopupFirstPartProps> = ({ handleClose, imageUrl, product }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.closeButton}>
        <IconButton color="inherit" aria-label="menu" onClick={handleClose} data-test="newsletter-close">
          <CloseIcon style={{ color: "#000" }} />
        </IconButton>
      </div>
      <div className={classes.productImageContainer}>
        <img className={classes.productImage} src={imageUrl} alt={`${product.brand} ${product.title}`} />
      </div>

      <div className={classes.productInfo}>
        <div className={classes.productBrand}>{product.brand}</div>
        <div className={classes.productTitle}>{product.title}</div>
        <PriceTag price={product.price} fetchOffersForProduct={product} className={classes.price} />
      </div>
    </>
  );
};

export default PriceAlertPopupFirstPart;
