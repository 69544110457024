import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TheCycleverseTheme } from "src/theme";


const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
    bikeSizingGrid: {
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridRowGap: "2em",
        width: "100%",
        paddingBottom: "2em",
        overflow: "hidden"
    },
    sizeBarRow: {
        display: "flex",
        flexDirection: "row",
        marginTop: "16px",
        minHeight: "2rem"
    },
    sizeBarLabel: {
        alignSelf: "center",
        textAlign: "right",
        fontWeight: "bold",
        minWidth: "3em",
        fontSize: "large",
        paddingRight: "16px",
        whiteSpace: "nowrap"
    },
    sizeBarBg: {
        flexGrow: 1,
        backgroundColor: "white",
        position: "relative"
    },
    sizeBarFill: {
        backgroundColor: "#9C00A2",
        position: "absolute",
        height: "100%",
        display: "flex"
    },
    sizeRangeText: {
        position:"absolute",
        alignSelf: "center",
        textAlign: "center",
        width: "100%",
        color: "black",
        fontWeight:"bold",
        overflow: "visible",
        whiteSpace: "nowrap",
        fontSize: "small",
        top: "2em",
        textAnchor: "middle"
    },
    centerAbsoluteText: {   
        marginLeft: "-100%",
        marginRight: "-100%",
        textAlign: "center"
    },
    rightAlignAbsoluteText: {
        marginLeft: "-150%",
        textAlign: "right"
    }
}));

export type BikeSizing = {
    sizeName: string,
    fromCm: number,
    toCm: number
}

export type BikeSizingTableProps = {
    sizing: Array<BikeSizing>
};

const BikeSizingTable: FC<BikeSizingTableProps> = (props) => {
    const classes = useStyles();

    const data = props.sizing;
    const min = Math.min(...data.map(d => d.fromCm))
    const max = Math.max(...data.map(d => d.toCm))
    const range = max - min;

    const relativeData = data.map(d => {
        const f = (d.fromCm - min) / range;
        const t = (d.toCm - min) / range;
        const w = t - f;
        return {
            sizeName: d.sizeName,
            relFrom: f,
            relTo: t,
            relWidth: w,
            fromCm: d.fromCm,
            toCm: d.toCm
        }
    });

    return (
        <div className={classes.bikeSizingGrid}>
            {relativeData.map((d, ix) => {
                return <React.Fragment key={ix}>
                    <div className={classes.sizeBarLabel}>{d.sizeName}</div>
                    <div className={classes.sizeBarBg}>
                        <div style={{ left: 100.0 * d.relFrom + "%", width: 100.0 * d.relWidth + "%" }} className={classes.sizeBarFill}>
                            <div className={classes.sizeRangeText}>
                                <div className={`${ix===relativeData.length-1?classes.rightAlignAbsoluteText:classes.centerAbsoluteText}`}>
                                {d.fromCm + " - " + d.toCm + " cm"}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            })}
        </div>
    );
};

export default BikeSizingTable;
