import React, { FC } from "react";

import AdBanner from "src/components/Ads/AdBanner";
import { AdSlotProductHead } from "src/components/Ads/AdSlots";
import BikeCompareButtonTeaser from "../../BikeCompare/BikeCompareButtonTeaser";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import OpenIcon from "../../Navigation/Icons/OpenIcon";
import ProductCategoryBreadcrumb from "./ProductCategoryBreadcrumb";
import ProductFeedWithFilter from "./ProductFeedWithFilter";
import Text from "../Text/Text";
import { TheCycleverseTheme } from "src/theme";
import environment from "../../../environment";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  productCategoryPageContent: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.globalMarginDesktop,
      marginRight: theme.globalMarginDesktop,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.globalMarginMobile,
      marginRight: theme.globalMarginMobile,
    },
  },
  breadcrumb: {
    marginTop: theme.spacing(1),
    display: "inline-block",
  },
  feed: {
    marginBottom: theme.spacing(10),
  },
  subCategories: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  text: {
    "& .FX-Text-content": {
      maxWidth: 600,
      margin: "auto",
      ...theme.formText,
    },
  },
  categoryMain: {
    ...theme.formText,
    paddingTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
  categoryTeaser: {
    textAlign: "center",
    fontFamily: "inherit",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    "& .FX-Text-content .textHeader": {
      fontFamily: "inherit",
      fontWeight: 300,
      fontSize: "x-large",
    },
    "& .FX-Text-content p": {
      fontSize: "0.875rem",
    },
  },
  readmoreButton: {
    textDecoration: "none",
    "& .MuiButton-root": {
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.background.secondary,
      color: theme.openButtonColor,
    },
  },
  readmoreIcon: {
    filter: "invert(50%) sepia(29%) saturate(3551%) hue-rotate(163deg) brightness(99%) contrast(101%)",
    fontSize: "12px!important",
  },
  compareTeaserContainer: {
    marginTop: theme.spacing(1),
    position: "sticky",
    top: theme.searchBarHeight + theme.spacing(1),
    zIndex: 2,
  },
  compareTeaserContent: {
    display: "flex",
    float: "right",
    flexDirection: "row",
  },
  compareTeaser: {
    float: "right",
    backgroundColor: theme.palette.primary.main,
    marginLeft: 10,
  },
  adHead: {
    margin: "auto",
    marginBottom: theme.spacing(4),
  },
}));

const isComparableBike = (category) => {
  return (
    (category.path.length > 0 && category.path[0].name == "Fahrräder") ||
    (category.path.length > 0 &&
      category.path[0].name == "E-Bikes" &&
      (category.path.length <= 1 || category.path[1].name != "E-Bike Teile"))
  );
};

const ProductCategoryPage: FC<{
  category: any;
  categoryFilter?: any;
  advertiser: string;
  advertiserCategory?: string;
  onApplyFilter?: any;
}> = ({ category, categoryFilter, advertiser, advertiserCategory, onApplyFilter }) => {
  const classes = useStyles();
  const { t, lang } = useTranslation("common");

  return (
    <div>
      <div className={classes.productCategoryPageContent}>
        {environment.features.enableBikeCompare && category && isComparableBike(category) && (
          <div className={classes.compareTeaserContainer}>
            <div className={classes.compareTeaserContent}>
              <BikeCompareButtonTeaser className={classes.compareTeaser} flavor="biobike" />
              <BikeCompareButtonTeaser className={classes.compareTeaser} flavor="ebike" />
            </div>
          </div>
        )}
        <ProductCategoryBreadcrumb category={category} className={classes.breadcrumb} titleComponent="h1" />

        {environment.features.productCategoryDescriptions && (
          <Text
            className={`${classes.text} ${classes.categoryTeaser}`}
            useCache={true}
            id={`category-teaser-${lang}-${category.id}`}
          >
            <a href="#info" className={classes.readmoreButton}>
              <Button endIcon={<OpenIcon className={classes.readmoreIcon} />}>{t("productCategoryReadmore")}</Button>
            </a>
          </Text>
        )}
        <ProductFeedWithFilter
          category={category}
          categoryFilter={categoryFilter}
          advertiser={advertiser}
          advertiserCategory={advertiserCategory}
          onApplyFilter={onApplyFilter}
          loadingComponent={
            <div>
              <div className={classes.productCategoryPageContent}>
                <div style={{ textAlign: "center", margin: 20 }}>
                  <CircularProgress color="secondary" />
                </div>
              </div>
            </div>
          }
          feedClassName={classes.feed}
        />
        {environment.features.productCategoryDescriptions && (
          <Text
            className={`${classes.text} ${classes.categoryMain}`}
            anchorId="info"
            useCache={true}
            id={`category-main-${lang}-${category.id}`}
          />
        )}
      </div>
      <AdBanner slot={AdSlotProductHead} className={classes.adHead} />
    </div>
  );
};

export default ProductCategoryPage;
