import React, { useCallback, useMemo } from "react";

import BikeDetails from "src/components/Content/Product/BikeDetails";
import MainLayout from "src/components/Layout/MainLayout";
import NewsletterCTA from "src/components/Newsletter/NewsletterCTA";
import ProductCategoryPage from "src/components/Content/Product/ProductCategoryPage";
import ProductDetails from "src/components/Content/Product/ProductDetails";
import SeoHead from "src/components/Seo/SeoHead";
import { createFetcher } from "src/lib/fetcher";
import environment from "src/environment";
import { getCategoryById } from "src/components/Content/Product/useCategory";
import { getFirstParam } from "src/lib/urlUtils";
import { default as productCategories } from "src/categoriesExplodedTree.json";
import { queryBikes } from "src/graphql/queries";
import { queryProducts } from "src/graphql/queries";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const Page = (props) => {
  const router = useRouter();
  const { prefetchedRecordData, reject } = props;
  const { generic, advertiser, advertiserCategory } = router.query;
  const { t, lang: locale } = useTranslation("common");

  const isBikeQuery = generic.length == 3; // cat/brand/model
  const isProductQuery = generic.length > 1;
  const categoryId = generic[0];
  const internalCategory = getCategoryById(categoryId);
  const category = useMemo(
    () =>
      internalCategory
        ? internalCategory
        : {
            pathNames: isProductQuery ? generic[1] : [],
            pathNamesRev: isProductQuery ? generic[1] : [],
            path: isProductQuery ? generic[1] : [],
            children: [],
            id: categoryId,
          },
    [internalCategory, generic, categoryId, isProductQuery]
  );

  const getCategoryName = useCallback(() => {
    const fallbackCategory = "";
    const fn = (): string => {
      if (!category) {
        return fallbackCategory;
      }
      const categoryPath = category.pathNames;
      const revCategoryPath = category.pathNamesRev;
      if (!categoryPath || categoryPath.length <= 0 || !revCategoryPath || revCategoryPath <= 0) {
        return fallbackCategory;
      }
      if (categoryPath[0] == "Fahrräder" && categoryPath.length > 1) {
        return `${revCategoryPath[0]} Fahrräder`;
      }
      if (categoryPath[0] == "E-Bikes" && categoryPath.length > 1) {
        return `${revCategoryPath[0]} E-Bikes`;
      }
      if (categoryPath[0] == "Bekleidung") {
        return `${revCategoryPath[0]} Bekleidung`;
      }
      return revCategoryPath[0];
    };
    return fn();
  }, [category]);

  const onApplyCategoryFilter = useCallback(
    (params) => {
      const fn = async (params) => {
        const brands = params?.brands ? params.brands : [];
        const sorting = params?.sorting ? params.sorting : "";
        const condition = params?.condition ? params.condition : "";
        const price = params?.priceRange ? params.priceRange : [];
        const categories = params?.categories ? params.categories : [];
        const query = {
          b: brands.join(","),
          o: sorting,
          d: condition,
          p: price.join(","),
          c: encodeURIComponent(categories.join(",")),
        };
        router.push(
          {
            pathname: "/[...generic]",
            query,
          },
          {
            pathname: `/${typeof generic == "string" ? generic : generic.join("/")}`,
            query,
          },
          { shallow: true, locale }
        );
      };
      return fn(params);
    },
    [generic, locale, router]
  );

  if (isBikeQuery) {
    const productSeoCategory = generic[0];
    const brandName = generic[1];
    const modelName = generic[2];
    const bikeId = "/" + brandName + "/" + modelName;
    return (
      <BikeDetails
        prefetchedData={prefetchedRecordData}
        reject={reject}
        bikeId={bikeId}
        seoCategoryFallback={productSeoCategory}
      />
    );
  }
  if (isProductQuery) {
    // show product
    const productSeoId = generic[1];
    const productSeoCategory = generic[0];
    return (
      <ProductDetails
        prefetchedData={prefetchedRecordData}
        reject={reject}
        seoId={productSeoId}
        seoCategory={productSeoCategory}
      />
    );
  } else {
    // show category
    return (
      <MainLayout searchBar="productSticky">
        <SeoHead
          title={t("productCategoryTitle", { categoryTitle: getCategoryName() })}
          description={t("productCategoryDescription", { categoryTitle: getCategoryName() })}
          ogDescription={t("productCategoryDescription", { categoryTitle: getCategoryName() })}
          keywords={category?.pathNamesRev?.join(" ")}
          schema={`{
                  "@context":"https://schema.org/",
                  "@type": "BreadcrumbList",
                  "itemListElement": [${category.path.map((item, i) => {
                    return `{
                      "@type": "ListItem", 
                      "position": "${i + 1}",
                      "name": "${item.name}",
                      "item": "https://${environment.host}/${locale}/${item.id}"
                    }`;
                  })}]
                }`}
        />
        <NewsletterCTA layout="general" page="productCategory" />
        <ProductCategoryPage
          category={category}
          categoryFilter="internal"
          advertiser={getFirstParam(advertiser)}
          advertiserCategory={getFirstParam(advertiserCategory)}
          onApplyFilter={onApplyCategoryFilter}
        />
      </MainLayout>
    );
  }
};

Page.getInitialProps = async ({ query, res, locale }) => {
  const { generic } = query;

  const forward = (path) => {
    res.writeHead(301, {
      Location: `/${locale}/${path}`,
    });
    res.end();
  };

  const reject = () => {
    res.statusCode = 404;
  };

  if (res) {
    if (generic.length <= 0) {
      reject();
      return;
    }

    if (generic.length == 1) {
      const path1 = generic[0];

      // apps & digital services
      if (path1 === "apps-digitale-services") {
        forward("apps-digitale-services/all");
        return;
      }

      // blog
      if (path1 === "artikel" || path1 === "articles" || path1 === "all") {
        forward("blog/");
        return;
      }

      // blog subcategories
      if (
        path1 === "article" ||
        path1 === "tests" ||
        path1 === "kaufberatung" ||
        path1 === "innovation" ||
        path1 === "tricks" ||
        path1 === "knowhow" ||
        path1 === "food" ||
        path1 === "interviews"
      ) {
        forward(`blog/${path1}`);
        return;
      }

      // blog articles
      const articleId = path1;
      if (
        articleId == "10-gruende-indoorcycling" ||
        articleId == "99-ernaehrungsmythen" ||
        articleId == "99-gruende-radfahren" ||
        articleId == "Fahrradinspektion--Die-11-Punkte-DIY-Checkliste" ||
        articleId == "Mecklenburger-Seen-Runde-Streckenplanung" ||
        articleId == "Muttertag-2020-Die-9-besten-Geschenke-fr-radfahrende-Mtter" ||
        articleId == "Rennradinspektion-Die-35-Punkte-Profi-Checkliste" ||
        articleId == "Rennradtraining-fuer-Frauen" ||
        articleId == "Vatertag-2020-Die-besten-Geschenkideen-fr-Radfahrer" ||
        articleId == "carryyygum-kleinster-gepaecktraeger" ||
        articleId == "corona-rueckwaerts-prognose" ||
        articleId == "critical-mass-termine" ||
        articleId == "critical-mass-wissen" ||
        articleId == "fahrradbuecher-lesetipp" ||
        articleId == "fahrradhose-boxershorts-forbicy" ||
        articleId == "indoorcycling-vergleich-heimtrainer" ||
        articleId == "kochbuecher-radfahrer-ausdauersportler" ||
        articleId == "lumos-vs-livall-helme" ||
        articleId == "rennrad-grundaustattung" ||
        articleId == "run-bell-handklingel" ||
        articleId == "schneller-radfahren-tipps"
      ) {
        forward(`blog/${articleId}`);
        return;
      }
    } else if (generic.length == 2) {
      const path1 = generic[0];
      const path2 = generic[1];

      // blogs
      if (path1 === "blog") {
        // handle blog redirects here:
        let id = path2;
        if (id == "cube-neuheiten") {
          id = "cube-mountainbike-neuheiten";
        }
        forward(`blog/${id}`);
        return;
      }

      // legacy articles
      if (path1 === "articles") {
        // handle legacy links here:
        let id = path2;
        if (id == "0a1c2f50-aa79-4837-8080-4f94b8e7d4e7") {
          id = "fahrradbuecher-lesetipp";
        }
        if (id == "0dc68625-6cc8-4bc7-a414-91854290385d") {
          id = "carryyygum-kleinster-gepaecktraeger";
        }
        if (id == "14a53954-182a-47dd-a7f2-bb9ae950fb3a") {
          id = "indoorcycling-vergleich-heimtrainer";
        }
        if (id == "4600039f-f33b-44be-adb0-fbcdb79459bc") {
          id = "lumos-vs-livall-helme";
        }
        if (id == "656e96fe-45e8-4e2e-99c8-11d7fec5707d") {
          id = "run-bell-handklingel";
        }
        if (id == "73d422fb-96e8-4029-967e-07f57b2d1afa") {
          id = "schneller-radfahren-tipps";
        }
        if (id == "97439179-3a42-49a9-a042-25e80c7ccacb") {
          id = "99-gruende-radfahren";
        }
        if (id == "9e42d7e3-c657-4bea-bcc4-64f7ae483f3a") {
          id = "corona-rueckwaerts-prognose";
        }
        if (id == "a81bc05e-2549-46cf-9e4e-e9e9b61e2556") {
          id = "10-gruende-indoorcycling";
        }
        if (id == "bdb419ac-4fac-49c1-9b58-fe61d2b171c2") {
          id = "99-ernaehrungsmythen";
        }
        if (id == "c4bd8851-d29a-487a-bc50-d562bd08d0ac") {
          id = "kochbuecher-radfahrer-ausdauersportler";
        }
        if (id == "dfb60a94-3b67-4cce-bd1e-db31d8fd3179") {
          id = "fahrradhose-boxershorts-forbicy";
        }
        forward(`blog/${id}`);
        return;
      }
    } else if (generic.length == 3) {
      const path1 = generic[0];
      const path2 = generic[1];
      const path3 = generic[2];

      // legacy articles folder
      if (path1 === "articles") {
        if (path2 === "categories") {
          if (
            path3 === "all" ||
            path3 === "tests" ||
            path3 === "kaufberatung" ||
            path3 === "innovation" ||
            path3 === "tricks" ||
            path3 === "knowhow" ||
            path3 === "food" ||
            path3 === "interviews"
          ) {
            forward(`blog/${path3}`);
            return;
          }
        }
      }
    }

    if (generic.length === 2) {
      const categoryPath = generic[0];
      const seoId = generic[1];

      if (!productCategories[categoryPath] && categoryPath != "p") {
        forward(`p/${seoId}`);
        return;
      }

      const fetcher = createFetcher();
      const prefetchedRecordData = await fetcher([
        queryProducts,
        JSON.stringify({
          action: "getBySeoId",
          seoId,
        }),
      ]);
      if (!prefetchedRecordData?.queryProducts?.items || prefetchedRecordData.queryProducts.items.length <= 0) {
        reject();
      }

      const product = prefetchedRecordData?.queryProducts?.items[0];
      if (product && product.visibility?.startsWith("hidden") && !!product.bikeSlug) {
        forward(`${categoryPath}/${product.bikeSlug}`);
        return;
      }

      return { prefetchedRecordData };
    }

    if (generic.length === 3) {
      const fetcher = createFetcher();

      const bikeIdFromSlug = (slug: any) => {
        const brandName = slug[1];
        const modelName = slug[2];
        const modelYear = slug[3];
        const bikeId =
          typeof slug === "string" ? slug : "/" + brandName + "/" + modelName + (!!modelYear ? "-" + modelYear : "");
        return bikeId;
      };

      const prefetchedRecordData = await fetcher([
        queryBikes,
        JSON.stringify({
          action: "getByBikeId",
          bikeId: bikeIdFromSlug(generic),
        }),
      ]);
      if (!prefetchedRecordData?.queryBikes?.items || prefetchedRecordData.queryBikes.items.length <= 0) {
        reject();
      }
      return { prefetchedRecordData };
    }
  }

  return {
    key: generic.join("-"), // force state reset for dynamic route
  };
};

export default Page;
