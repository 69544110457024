import React, { FC } from "react";

import CommonModal from "src/components/common/CommonModal";
import Grid from "@material-ui/core/Grid";
import { NewsletterLayout } from "types";
import PriceAlertForm from "./PriceAlertForm";
import PriceAlertPopupFirstPart from "./PriceAlertPopupFirstPart";
import { Product } from "./PriceAlertPopup";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "src/translations/useTranslationExtras";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  paper: {
    overflow: "scroll",
    position: "absolute",
    width: "100%",
    maxWidth: 450,
    maxHeight: "95%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    display: "block",
  },

  formContainerBackground: {
    background: "#f2f2f0",
  },

  setPriceAlert: {
    display: "inline",
    color: theme.darkOrangeColor,
    fontWeight: 900,
    fontFamily: theme.typography.fontFamily,
    "@media (max-width: 375px)": {
      fontSize: "28px",
    },
  },

  message: {
    fontSize: "35px",
    padding: "0 40px",
    lineHeight: "40px",
    fontWeight: 300,
    fontFamily: theme.typography.fontFamily,
    "@media (max-width: 375px)": {
      fontSize: "22px",
      lineHeight: "24px",
    },
  },

  footerDecoration: {
    textDecoration: "underline",
    marginTop: "10px",
    fontFamily: theme.typography.button.fontFamily,
    fontWeight: 300,
    cursor: "pointer",
    "@media (max-width: 375px)": {
      fontSize: "16px",
      lineHeight: "24px",
      marginTop: 0,
    },
  },

  subscriptionFormContainer: {
    textAlign: "center",
    background: "#f2f2f0",
    marginTop: "20px",
    fontSize: "large",
    padding: "20px 5px",
    fontFamily: theme.highlightFontFamily,
    "@media (max-width: 375px)": {
      padding: "0 5px 5px 5px",
      marginTop: 0,
    },
  },

  bold: {
    fontWeight: 600,
  },

  modalCustomForm: {
    paddingTop: theme.spacing(1),
    margin: "auto",
    marginTop: "10px",
  },
}));

export type PriceAlertPopupModalProps = {
  afterSubscribe?: () => void;
  handleClose?: () => void;
  open: boolean;
  layout?: NewsletterLayout;
  page?: string;
  imageUrl: string;
  product: Product;
  openConfirmationModal?: (emailConfirmed?: boolean) => void;
  closeConfrimationModal?: () => void;
};

const PriceAlertPopupModal: FC<PriceAlertPopupModalProps> = (props) => {
  const { open, handleClose, imageUrl, product, openConfirmationModal } = props;
  const classes = useStyles();

  const { t, lang: locale } = useTranslation("priceAlert");
  const { priceAsFloat } = useTranslationExtras(t, locale);

  if(!product)
    return null;

  return (
    <CommonModal open={open} handleClose={handleClose} containerClass={classes.paper} dataTest="newsletter-container">
      <>
        <PriceAlertPopupFirstPart handleClose={handleClose} imageUrl={imageUrl} product={product} />
        <div className={classes.subscriptionFormContainer}>
          <h1 className={classes.setPriceAlert}>{t("setPriceAlert")}</h1>
          <div className={classes.message}>{t("priceAlertDescription")}</div>

          <Grid container className={classes.formContainerBackground}>
            <Grid item xs={10} className={classes.modalCustomForm}>
              <PriceAlertForm
                onSuccess={() => handleClose()}
                product={product}
                productId={product.id}
                productCategory={product.category}
                productPrice={priceAsFloat(product.price)}
                openConfirmationModal={openConfirmationModal}
              />
              <div className={classes.footerDecoration}>
                <div onClick={handleClose}>{t("noThanks")}</div>
                <div onClick={handleClose} className={classes.bold}>
                  {t("subscriptionContent")}
                </div>
                <div onClick={handleClose}>{t("watchIt")}</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    </CommonModal>
  );
};

export default PriceAlertPopupModal;
