import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TheCycleverseTheme } from "src/theme";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  diagramContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  diagramMidSection: {
    flexGrow: 1,
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  barContainer: {
    minHeight: "2rem",
    backgroundColor: "transparent",
    position: "relative",
    marginRight: "16px",
    marginLeft: "16px",
  },
  barLabel: {
    marginRight: "16px",
    marginLeft: "16px",
  },
  barLabelEstimates: {
    marginRight: "16px",
    marginLeft: "16px",
    fontSize: "x-small",
    color: "gray"
  },
  barFill: {
    display: "flex",
  },
  barFillText: {
    alignSelf: "center",
    width: "100%",
    textAlign: "right",
    paddingRight: "16px",
    fontWeight: "bold"
  },
  currentBar: {
    backgroundColor: "#9C00A2",
    color: "white",
    position: "absolute",
    height: "100%",
    textAlign: "right",
  },
  comparisonBar: {
    backgroundColor: "lightGray",
    color: "white",
    position: "absolute",
    height: "100%",
    textAlign: "right",
  },
  horizontalDivider: {
    height: "1px",
    width: "100%",
    background: "black",
    marginTop: "8px",
    marginBottom: "8px",
  }
}));

export type BikeSpeedEstimate = {
  maxSpeedKph: number;
  maxSpeedKphComparables: number;
  gearsRear: number; // Kettenblätter hinten
  gearsFront: number; // Kettenblätter vorne
  cogsRearMin: number; // Anzahl Zähne des kleinsten Kettenblattes hinten
  cogsFrontMax: number; // Anzahl Zähne des größten Kettenblattes vorne
  wheelCircumferenceMm: number;
};

export type BikeMaxSpeedDiagramProps = {
  speedEstimate: BikeSpeedEstimate;
  bikeName: String;
};

const renderIconUp = () => {
  return (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="48.25px"
        height="48.25px"
        viewBox="0 0 48.25 48.25"
        enableBackground="new 0 0 48.25 48.25"
        xmlSpace="preserve"
      >
        <g>
          <defs>
            <rect id="BMSDSVGID_1_" width="48.25" height="48.25" />
          </defs>
          <clipPath id="BMSDSVGID_2_">
            <use href="#BMSDSVGID_1_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#BMSDSVGID_2_)"
            fill="#FFFFFF"
            d="M24.125,48.125c13.255,0,24-10.745,24-24s-10.745-24-24-24s-24,10.745-24,24
       S10.87,48.125,24.125,48.125"
          />
          <circle
            clipPath="url(#BMSDSVGID_2_)"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="0.25"
            cx="24.125"
            cy="24.125"
            r="24"
          />
        </g>
        <polygon points="17.584,22.103 6.325,37.54 41.926,37.54 32.126,17.483 24.125,32.108 " />
        <g>
          <defs>
            <rect id="BMSDSVGID_3_" width="48.25" height="48.25" />
          </defs>
          <clipPath id="BMSDSVGID_4_">
            <use href="#BMSDSVGID_3_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#BMSDSVGID_4_)"
            fill="none"
            stroke="#000000"
            strokeWidth="1.471"
            d="M21.04,10.841
       c-5.875,0-11.132,4.84-11.132,10.81"
          />
          <polygon clipPath="url(#BMSDSVGID_4_)" points="25.12,10.841 19.888,5.978 19.888,15.705 	" />
        </g>
      </svg>
    </div>
  );
};
const renderIconDown = () => {
  return (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="48.25px"
        height="48.25px"
        viewBox="0 0 48.25 48.25"
        enableBackground="new 0 0 48.25 48.25"
        xmlSpace="preserve"
      >
        <g>
          <defs>
            <rect id="BMSDDOWN_SVGID_1_" width="48.25" height="48.25" />
          </defs>
          <clipPath id="BMSDDOWN_SVGID_2_">
            <use href="#BMSDDOWN_SVGID_1_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#BMSDDOWN_SVGID_2_)"
            fill="#FFFFFF"
            d="M24.125,48.125c13.255,0,24-10.745,24-24s-10.745-24-24-24s-24,10.745-24,24
       S10.87,48.125,24.125,48.125"
          />
          <circle
            clipPath="url(#BMSDDOWN_SVGID_2_)"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="0.25"
            cx="24.125"
            cy="24.125"
            r="24"
          />
        </g>
        <polygon points="30.665,22.171 41.925,37.609 6.324,37.609 16.124,17.552 24.125,32.177 " />
        <g>
          <defs>
            <rect id="BMSDDOWN_SVGID_3_" width="48.25" height="48.25" />
          </defs>
          <clipPath id="BMSDDOWN_SVGID_4_">
            <use href="#BMSDDOWN_SVGID_3_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#BMSDDOWN_SVGID_4_)"
            fill="none"
            stroke="#000000"
            strokeWidth="1.471"
            d="M37.062,17.776
       c0-5.875-4.84-11.132-10.81-11.132"
          />
          <polygon clipPath="url(#BMSDDOWN_SVGID_4_)" points="37.062,21.856 41.926,16.624 32.198,16.624 	" />
        </g>
      </svg>
    </div>
  );
};

const BikeMaxSpeedDiagram: FC<BikeMaxSpeedDiagramProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("bikeDetails");
    const {speedEstimate, bikeName} = props;
    const data = {
        current: {
            name:  bikeName,
            value: Math.round(speedEstimate.maxSpeedKph)
        },
        comparison: {
            name: t('bikeDetails:bikeMaxSpeedDiagramSimilarBikes'),
            value: Math.round(speedEstimate.maxSpeedKphComparables)
        },
        maxValue: 100,
        unit: "km/h"
    };

  return (
    <div className={classes.diagramContainer}>
      <div>{renderIconUp()}</div>
      <div className={classes.diagramMidSection}>
        <div>
          <div className={classes.barLabel}>{data.current.name}</div>
          <div className={classes.barContainer}>
            <div
              style={{ width: (data.current.value / data.maxValue) * 100.0 + "%" }}
              className={`${classes.barFill} ${classes.currentBar}`}
            >
              <div className={classes.barFillText}>{data.current.value + " " + data.unit}</div>
            </div>
          </div>
        </div>
        <div className={classes.horizontalDivider} />
        <div>
          <div className={classes.barContainer}>
            <div
              style={{ width: (data.comparison.value / data.maxValue) * 100.0 + "%" }}
              className={`${classes.barFill} ${classes.comparisonBar}`}
            >
              <div className={classes.barFillText}>{data.comparison.value + " " + data.unit}</div>
            </div>
          </div>
          <div className={classes.barLabel}>{data.comparison.name}</div>
          <div className={classes.barLabelEstimates}>{t('bikeDetails:bikeMaxSpeedDiagramEstimates')}</div>
        </div>
      </div>
      <div>{renderIconDown()}</div>
    </div>
  );
};

export default BikeMaxSpeedDiagram;
