import { FC } from "react";
import LocaleLink from "src/components/Navigation/LocaleLink";
import { makeStyles } from "@material-ui/core/styles";
import { TheCycleverseTheme } from "src/theme";
import useTranslation from "next-translate/useTranslation";
import { FrameSize } from "./NormalizedBikeTypes";
const useStyles = makeStyles((theme: TheCycleverseTheme) => ({

    bikeVariantsHeader: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginTop: "8px",
        marginBottom: "16px",
    },
    bikeVariantsHeaderText: {
        fontSize: "1.1em",
        fontWeight: "bold",
    },
    bikeVariantsHeaderTeaser: {
        color: "#00AEEF",
        cursor: "pointer",
        textDecorationLine: "underline",
    },
    frameSizeVariantsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    frameSizeVariantContainer: {
        margin: "1px",
        borderRadius: "4px",
        marginRight: "8px",
        marginBottom: "8px",
        padding: "4px",
        paddingLeft: "16px",
        paddingRight: "16px",
        display: "inline-block",
    },
    frameSizeVariantContainerInactive: {
        backgroundColor: "#f2f2f0",
        color: "black",
    },
    frameSizeVariantContainerActive: {
        backgroundColor: "#00AEEF",
        color: "white",
    },
    frameSizeVariantContainerAvailable: {
        opacity: "100%",
        cursor: "pointer",
    },
    frameSizeVariantContainerUnavailable: {
        opacity: "40%",
        cursor: "auto",
    },
    frameSizeVariantTextCm: {
        textAlign: "center",
        fontSize: "14px",
    },
    frameSizeVariantTextLetter: {
        textAlign: "center",
        fontSize: "14px",
    },
    frameSizeVariantTextInches: {
        textAlign: "center",
        fontSize: "12px",
    },
}));


export interface InternalFrameSize {
    cm?: number,
    letter?: string
}


const formatCmToInches = (centimeters: number) => {
    const inchesX10 = Math.round(centimeters * 0.3937008 * 10.0);
    const str = "" + inchesX10;
    const ints = str.slice(0, str.length - 1);
    const decimals = str.slice(str.length - 1);
    const result = ints + "," + decimals + '"';
    return result;
  };

export const SizeVariantsSelection: FC<{ variantArray: FrameSize[]; availableArray: FrameSize[], selectedFrameSize: FrameSize, letterSynonym: (string) => string, getRouteWithSize: (FrameSize) => string }> = ({
    variantArray,
    availableArray,
    selectedFrameSize,
    letterSynonym,
    getRouteWithSize
}) => {
    const classes = useStyles();
    const { t, lang } = useTranslation("productDetails");

    if (!variantArray || variantArray.length === 0) {
        return null;
    }
    const variantName = t("bikeDetails:productBikeVariantsFramesizeHeaderText");
    const isAvailable = (c: FrameSize) => {
        const cmAvailable = !!c?.cm && availableArray.map((a) => a.cm).includes(c.cm);
        const letterAvailable = !!c?.letter && availableArray.map((a) => letterSynonym(a.letter)).includes(c?.letter);
        const a = cmAvailable || letterAvailable
        return a;
    };
    const getFrameSizeButtonCm = (v: FrameSize) => {
        const showAsSelected = selectedFrameSize?.cm === v.cm;
        return <div
            key={v.cm}
            className={`${classes.frameSizeVariantContainer} 
  ${showAsSelected
                    ? classes.frameSizeVariantContainerActive
                    : classes.frameSizeVariantContainerInactive
                }
  ${isAvailable(v) ? classes.frameSizeVariantContainerAvailable : classes.frameSizeVariantContainerUnavailable
                }
`}
        >
            <div className={classes.frameSizeVariantTextCm}>{v.cm} cm</div>
            <div className={classes.frameSizeVariantTextInches}>{formatCmToInches(v.cm)}</div>
        </div>;
    }

    const getFrameSizeButtonLetter = (v: FrameSize) => {
        const showAsActive = selectedFrameSize?.letter === v.letter;
        return <div
            key={v.letter}
            className={`${classes.frameSizeVariantContainer} 
  ${showAsActive
                    ? classes.frameSizeVariantContainerActive
                    : classes.frameSizeVariantContainerInactive
                }
  ${isAvailable(v) ? classes.frameSizeVariantContainerAvailable : classes.frameSizeVariantContainerUnavailable
                }
`}
        >
            <div className={classes.frameSizeVariantTextLetter}>{v.letter}</div>
        </div>;
    }

    const getFrameSizeButton = (v: FrameSize) => {
        return (!v.cm) ? getFrameSizeButtonLetter(v) : getFrameSizeButtonCm(v);
    }
    return (
        <div>
            <div className={classes.bikeVariantsHeader}>
                <div className={classes.bikeVariantsHeaderText}>{variantName}:</div>
                <div>
                    <LocaleLink href="/kaufberatung-fahrradgroesse" as="/kaufberatung-fahrradgroesse" target="new">
                        {t("bikeDetails:productBikeVariantsFramesizeHeaderCalculatorTeaserText")}
                    </LocaleLink>
                </div>
            </div>
            <div className={classes.frameSizeVariantsContainer}>
                {variantArray
                    .map((v) => {
                        const targetRoute = getRouteWithSize(v);
                        const linkContent = getFrameSizeButton(v);
                        return isAvailable(v) ? (
                            <LocaleLink key={"lc-" + (v.cm || v.letter)} as={targetRoute} href={targetRoute}>
                                {linkContent}
                            </LocaleLink>
                        ) : (
                            linkContent
                        );
                    })}
            </div>
        </div>
    );
};
