import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import LocaleLink from "../../Navigation/LocaleLink";

const useStyles = makeStyles(() => ({
  level: {
    margin: 5,
    marginLeft: 0,
    fontSize: "0.875rem",
    fontWeight: 300,
    display: "inline-block",
  },
  separator: {
    marginLeft: 0,
    "&::after": {
      content: '"/"',
      marginLeft: 5,
    },
  },
  home: {},
  breadcrumbs: {
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 0,
  },
}));

const ProductCategoryBreadcrumb = (props) => {
  const { category, className, titleComponent } = props;
  const { t } = useTranslation("categories");
  const classes = useStyles();

  if (!category) {
    return null;
  }

  const getLinks = () => {
    return category.path.map(({ name, id }, i) => (
      <LocaleLink notStyled key={i} href="/[...generic]" as={`/${id}`}>
        <span className={`${classes.level} ${i < category.path.length - 1 ? classes.separator : ""}`}>{t(name)}</span>
      </LocaleLink>
    ));
  };

  return (
    <div className={className}>
      <div className={classes.home}>
        <LocaleLink notStyled href="/" as="/">
          <span>Home</span>
        </LocaleLink>
      </div>
      {titleComponent == "h1" ? (
        <h1 className={classes.breadcrumbs}>{getLinks()}</h1>
      ) : (
        <div className={classes.breadcrumbs}>{getLinks()}</div>
      )}
    </div>
  );
};

export default ProductCategoryBreadcrumb;
