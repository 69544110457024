import { GPTSlot, GUIDELINES } from "goopubtag";
import { useContext, useEffect, useState } from "react";
import { useMedia, useTimeout } from "react-use";

import AdContext from "./AdContext";
import { AdSlotType } from "./AdSlots";
import { TheCycleverseTheme } from "src/theme";
import clsx from "clsx";
import environment from "src/environment";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { v4 as uuidv4 } from "uuid";

const { UNIT_SIZE, SCREEN_SIZE } = GUIDELINES;

const useStyles = makeStyles(() => ({
  banner: (props: { adSlot: AdSlotType; width: number; height: number }) => {
    return {
      maxWidth: "100%",
      aspectRatio: `${props.width} / ${props.height}`,
      width: props.width,
      height: props.height,
      backgroundColor: environment.name == "dev" ? "#9dbbbc" : "inherit",
      overflow: "hidden",
    };
  },
}));

const AdBanner = (props: {
  slot: AdSlotType;
  className?: string;
  hideOnMobile?: boolean;
  disabled?: boolean;
  collapseOnFallback?: boolean;
}) => {
  const { adsEnabled } = useContext(AdContext);
  const isMobileView = useMedia("(max-width: 480px)", true);
  const width = isMobileView && props.slot.widthMobile ? props.slot.widthMobile : props.slot.widthDesktop;
  const height = isMobileView && props.slot.heightMobile ? props.slot.heightMobile : props.slot.heightDesktop;
  const classes = useStyles({ adSlot: props.slot, width, height });
  const [isReady, cancel] = useTimeout(2000);
  const [slotId, setSlotId] = useState(`${props.slot.slotId}-${uuidv4()}`);
  const adUnitPath =
    isMobileView && props.slot.adUnitPathMobile ? props.slot.adUnitPathMobile : props.slot.adUnitPathDesktop;

  if (!environment.features.showAds) {
    return null;
  }

  if (props.hideOnMobile && isMobileView) {
    return null;
  }

  if (props.disabled) {
    return null;
  }

  return (
    <div className={clsx(classes.banner, props.className)}>
      {adsEnabled && (
        <GPTSlot
          adUnit={adUnitPath}
          slotId={slotId}
          sizes={[width, height]}
          fallback={props.collapseOnFallback ? "collapse" : "default"}
        />
      )}
      {isReady && !props.collapseOnFallback && !adsEnabled && !isMobileView && props.slot.fallbackDesktop && (
        <a href={props.slot.fallbackDesktop.link} target="_blank">
          <img src={props.slot.fallbackDesktop.image} alt={props.slot.fallbackDesktop.imageAlt} />
        </a>
      )}
      {isReady && !props.collapseOnFallback && !adsEnabled && isMobileView && props.slot.fallbackMobile && (
        <a href={props.slot.fallbackMobile.link} target="_blank">
          <img src={props.slot.fallbackMobile.image} alt={props.slot.fallbackMobile.imageAlt} />
        </a>
      )}
    </div>
  );
};
export default AdBanner;
