import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    chartSvgContainer: {
        maxWidth: "100vw"
    },
    title: {
        fontSize: 32,
        fontWeight: 900,
        lineHeight: "1.1",
        marginBottom: 8,
    }
}));

export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;

export type GeometryMeasurement = `frame` |
    `seattubeextension` |  // Sitzrohrverlängerung
    `wheels` |  // Räder
    `stack` |
    `reach` |
    `chainstaylength` |  // Kettenstrebenlänge
    `seatangle` |  // Sitzwinkel
    `seattube` |  // Sitzrohrlänge
    `steeringtubeangle` |  // Steuerrohrwinkel
    `steeringtubelength` |  // Steuerrohrlänge
    `wheelbase` |  // Radstand
    `toptubelength` |  // Oberrohrlänge
    `trailcut` |  // Nachlauf
    `forkprebend` |  // Gabelvorbiegung
    `pedalhubheight` |  // Tretlagerhöhe
    `standoverheight` |  // Überstandshöhe
    `bottombracketdrop` // Innenlagerabsenkung
    ;

export type BikeGeometryVisibility = Map<GeometryMeasurement, Boolean>;
export type BikeGeometryColors = Map<GeometryMeasurement, Color>;

export const defaultHighlightColor: Color = "#FF4101";
export const defaultFrameColor: Color = "#9E9D9F";
export const defaultWheelColor: Color = "#C1C1BA";
export const defaultForkColor: Color = "#B1B0B1";
export const defaultSeatTubeExtension: Color = "#B1B0B1";

export type GeometryMeasurementValue = {
    color: Color,
    visible: Boolean
}

const defaultHighlight = { color: defaultHighlightColor, visible: false }

export const defaultGeometryValues = new Map<GeometryMeasurement, GeometryMeasurementValue>([
    [`frame`, { color: defaultFrameColor, visible: true }],
    [`seattubeextension`, { color: defaultSeatTubeExtension, visible: true }], // Sitzrohrverlängerung
    [`wheels`, { color: defaultWheelColor, visible: true }], // Räder

    [`stack`, {...defaultHighlight}],
    [`reach`, {...defaultHighlight}],
    [`chainstaylength`, {...defaultHighlight}], // Kettenstrebenlänge
    [`seatangle`, {...defaultHighlight}], // sitzwinkel
    [`seattube`, {...defaultHighlight}], // sitzrohrlänge
    [`steeringtubeangle`, {...defaultHighlight}], // steuerrohrwinkel
    [`steeringtubelength`, {...defaultHighlight}], // steuerrohrlänge
    [`wheelbase`, {...defaultHighlight}], // radstand
    [`toptubelength`, {...defaultHighlight}], // oberrohrlänge
    [`trailcut`, {...defaultHighlight}], // nachlauf
    [`forkprebend`, {...defaultHighlight}], // Gabelvorbiegung
    [`pedalhubheight`, {...defaultHighlight}], // Tretlagerhöhe
    [`standoverheight`, {...defaultHighlight}], // Überstandshöhe
    [`bottombracketdrop`, {...defaultHighlight}] // Innenlagerabsenkung
]);

export type BikeGeometryVisibilityProps = {
    frame?: boolean,
    seattubeextension?: boolean,
    wheels?: boolean,

    stack?: boolean,
    reach?: boolean,
    chainstaylength?: boolean,
    seatangle?: boolean,
    seattube?: boolean,
    steeringtubeangle?: boolean,
    steeringtubelength?: boolean,
    wheelbase?: boolean,
    toptubelength?: boolean,
    trailcut?: boolean,
    forkprebend?: boolean,
    pedalhubheight?: boolean,
    standoverheight?: boolean,
    bottombracketdrop?: boolean,
}

export type BikeGeometryColorProps = {
    frame?: Color,
    seattubeextension?: Color,
    wheels?: Color,

    stack?: Color,
    reach?: Color,
    chainstaylength?: Color,
    seatangle?: Color,
    seattube?: Color,
    steeringtubeangle?: Color,
    steeringtubelength?: Color,
    wheelbase?: Color,
    toptubelength?: Color,
    trailcut?: Color,
    forkprebend?: Color,
    pedalhubheight?: Color,
    standoverheight?: Color,
    bottombracketdrop?: Color,
}

export type BikeGeometryNumbersProps = {
    sizeName: string,
    wheelSizeInchesX10: number, 
    stack: number,  // millimeters
    reach: number,  // millimeters
    toptubelength: number,  // millimeters
    seattube: number,  // millimeters
    seatangleX10: number,  // degrees
    steeringtubelength: number,  // millimeters
    steeringtubeangle: number,  // millimeters
    bottombracketdrop: number,  // millimeters
    pedalhubheight: number,  // millimeters
    chainstaylength: number,  // millimeters
    wheelbase: number,  // millimeters
    standoverheight: number,  // millimeters
    trailcut: number,  // millimeters
    forkprebend: number,  // millimeters
}

export type BikeGeometryProps = {
    visibility?: BikeGeometryVisibilityProps
    colors?: BikeGeometryColors
};

const BikeGeometry: FC<BikeGeometryProps> = (props) => {
    const classes = useStyles();


    const defaults: Map<GeometryMeasurement, GeometryMeasurementValue> = new Map([...Array.from(defaultGeometryValues.entries())]);
    const colorOverrides: Map<GeometryMeasurement, Color> = (!!props.colors) ? new Map(Object.entries(props.colors).map(x => [x[0] as GeometryMeasurement, x[1] as Color])) : new Map()
    const visibilityOverrides: Map<GeometryMeasurement, Boolean> = (!!props.visibility) ? new Map(Object.entries(props.visibility).map(x => [x[0] as GeometryMeasurement, x[1] as Boolean])) : new Map()

    const isVisible = (key: GeometryMeasurement) => visibilityOverrides.has(key) ? visibilityOverrides.get(key) : defaults.get(key).visible;
    const getColor = (key: GeometryMeasurement) => colorOverrides.has(key) ? colorOverrides.get(key) : defaults.get(key).color;

    const hl = (key: GeometryMeasurement) => {
        return isVisible(key)? getColor(key) : "none";
    }

    return (
        <div>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="521.814px" height="279.185px" viewBox="0 0 521.814 279.185" enableBackground="new 0 0 521.814 279.185"  className={classes.chartSvgContainer}>
                <g>
                    <defs>
                        <rect id="GEODIAID_1_" width="521.814" height="279.185" />
                    </defs>
                    <clipPath id="GEODIAID_2_">
                        <use xlinkHref="#GEODIAID_1_" overflow="visible" />
                    </clipPath>
                    <circle clipPath="url(#GEODIAID_2_)" fill="none" stroke={hl(`wheels`)} strokeWidth="8" cx="104.053" cy="175.133" r="100.052" />
                    <circle clipPath="url(#GEODIAID_2_)" fill="none" stroke={hl(`wheels`)} strokeWidth="8" cx="417.761" cy="175.133" r="100.052" />

                    <line clipPath="url(#GEODIAID_2_)" fill="none" stroke={hl(`seattubeextension`)} strokeWidth="7" x1="184.708" y1="37.08" x2="171.907" y2="1.176" />
                </g>
                <line fill="none" stroke={hl(`seattubeextension`)} strokeWidth="10" strokeLinecap="round" x1="416.709" y1="175.58" x2="367.709" y2="56.58" />
                <line fill="none" stroke={hl(`frame`)} strokeWidth="10" x1="184.708" y1="37.08" x2="231.708" y2="196.58" />
                <g>
                    <defs>
                        <rect id="GEODIAID_3_" width="521.814" height="279.185" />
                    </defs>
                    <clipPath id="GEODIAID_4_">
                        <use xlinkHref="#GEODIAID_3_" overflow="visible" />
                    </clipPath>

                    <line clipPath="url(#GEODIAID_4_)" fill="none" stroke={hl(`frame`)} strokeWidth="10" x1="356.709" y1="17.58" x2="370.709" y2="64.08" />
                </g>
                <line fill="none" stroke={hl(`frame`)} strokeWidth="10" strokeLinecap="round" x1="184.708" y1="47.58" x2="103.708" y2="175.58" />
                <line fill="none" stroke={hl(`frame`)} strokeWidth="10" strokeLinecap="round" x1="187.708" y1="44.58" x2="358.709" y2="25.58" />
                <line fill="none" stroke={hl(`frame`)} strokeWidth="10" strokeLinecap="round" x1="231.708" y1="198.08" x2="103.708" y2="175.58" />
                <line fill="none" stroke={hl(`frame`)} strokeWidth="10" strokeLinecap="round" x1="231.708" y1="198.08" x2="365.709" y2="55.58" />
                <g>
                    <defs>
                        <rect id="GEODIAID_5_" width="521.814" height="279.185" />
                    </defs>
                    <clipPath id="GEODIAID_6_">
                        <use xlinkHref="#GEODIAID_5_" overflow="visible" />
                    </clipPath>

                    <line clipPath="url(#GEODIAID_6_)" fill="none" stroke={hl(`pedalhubheight`)} strokeWidth="3" x1="230.407" y1="197.176" x2="230.407" y2="278.477" />

                    <line clipPath="url(#GEODIAID_6_)" fill="none" stroke={hl(`pedalhubheight`)} strokeWidth="3" strokeDasharray="9.137,6.137" x1="434.013" y1="276.976" x2="88.868" y2="276.976" />
                </g>
                <line fill="none" stroke={hl(`bottombracketdrop`)} strokeWidth="3" x1="231.407" y1="191.176" x2="231.407" y2="200.676" />
                <g>
                    <defs>
                        <rect id="GEODIAID_7_" width="521.814" height="279.185" />
                    </defs>
                    <clipPath id="GEODIAID_8_">
                        <use xlinkHref="#GEODIAID_7_" overflow="visible" />
                    </clipPath>

                    <line clipPath="url(#GEODIAID_8_)" fill="none" stroke={hl(`trailcut`)} strokeWidth="3" strokeDasharray="9.305,6.305" x1="416.407" y1="175.659" x2="416.407" y2="278.618" />

                    <line clipPath="url(#GEODIAID_8_)" fill="none" stroke={hl(`trailcut`)} strokeWidth="3" strokeDasharray="8.829,5.829" x1="356.709" y1="17.58" x2="436.906" y2="278.176" />

                    <line clipPath="url(#GEODIAID_8_)" fill="none" stroke={hl(`trailcut`)} strokeWidth="3" x1="414.907" y1="277.576" x2="437.907" y2="277.576" />
                </g>
                <line fill="none" stroke={hl(`forkprebend`)} strokeWidth="3" x1="418.168" y1="177.102" x2="403.314" y2="181.292" />
                <g>
                    <defs>
                        <rect id="GEODIAID_9_" width="521.814" height="279.185" />
                    </defs>
                    <clipPath id="GEODIAID_10_">
                        <use xlinkHref="#GEODIAID_9_" overflow="visible" />
                    </clipPath>

                    <line clipPath="url(#GEODIAID_10_)" fill="none" stroke={hl(`forkprebend`)} strokeWidth="3" strokeDasharray="9.193,6.193" x1="356.709" y1="17.58" x2="431.906" y2="277.676" />
                </g>
                <line fill="none" stroke={hl(`toptubelength`)} strokeWidth="3" x1="357.907" y1="15.676" x2="177.907" y2="15.676" />
                <line fill="none" stroke={hl(`toptubelength`)} strokeWidth="3" strokeDasharray="8.863,5.863" x1="178.907" y1="18.676" x2="231.709" y2="196.58" />
                <line fill="none" stroke={hl(`toptubelength`)} strokeWidth="3" strokeDasharray="8.223,5.223" x1="356.709" y1="17.58" x2="370.709" y2="64.08" />
                <g>
                    <defs>
                        <rect id="GEODIAID_11_" width="521.814" height="279.185" />
                    </defs>
                    <clipPath id="GEODIAID_12_">
                        <use xlinkHref="#GEODIAID_11_" overflow="visible" />
                    </clipPath>

                    <line clipPath="url(#GEODIAID_12_)" fill="none" stroke={hl(`standoverheight`)} strokeWidth="3" strokeDasharray="9,6" x1="423.907" y1="277.384" x2="99.907" y2="277.384" />

                    <line clipPath="url(#GEODIAID_12_)" fill="none" stroke={hl(`standoverheight`)} strokeWidth="3" x1="267.407" y1="278.176" x2="267.407" y2="30.676" />
                </g>
                <line fill="none" stroke={hl(`wheelbase`)} strokeWidth="3" x1="417.761" y1="175.219" x2="103.324" y2="175.219" />
                <line fill="none" stroke={hl(`steeringtubelength`)} strokeWidth="3" x1="356.709" y1="17.58" x2="370.709" y2="64.08" />
                <line fill="none" stroke={hl(`steeringtubeangle`)} strokeWidth="3" strokeDasharray="9.167,6.167" x1="417.761" y1="175.219" x2="101.907" y2="175.219" />
                <g>
                    <defs>
                        <rect id="GEODIAID_13_" width="521.814" height="279.185" />
                    </defs>
                    <clipPath id="GEODIAID_14_">
                        <use xlinkHref="#GEODIAID_13_" overflow="visible" />
                    </clipPath>
                    <path clipPath="url(#GEODIAID_14_)" fill="none" stroke={hl(`steeringtubeangle`)} strokeWidth="3" d="M383.142,132.831
		c-12.72,5.616-21.838,15.037-21.838,29.835"/>
                    <polygon clipPath="url(#GEODIAID_14_)" fill={hl(`steeringtubeangle`)} points="387.901,130.729 377.635,127.176 383.611,140.709 	" />
                    <polygon clipPath="url(#GEODIAID_14_)" fill={hl(`steeringtubeangle`)} points="361.304,167.869 368.7,159.913 353.906,159.913 	" />
                </g>
                <line fill="none" stroke={hl(`steeringtubeangle`)} strokeWidth="3" x1="356.709" y1="17.58" x2="370.709" y2="64.08" />
                <line fill="none" stroke={hl(`steeringtubeangle`)} strokeWidth="3" strokeDasharray="9.261,6.261" x1="370.709" y1="64.176" x2="405.906" y2="176.719" />
                <line fill="none" stroke={hl(`seattube`)} strokeWidth="3" x1="184.708" y1="37.08" x2="231.907" y2="197.176" />
                <line fill="none" stroke={hl(`seatangle`)} strokeWidth="3" strokeDasharray="9.073,6.073" x1="416.907" y1="175.676" x2="104.907" y2="175.676" />
                <line fill="none" stroke={hl(`seatangle`)} strokeWidth="3" x1="184.708" y1="37.08" x2="225.907" y2="177.176" />
                <g>
                    <defs>
                        <rect id="GEODIAID_15_" width="521.814" height="279.185" />
                    </defs>
                    <clipPath id="GEODIAID_16_">
                        <use xlinkHref="#GEODIAID_15_" overflow="visible" />
                    </clipPath>
                    <path clipPath="url(#GEODIAID_16_)" fill="none" stroke={hl(`seatangle`)} strokeWidth="3" d="M201.184,124.281
		c-25.681,0-41.297,15.616-41.297,41.297"/>
                    <polygon clipPath="url(#GEODIAID_16_)" fill={hl(`seatangle`)} points="206.386,124.281 198.43,116.884 198.43,131.678 	" />
                    <polygon clipPath="url(#GEODIAID_16_)" fill={hl(`seatangle`)} points="159.886,170.782 167.283,162.826 152.489,162.826 	" />
                </g>
                <line fill="none" stroke={hl(`chainstaylength`)} strokeWidth="3" x1="231.708" y1="207.676" x2="103.708" y2="185.176" />
                <line fill="none" stroke={hl(`chainstaylength`)} x1="231.407" y1="198.176" x2="231.407" y2="209.176" />
                <line fill="none" stroke={hl(`chainstaylength`)} x1="103.407" y1="175.176" x2="103.407" y2="186.176" />
                <line fill="none" stroke={hl(`reach`)} strokeWidth="3" strokeDasharray="8.72,5.72" x1="232.208" y1="16.08" x2="232.208" y2="198.08" />
                <line fill="none" stroke={hl(`reach`)} strokeWidth="3" x1="230.708" y1="17.58" x2="357.709" y2="17.58" />
                <line fill="none" stroke={hl(`stack`)} strokeWidth="3" x1="232.208" y1="16.08" x2="232.208" y2="198.08" />
                <line fill="none" stroke={hl(`stack`)} strokeWidth="3" strokeDasharray="8.882,5.882" x1="230.708" y1="17.58" x2="357.709" y2="17.58" />
            </svg>
        </div>
    );
};

export default BikeGeometry;
