import React, { FC, useContext, useState } from "react";

import Analytics from "src/lib/analytics/analytics";
import Button from "@material-ui/core/Button";
import GqlClientContext from "src/lib/gqlClientContext";
import Grid from "@material-ui/core/Grid";
import { TheCycleverseTheme } from "src/theme";
import { createPriceAlert } from "src/graphql/mutations";
import { getProductInfoLink } from "src/components/Content/Product/useProductLink";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  field: {
    "& input": {
      boxShadow: "inset 0px 0px 0px 0px",
      border: `1px solid ${theme.unflashyColor}`,
      minWidth: 100,
      width: "100%",
      padding: 8,
      paddingTop: 9,
      borderRadius: theme.shape.borderRadius,
      marginBottom: 5,
      marginRight: 5,
      fontSize: 16,
      fontWeight: 300,
    },
    "& input:focus": {
      outline: "none",
      "& input": {
        boxShadow: "inset 0px 0px 0px 0px",
        minWidth: 100,
        width: "100%",
        padding: 8,
        paddingTop: 9,
        borderRadius: theme.shape.borderRadius,
        marginBottom: 5,
        marginRight: 5,
        fontSize: 16,
        fontWeight: 300,
      },
    },
    "& button": {
      backgroundColor: theme.adviceColor,
      color: "#fff",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },

  finalButton: {
    textAlign: "center",
    paddingBottom: theme.spacing(1),
    "& button": {
      backgroundColor: theme.darkOrangeColor,
      fontSize: "large",
      color: theme.tableHeaderFgColor,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: "5px 30px",
    },
  },

  errorMessage: {
    color: theme.darkOrangeColor,
  },
}));

export const validEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export type PriceAlertFormProps = {
  productPrice: any;
  productCategory: string;
  productId: string;
  product: any;
  onSuccess: () => void;
  openConfirmationModal: (emailConfirmed?: boolean) => void;
};

const PriceAlertForm: FC<PriceAlertFormProps> = ({
  productId,
  productCategory,
  productPrice,
  product,
  openConfirmationModal,
  onSuccess,
}) => {
  const classes = useStyles();
  const { t, lang: locale } = useTranslation("priceAlert");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const gqlFetcher = useContext(GqlClientContext);

  const handleChange = (event) => {
    const { value } = event.target;
    setError(null);
    setEmail(value);
  };

  const handleSubmit = async () => {
    if (validEmail(email)) {
      Analytics.priceAlertRegistration(email, productId, productCategory, productPrice);
      const userId = await Analytics.getUserId();
      const productLink = getProductInfoLink(product, locale);
      const data = await gqlFetcher([createPriceAlert, JSON.stringify({ productId, productLink, userId, email })]);
      const emailConfirmed = data?.createPriceAlert?.emailConfirmed;
      openConfirmationModal(emailConfirmed);
      onSuccess();
    } else {
      setError(t("invalidEmailErrorMessage"));
    }
  };
  return (
    <div className={classes.field}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} sm={12}>
          <input
            name="email"
            onChange={handleChange}
            value={email || ""}
            type="email"
            placeholder={t("emailPlaceHolder")}
            autoComplete="off"
          />
          {error ? <div className={classes.errorMessage}>{error}</div> : null}
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.finalButton} style={{ textAlign: "center" }}>
            <Button onClick={handleSubmit} type="submit">
              {t("createNow")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PriceAlertForm;
