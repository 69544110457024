import React, { FC } from "react";
import useTranslation from "next-translate/useTranslation";

type PriceAlertButtonProps = {
  toggleButtonClass: string;
};

const PriceAlertButton: FC<PriceAlertButtonProps> = ({ toggleButtonClass }) => {
  const { t } = useTranslation("priceAlert");
  return <div className={toggleButtonClass}>{t("priceAlert")}</div>;
};

export default PriceAlertButton;
