import { GUIDELINES } from "goopubtag";
import environment from "src/environment";
const { UNIT_SIZE, SCREEN_SIZE } = GUIDELINES;

const mockAdUnitPath = "Travel/Europe";
const getAdUnitPath = (path) => {
  if (environment.features.useMocksInAdSlots) {
    return mockAdUnitPath;
  }
  return path;
};

export type AdSlotFallbackType = {
  image: string;
  imageAlt: string;
  link: string;
};

export type AdSlotType = {
  slotId: string;
  widthDesktop: number;
  heightDesktop: number;
  fallbackDesktop?: AdSlotFallbackType;
  widthMobile?: number;
  heightMobile?: number;
  fallbackMobile?: AdSlotFallbackType;
  adUnitPathDesktop: string;
  adUnitPathMobile?: string;
};

export const AdSlotArticleHead: AdSlotType = {
  slotId: "ad-article-head",
  widthDesktop: UNIT_SIZE.LEADERBOARD[0],
  heightDesktop: UNIT_SIZE.LEADERBOARD[1],
  fallbackDesktop: {
    image: "/images/ads/TC-Header-Banner-728x90.jpg",
    imageAlt: "Geschenke für Fahrradfahrer",
    link: "https://thecycleverse.com/de/blog/geschenke-fuer-fahrradfahrer",
  },
  widthMobile: UNIT_SIZE.MOBILE_LEADERBOARD[0],
  heightMobile: UNIT_SIZE.MOBILE_LEADERBOARD[1],
  fallbackMobile: {
    image: "/images/ads/TC-Header-Banner-320x50.jpg",
    imageAlt: "Geschenke für Fahrradfahrer",
    link: "https://thecycleverse.com/de/blog/geschenke-fuer-fahrradfahrer",
  },
  adUnitPathDesktop: getAdUnitPath("leaderboard-1"),
  adUnitPathMobile: getAdUnitPath("leaderboard-2"),
};

export const AdSlotArticleSkyscraper1: AdSlotType = {
  slotId: "ad-article-skyscraper-1",
  widthDesktop: UNIT_SIZE.SKYSCRAPER_SLIM[0],
  heightDesktop: UNIT_SIZE.SKYSCRAPER_SLIM[1],
  adUnitPathDesktop: getAdUnitPath("skyscraper-1"),
};

export const AdSlotArticleInline1: AdSlotType = {
  slotId: "ad-article-inline-1",
  widthDesktop: UNIT_SIZE.MPU_300[0],
  heightDesktop: UNIT_SIZE.MPU_300[1],
  adUnitPathDesktop: getAdUnitPath("inline-1"),
};

export const AdSlotArticleSkyscraper2: AdSlotType = {
  slotId: "ad-article-skyscraper-2",
  widthDesktop: UNIT_SIZE.SKYSCRAPER_SLIM[0],
  heightDesktop: UNIT_SIZE.SKYSCRAPER_SLIM[1],
  adUnitPathDesktop: getAdUnitPath("skyscraper-2"),
};

export const AdSlotArticleFooter1: AdSlotType = {
  slotId: "ad-article-footer-1",
  widthDesktop: 970,
  heightDesktop: 90,
  adUnitPathDesktop: getAdUnitPath("footer-1"),
};

export const AdSlotArticleFooter2: AdSlotType = {
  slotId: "ad-article-footer-2",
  widthDesktop: UNIT_SIZE.LEADERBOARD[0],
  heightDesktop: UNIT_SIZE.LEADERBOARD[1],
  adUnitPathDesktop: getAdUnitPath("footer-2"),
};

export const AdSlotProductHead: AdSlotType = AdSlotArticleHead;
export const AdSlotProductSkyscraper1: AdSlotType = AdSlotArticleSkyscraper1;
export const AdSlotProductSkyscraper2: AdSlotType = AdSlotArticleSkyscraper2;

export const AdSlotProductInline2: AdSlotType = {
  slotId: "ad-product-inline-2",
  widthDesktop: UNIT_SIZE.MPU_300[0],
  heightDesktop: UNIT_SIZE.MPU_300[1],
  adUnitPathDesktop: getAdUnitPath("inline-2"),
};

export const AdSlotProductInline3: AdSlotType = {
  slotId: "ad-product-inline-3",
  widthDesktop: UNIT_SIZE.MPU_300[0],
  heightDesktop: UNIT_SIZE.MPU_300[1],
  adUnitPathDesktop: getAdUnitPath("inline-3"),
};

export const AdSlotProductInline4: AdSlotType = {
  slotId: "ad-product-inline-4",
  widthDesktop: UNIT_SIZE.MPU_300[0],
  heightDesktop: UNIT_SIZE.MPU_300[1],
  adUnitPathDesktop: getAdUnitPath("inline-4"),
};
