import { default as bestProducts } from "../bestProducts.json";

export const getSegmentName = (key) => {
  const categoryNames = Object.keys(bestProducts.items);
  for (var i = 0; i < categoryNames.length; i++) {
    const items = bestProducts.items[categoryNames[i]];
    const res = items.find((o) => o.key === key);
    if (res) {
      return res.name;
    }
  }
  return null;
};

export const getSegmentByPath = (path) => {
  const categoryNames = Object.keys(bestProducts.items);
  for (var i = 0; i < categoryNames.length; i++) {
    const items = bestProducts.items[categoryNames[i]];
    const res = items.find((o) => o.path === path);
    if (res) {
      return res;
    }
  }
  return null;
};

export const getParentCategory = (key) => {
  const categoryNames = Object.keys(bestProducts.items);
  for (var i = 0; i < categoryNames.length; i++) {
    const categoryName = categoryNames[i];
    const items = bestProducts.items[categoryName];
    const res = items.find((o) => o.key === key);
    if (res) {
      return categoryName;
    }
  }
  return null;
};

export const getBestBikePath = (category, price) => {
  const segment = getSegmentByPath(category);

  if (!segment) {
    return null;
  }

  const prices = segment.priceSegments;

  if (!prices || prices.length <= 0) {
    return null;
  }

  var selectedPrice = prices[0];
  for (let i = 0; i < prices.length - 1; i++) {
    if (price >= prices[i]) {
      selectedPrice = prices[i + 1];
    }
  }

  let showHighEnd = prices[prices.length - 1] == selectedPrice && selectedPrice < price;

  const priceKeys = `${segment.key}${bestProducts.maxPriceSegment}${selectedPrice}`;
  const highEndKey = `${bestProducts.highEndSegment}${segment.key}`;

  if (showHighEnd) {
    return highEndKey;
  } else {
    return priceKeys;
  }
};
