import ButtonLink from "src/components/Navigation/ButtonLink";
import Container from "@material-ui/core/Container";
import MainLayout from "src/components/Layout/MainLayout";
import React from "react";
import SeoHead from "src/components/Seo/SeoHead";
import useTranslation from "next-translate/useTranslation";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  errorImage: {
    backgroundImage: "url(/images/errorTasse.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "100%",
    height: 150,
  },
  errorTitle: {
    fontWeight: 900,
    fontSize: 70,
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  button: {
    display: "inline-block",
    backgroundColor: theme.hightlightColorTertiary,
    color: theme.palette.primary.main,
  },
}));

function Error({ statusCode }) {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const isServerError = statusCode === true;

  return (
    <MainLayout>
      <SeoHead title="Oooops.">
        <meta name="robots" content="noindex" />
      </SeoHead>
      <Container maxWidth="sm" className={classes.container}>
        <div className={classes.errorImage} />
        <div className={classes.errorTitle}>Oooops.</div>
        <div>{`${statusCode}.`}</div>
        <div>{t("errorDescription")}</div>
        <div className={classes.buttonContainer}>
          <ButtonLink href="/" as="/" buttonClassName={classes.button}>
            {t("errorButton")}
          </ButtonLink>
        </div>
      </Container>
    </MainLayout>
  );
}

Error.getInitialProps = ({ res, err }) => {
  if (err && err.code === "ENOENT") {
    res.statusCode = 404;
  }
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
