import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from "next/router";
import environment from "../../environment";
import useTranslation from "next-translate/useTranslation";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  // LineShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  PinterestShareButton,
  PinterestIcon,
  // PocketShareButton,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  // TumblrShareButton,
  TwitterShareButton,
  TwitterIcon,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  // WorkplaceShareButton,
} from "react-share";

const useStyles = makeStyles(() => ({
  socialMediaShare: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    flexWrap: "wrap",
    "& button": {
      margin: 5,
    },
    // '& button:first-child': {
    //     marginLeft: 0,
    // },
  },
  socialShareText: {
    paddingBottom: 5,
    paddingRight: 5,
  },
  blackAndWhite: {
    "& .react-share__ShareButton": {
      filter: "grayscale(1.0) brightness(0.6) contrast(6)",
    },
  },
}));

const SocialShareButtons = (props) => {
  const { url, title, mediaUrl, className } = props;
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation("common");
  let shareUrl = url;

  if (!shareUrl) {
    shareUrl = `https://${environment.host}${router.asPath}`;
  }

  return (
    <div className={`${className ? className : ""} ${classes.socialMediaShare}`}>
      <div className={classes.socialShareText}>{t("socialShareText")}</div>
      <div className={`${classes.socialShareButtons} ${classes.blackAndWhite}`}>
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <PinterestShareButton url={shareUrl} media={mediaUrl}>
          <PinterestIcon size={32} round />
        </PinterestShareButton>

        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>

        <TelegramShareButton url={shareUrl} title={title}>
          <TelegramIcon size={32} round />
        </TelegramShareButton>

        <RedditShareButton url={shareUrl} title={title} windowWidth={660} windowHeight={460}>
          <RedditIcon size={32} round />
        </RedditShareButton>

        <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <InstapaperShareButton url={shareUrl} title={title}>
          <InstapaperIcon size={32} round />
        </InstapaperShareButton>

        <EmailShareButton url={shareUrl} subject={title}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default SocialShareButtons;
