import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import useTranslation from "next-translate/useTranslation";
import { TheCycleverseTheme } from "src/theme";
import { BikeGeometryNumbersProps } from "./BikeGeometry";


const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
    table: {
        "& th": {
            backgroundColor: "lightGray",
            cursor: "auto"
        },
        "& tr>td:first-child, tr>th:first-child": {
            backgroundColor: "white",
            textAlign: "left",
            fontWeight: "bold",
            cursor: "auto"
        },
        "& td,th": {
            borderWidth: "0.5pt",
            borderColor: "gray",
            borderStyle: "solid",
            textAlign: "center",
            cursor: "pointer",
            whiteSpace: "nowrap"
        }
    },
    tableColHighlighOn: {
        color: '#9C00A2',
        fontWeight: "bold"
    },
    tableColHighlighOff: {
    },
    tableRowHighlighOn: {
        color: '#f94902',
        fontWeight: "bold"
    },
    tableRowHighlighOff: {
        color: 'auto'
    }
}));

export interface TableCoordinate {
    col: number;
    row: number;
}

export type BikeTableEventName = "stack"|"reach"|"toptubelength"|"seattube"|"seatangle"|"steeringtubelength"|"steeringtubeangle"|"bottombracketdrop"|"pedalhubheight"|"chainstaylength"|"wheelbase"|"standoverheight"|"trailcut"|"forkprebend";

export type BikeGeometryProps = {
    bikeGeometries: Array<BikeGeometryNumbersProps>,
    onGeometryHighlight?: (eventName: BikeTableEventName, coord: TableCoordinate) => void
    highlightCoord: TableCoordinate
};



const BikeGeometryTable: FC<BikeGeometryProps> = (props) => {
    const classes = useStyles();
    const { t, lang: locale } = useTranslation("bikeDetails");
    const {highlightCoord} = props

    const bikeRowHeaders = [
        t('bikeDetails:geometryTH-manufacturersize'),
        t('bikeDetails:geometryTH-wheelsize'),
        t('bikeDetails:geometryTH-stack'),
        t('bikeDetails:geometryTH-reach'),
        t('bikeDetails:geometryTH-toptubelength'),
        t('bikeDetails:geometryTH-seattube'),
        t('bikeDetails:geometryTH-seatangle'),
        t('bikeDetails:geometryTH-steeringtubelength'),
        t('bikeDetails:geometryTH-steeringtubeangle'),
        t('bikeDetails:geometryTH-bottombracketdrop'),
        t('bikeDetails:geometryTH-pedalhubheight'),
        t('bikeDetails:geometryTH-chainstaylength'),
        t('bikeDetails:geometryTH-wheelbase'),
        t('bikeDetails:geometryTH-standoverheight'),
        t('bikeDetails:geometryTH-trailcut'),
        t('bikeDetails:geometryTH-forkprebend')
    ]

    const bikeColumns = props.bikeGeometries.map(g => {
        return [
            g.sizeName,
            (g.wheelSizeInchesX10 / 10) + " Zoll",
            g.stack,
            g.reach,
            g.toptubelength,
            g.seattube,
            (g.seatangleX10 / 10),
            g.steeringtubelength,
            g.steeringtubeangle,
            g.bottombracketdrop,
            g.pedalhubheight,
            g.chainstaylength,
            g.wheelbase,
            g.standoverheight,
            g.trailcut,
            g.forkprebend
        ];
    })

    const tableColumns: (string | number)[][] = [bikeRowHeaders];
    bikeColumns?.map(c=>tableColumns.push(c));

    const tableData = tableColumns[0].map((_, colIndex) => tableColumns.map(row => row[colIndex])); // transpose

    const headerRowCount = 2;
    const headerRows = tableData.slice(0, headerRowCount)
    const bodyRows = tableData.slice(headerRowCount)
    const eventNames: Array<BikeTableEventName> = [
    "stack",
    "reach",
    "toptubelength",
    "seattube",
    "seatangle",
    "steeringtubelength",
    "steeringtubeangle",
    "bottombracketdrop",
    "pedalhubheight",
    "chainstaylength",
    "wheelbase",
    "standoverheight",
    "trailcut",
    "forkprebend"
    ];

    const highlight = (row: number, col: number) => {
        const highlightedTableCoords = {
            row: row,
            col: Math.max(1, col),
        };
        if(!!props.onGeometryHighlight){
            const x = row - headerRowCount;
            if(x>=0 && x < eventNames.length){
                props.onGeometryHighlight(eventNames[x], highlightedTableCoords)
            }
        }
    };

    return (
        <div>
            <TableContainer>
                <Table className={`${classes.table}`} aria-label="simple table">
                    <TableHead>
                        {headerRows.map((row, rowIx) =>
                            <TableRow
                                key={"geoRow"+rowIx}
                            >
                                {row.map((d, colIx) => <TableCell
                                    key={"geoCell"+colIx+"x"+rowIx}
                                    className={`
                                        ${highlightCoord.col === colIx ? classes.tableColHighlighOn : classes.tableColHighlighOff}
                                            `}
                                            onMouseMove={() => highlight(rowIx, colIx)}
                                    onClick={() => highlight(rowIx, colIx)}
                                >{d}</TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>
                        {bodyRows.map((row, rowIndex) =>{
                            const rowIx = rowIndex + headerRows.length
                            return <TableRow
                                    key={"geoRow"+rowIx}
                                >
                                {row.map((d, colIx) => {
                                    return <TableCell
                                        key={"geoCell"+colIx+"x"+rowIx}
                                        className={`
                                            ${highlightCoord.col === colIx ? classes.tableColHighlighOn : classes.tableColHighlighOff}
                                            ${((highlightCoord.col === colIx || 0 === colIx) && highlightCoord.row === rowIx)  ? classes.tableRowHighlighOn : classes.tableRowHighlighOff}
                                            `}
                                        onMouseMove={() => highlight(rowIx, colIx)}
                                        onClick={() => highlight(rowIx, colIx)}
                                    >{d}</TableCell>
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default BikeGeometryTable;
