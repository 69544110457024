import React from "react";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import ProductHightlights from "./ProductHightlights";
import useTranslation from "next-translate/useTranslation";
import { singleAdvertiser } from "src/components/common/ProductUtil";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  advertiserItem: {
    marginTop: theme.spacing(4),
    "&::after": {
      display: "block",
      maxWidth: theme.paragraphMaxWidth,
      margin: "auto",
      marginTop: theme.spacing(4),
      content: '""',
      borderBottom: `1px solid ${theme.unflashyColor}`,
    },
    "&:last-child:after": {
      borderBottom: "none",
      marginTop: theme.spacing(8),
    },
  }
}));

export type ProductToResolve = {
  brand: string,
  title: string,
  ean?: string,
  seoId: string
};
export type AffiliateRecord = {
  advertiser: string
};

export type ProvidedPriceComparisonSectionProps = {
  affiliateRecords: Array<AffiliateRecord>;
  title: string;
  product?: never;
};

export type ResolvingPriceComparisonSectionProps = {
  affiliateRecords?: never;
  title?: never;
  product: ProductToResolve;
};

export type PriceComparisonSectionProps = ProvidedPriceComparisonSectionProps | ResolvingPriceComparisonSectionProps;

const PriceComparisonSection = (props: PriceComparisonSectionProps) => {
  const {product, affiliateRecords, title} = props;
  const classes = useStyles();
  const { t, lang } = useTranslation("productDetails");

  const productTitle = title || `${product.brand} ${product.title}`;

  return <ProductHightlights
  anchor="links"
  getTitle={(productRecords) => {
    if (productRecords.length <= 1) {
      return t("productDetailPriceComparisonNoneTitle");
    } else {
      return t("productDetailPriceComparison", {
        productsLength: productRecords.length,
        brandAndTitle: productTitle,
      });
    }
  }}
  getSubTitle={(productRecords) => {
    if (productRecords.length <= 1) {
      return t("productDetailPriceComparisonNoneText");
    } else {
      return null;
    }
  }}
  minimized={true}
  verticalScroll={true}
  productsPostprocessor={singleAdvertiser}
  cardType="Advertiser"
  align="center"
  itemClass={classes.advertiserItem}
  recommendation={affiliateRecords?{ type: "productPayloads", products: singleAdvertiser(affiliateRecords) }:{ type: "sameEanOrSeoId", product: product, seoId: product.seoId }}
  typographyVariant="h2"
/>
};

export default PriceComparisonSection;
