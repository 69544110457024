import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TheCycleverseTheme } from "src/theme";
import useTranslation from "next-translate/useTranslation";
import BikePreview from "./Product/BikePreview";
import Image from "next/image";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
	bikeComparisonPreviewContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	bikeComparisonPreviewContainerLarge: {
		display: 'flex',
		flexDirection: 'row'
	},
	bikeComparisonVs: {
		display: 'contents',
		height: '30px',
		textAlign: 'center',
		"&>span": {
			margin: "-10px !important"
		}
	},
	bikeComparisonSmaller: {
		flexBasis: "30%",
		paddingTop: '0px',
		paddingBottom: '0px',
		paddingLeft: '30px',
		paddingRight: '30px'
	},
	bikeComparisonMain: {
		flexBasis: "30%",
	},
	bikeComparisonSmallerLarge: {
		flexBasis: "30%",
		paddingTop: '30px',
		paddingBottom: '30px',
		paddingLeft: '0px',
		paddingRight: '0px'
	},
}));

export type BikeComparisonBlockProps = {
	compareLink: string
	cheap: any,
	bike: any,
	expensive: any
};

const BikeComparisonBlock: FC<BikeComparisonBlockProps> = (props) => {
	const classes = useStyles();
	const { t } = useTranslation("bikeDetails");

	const { compareLink, cheap, bike, expensive } = props;

	const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));

	return <>
		<div className={isDesktopView?classes.bikeComparisonPreviewContainerLarge:classes.bikeComparisonPreviewContainer}>
			<div className={isDesktopView?classes.bikeComparisonSmallerLarge:classes.bikeComparisonSmaller}>
				<BikePreview key={"preview_" + cheap.id} link={compareLink} bike={cheap} onClick={() => { }} vertical={isDesktopView} />
			</div>
			<div className={classes.bikeComparisonVs}>
				<Image
					src={"/images/comparison/vs_comparison.svg"}
					width={40}
					height={40}
					alt={cheap.modelName + " versus" + bike.modelName}
				/>
			</div>
			<div className={classes.bikeComparisonMain}>
				<BikePreview key={"preview_" + bike.id} link={compareLink} bike={bike} onClick={() => { }} vertical={isDesktopView} />
			</div>
			<div className={classes.bikeComparisonVs}>
				<Image
					src={"/images/comparison/vs_comparison.svg"}
					width={40}
					height={40}
					alt={bike.modelName + " versus" + expensive.modelName}
				/>
			</div>
			<div className={isDesktopView?classes.bikeComparisonSmallerLarge:classes.bikeComparisonSmaller}>
				<BikePreview key={"preview_" + expensive.id} link={compareLink} bike={expensive} onClick={() => { }} vertical={isDesktopView} />
			</div>
		</div>
	</>;
};

export default BikeComparisonBlock;
